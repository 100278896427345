import React, { useMemo } from 'react';
import { roundToX } from 'helpers/data';
import { getRedGreenColor } from 'helpers/tables';
import { ScoutingGradeBoxes } from 'components/PlayersV2/Scouting/ScoutingGrades';
import { HighlightBox } from 'components/bdd/warcomponents';
import { Border, Column, Container, Grid, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import { HoverInteractive } from 'components/bdd';
import { useScoutingModelDataStore, useScoutingRanks } from '../hooks';
import { ScoutReportSummary } from './ScoutReportSummary';
import { PlayerViewStats } from './PlayerViewStats';
import { DraftPlayerChecks } from './DraftPlayerChecks';
import { SimilarScoutingPlayersLinkButton } from 'components/PlayersV2/Scouting/SkillsAndModels';
import { DraftListPlayerMadeItResult } from 'components/DraftApp/DraftListPlayerMadeItResult';
import { DraftListPlayerExpectedPickRange } from 'components/DraftApp/DraftListPlayerExpectedPickRange';
import { useUser } from 'helpers/user';



const scoutingModels = [
  {
    title: 'Ovr',
    outcomeSlugD: 'draft-overall-d-score',
    outcomeSlugF: 'draft-overall-f-score',
    nhlSlugD: 'draft-overall-d-nhl',
    nhlSlugF: 'draft-overall-f-nhl',
  },
  {
    title: 'Scout',
    outcomeSlugD: 'draft-scout-d-score',
    outcomeSlugF: 'draft-scout-f-score',
    nhlSlugD: 'draft-scout-d-nhl',
    nhlSlugF: 'draft-scout-f-nhl',
  },
  {
    title: 'Prod',
    outcomeSlugD: 'draft-prod-d-score',
    outcomeSlugF: 'draft-prod-f-score',
    nhlSlugD: 'draft-prod-d-nhl',
    nhlSlugF: 'draft-prod-f-nhl',
  },
  {
    title: 'SL',
    outcomeSlugD: 'draft-sl-d-score',
    outcomeSlugF: 'draft-sl-f-score',
    nhlSlugD: 'draft-sl-d-nhl',
    nhlSlugF: 'draft-sl-f-nhl',
  },
];
const scoutingModelHover = (modelToScore, outcomeSlugD, outcomeSlugF, nhlSlugD, nhlSlugF) => {
  const outcomeModelDetails = !!modelToScore[outcomeSlugD]?.details
    ? JSON.parse(modelToScore[outcomeSlugD]?.details)
    : !!modelToScore[outcomeSlugF]?.details
    ? JSON.parse(modelToScore[outcomeSlugF]?.details)
    : null;
  const pNHL = !!modelToScore[nhlSlugD]?.modelScore
    ? modelToScore[nhlSlugD].modelScore
    : !!modelToScore[nhlSlugF]?.modelScore
    ? modelToScore[nhlSlugF].modelScore
    : null;

  if (!outcomeModelDetails) {
    return <></>;
  }
  const probabilities = [
    { key: 'pT6', title: 'Top' },
    { key: 'pM6', title: 'Middle' },
    { key: 'pB6', title: 'Bottom' },
    { key: 'pDep', title: 'Depth' },
    { key: 'pDW', title: 'Not NHL' },
  ];
  const probs = probabilities.filter((prob) => !!outcomeModelDetails[prob.key]);
  const score = !!modelToScore[outcomeSlugD]?.modelScore ? modelToScore[outcomeSlugD].modelScore
  : !!modelToScore[outcomeSlugF]?.modelScore ? modelToScore[outcomeSlugF].modelScore
  : null;
const percentile = !!modelToScore[outcomeSlugD]?.percentile ? modelToScore[outcomeSlugD].percentile
: !!modelToScore[outcomeSlugF]?.percentile ? modelToScore[outcomeSlugF].percentile
: null;
  const maxScore = probs
    .map(({ key, title }) => outcomeModelDetails[key])
    .sort((a, b) => b - a)[0];
  return (
    <Container>
      <Row columnGap={1}>
        <div>
          {probabilities
            .filter((prob) => !!outcomeModelDetails[prob.key])
            .map(({ key, title }) => (
              <HighlightBox
                key={key}
                title={
                  <Typography variant="body1" textAlign="center">
                    {title}
                  </Typography>
                }
                content={
                  <Typography variant="stat">
                    {roundToX(outcomeModelDetails[key] * 100, 0, true) + '%'}
                  </Typography>
                }
                color={getRedGreenColor(
                  outcomeModelDetails[key],
                  0,
                  maxScore
                )} /* highlight according to max score. Every model score has red and green */
              />
            ))}
          <HighlightBox
            key={'nhl'}
            title={
              <Typography variant="body1" textAlign="center">
                {'NHL'}
              </Typography>
            }
            content={
              <Typography variant="stat">{roundToX(pNHL * 100, 0, true) + "%"}</Typography>
            }
            color={getRedGreenColor(pNHL, 0, 1)}
          />
          <HighlightBox
            key={'score'}
            title={
              <Typography variant="body1" textAlign="center">
                {'Score'}
              </Typography>
            }
            content={
              <Typography variant="stat">
                {roundToX(score, 1, true)}
              </Typography>
            }
            color={getRedGreenColor(percentile, 0, 1)}
          />
        </div>
      </Row>
    </Container>
  );
};

export const ScoutingRanks = ({
  rinknetId,
  rinknetIds,
  posFDG,
  playerName,
  compact,
  hideScoutReportModal,
  draftYear,
  season,
  listId,
  hideExpectedPickRange,
  listType,
  useMyGrades,
}) => {
  const { user } = useUser();
  const scoutId = user.rinknetUser?.id;
  const { ratingToRank, split } = useScoutingRanks({
    rinknetId,
    splitFilters: {
      predictedGrades: false,
      position: posFDG.toLowerCase(),
      scoutId: useMyGrades ? scoutId : null,
    },
  });
  const { ratingToRank: predictedRatingToRank, split: predictedSplit } =
    useScoutingRanks({
      rinknetId,
      splitFilters: { predictedGrades: true, position: posFDG.toLowerCase() },
    });
  const { modelToScore } = useScoutingModelDataStore();

  if (!ratingToRank) {
    return (
      <Container>
        <Typography variant="stat">Loading...</Typography>
      </Container>
    );
  }

  let scoutingRankBoxes;
  let predictedScoutingRankBoxes;
  if (!ratingToRank || Object.keys(ratingToRank).length == 0) {
    scoutingRankBoxes = (
      <Container>
        <Typography variant="stat">
          <em>No scouting data found</em>
        </Typography>
      </Container>
    );
  } else {
    scoutingRankBoxes = (
      <ScoutingGradeBoxes
        rankings={Object.values(ratingToRank)}
        split={split}
        position={posFDG}
        rinknetId={rinknetId}
        playerName={playerName}
        noSplitDescription={compact}
        predictedRankings={
          predictedRatingToRank && Object.values(predictedRatingToRank)
        }
        hideScoutReportModal={hideScoutReportModal}
      />
    );
  }

  if (!!predictedScoutingRankBoxes) {
    scoutingRankBoxes = (
      <HoverInteractive
        content={
          <Container padding={1}>{predictedScoutingRankBoxes}</Container>
        }
      >
        {scoutingRankBoxes}
      </HoverInteractive>
    );
  }

  const viewStats = (
    <PlayerViewStats
      playerName={playerName}
      rinknetId={rinknetId}
      rinknetIds={rinknetIds}
      horizontal={compact}
      season={season}
    />
  );

  let modelScoreBoxes = null;
  if (listType != 'PRO_LIST' && listType != 'FREE_AGENT_LIST') {
    const modelScores = modelToScore && modelToScore(rinknetId);
    if (!modelScores || Object.keys(modelScores).length == 0) {
      modelScoreBoxes = null;
    } else {
      modelScoreBoxes = (
        <Row columnGap={2} justifyContent="space-between">
          {scoutingModels.map(({ title, outcomeSlugD, outcomeSlugF, nhlSlugD, nhlSlugF }) =>
            compact ? (
              <HoverInteractive
                key={outcomeSlugF}
                renderContent={() =>
                  scoutingModelHover(
                    modelScores,
                    outcomeSlugD,
                    outcomeSlugF,
                    nhlSlugD,
                    nhlSlugF
                  )
                }
              >
                <Row columnGap={1}>
                  <Typography variant="stat" textAlign="center">
                    {title}
                  </Typography>
                  <Border>
                    <Container
                      padding={0.5}
                      paddingLeft={1}
                      paddingRight={1}
                      style={{
                        backgroundColor: getRedGreenColor(
                          modelScores[outcomeSlugD]?.percentile ? modelScores[outcomeSlugD].percentile : modelScores[outcomeSlugF]?.percentile,
                          0,
                          1
                        ),
                      }}
                    >
                      <Typography variant="stat">
                        {roundToX(
                          modelScores[outcomeSlugD]?.percentile ? modelScores[outcomeSlugD].percentile * 100 :  modelScores[outcomeSlugF]?.percentile * 100,
                          0
                        )}
                      </Typography>
                    </Container>
                  </Border>
                </Row>
              </HoverInteractive>
            ) : (
              <HoverInteractive
                key={outcomeSlugF}
                renderContent={() =>
                  scoutingModelHover(
                    modelScores,
                    outcomeSlugD,
                    outcomeSlugF,
                    nhlSlugD,
                    nhlSlugF
                  )
                }
              >
                <Column gap={1}>
                  <Typography variant="stat" textAlign="center">
                    {title}
                  </Typography>
                  <Border>
                    <Container
                      padding={0.5}
                      paddingLeft={1}
                      paddingRight={1}
                      style={{
                        backgroundColor: getRedGreenColor(
                          modelScores[outcomeSlugD]?.percentile ? modelScores[outcomeSlugD].percentile : modelScores[outcomeSlugF]?.percentile,
                          0,
                          1
                        ),
                      }}
                    >
                      <Typography variant="stat">
                        {roundToX(
                          modelScores[outcomeSlugD]?.percentile ? modelScores[outcomeSlugD].percentile * 100 :  modelScores[outcomeSlugF]?.percentile * 100,
                          0
                        )}
                      </Typography>
                    </Container>
                  </Border>
                </Column>
              </HoverInteractive>
            )
          )}
        </Row>
      );
    }
  }

  return (
    <Container paddingLeft={1}>
      <Column gap={1}>
        <Row columnGap={4} alignItems="start">
          {viewStats}
          {!!modelScoreBoxes && (
            <Container minWidth={compact ? 150 : 0}>
              {modelScoreBoxes}
            </Container>
          )}
          {scoutingRankBoxes}
        </Row>
        {listType != 'PRO_LIST' && listType != 'FREE_AGENT_LIST' ? (
          <Row gap={8}>
            <ScoutReportSummary rinknetId={rinknetId} />
            <Typography variant="body2">|</Typography>
            <DraftPlayerChecks
              rinknetId={rinknetId}
              rinknetIds={rinknetIds}
              draftYear={draftYear}
              pos={posFDG}
            />
            <Typography variant="body2">|</Typography>
            <SimilarScoutingPlayersLinkButton
              name={playerName}
              rinknetId={rinknetId}
              draftYear={draftYear}
              text={
                <DraftListPlayerMadeItResult
                  rinknetId={rinknetId}
                  rinknetIds={rinknetIds}
                />
              }
            />
            {!!draftYear && !hideExpectedPickRange && (
              <>
                <Typography variant="body2">|</Typography>
                <DraftListPlayerExpectedPickRange
                  listId={listId}
                  rinknetId={rinknetId}
                />
              </>
            )}
          </Row>
        ) : null}
      </Column>
    </Container>
  );
};

