import { theme } from 'constants/theme';
import { createContext, useContext, useMemo, useRef, useState } from 'react';
import { XLg } from 'react-bootstrap-icons';
import BDDErrorBoundary from '../bdderrorboundary';
import { IconButton } from '../Button';
import { WindowContext } from '../context';
import { Divider } from '../Divider';
import { useSearchParamsState } from '../hooks';
import { Column, Container, Row } from '../Layout';
import { Modal } from './Modal';

export const ModalContext = createContext();

export const ModalContextProvider = ({
  children,
  onHide,
  // TODO: Not sure why searchParams trigger an infinite loop for PlaylistField components
  // Ideally search params aren't needed, and we can cook the modal into the route
  searchParams = true,
}) => {
  /* eslint-disable react-hooks/rules-of-hooks */
  const [modalContext, setModalContext] = searchParams
    ? useSearchParamsState(null, 'modalContext')
    : useState();

  const modalRef = useRef();
  const { width, height } = useContext(WindowContext);
  const defaultFullscreen =
    width <= theme.breakpoints.xs || height <= theme.breakpoints.xs;

  const [size, setSize] = useState(defaultFullscreen ? 'fullscreen' : 'lg');
  const [body, setBody] = useState();
  const [title, setTitle] = useState();
  const [footer, setFooter] = useState();
  const [onRenderHide, setOnRenderHide] = useState();

  const showModal = !!body;

  const hideModal = () => {
    setBody(null);
    onHide && onHide();
    onRenderHide && onRenderHide();
    setModalContext(null);
  };

  const modal = (
    <Modal ref={modalRef} size={size || 'lg'} show={showModal} onHide={hideModal}>
      <BDDErrorBoundary>
        <Container height="100%">
          <Column gap={2}>
            {title && (
              <Container>
                <Column>
                  <Container padding={3}>
                    <Row justifyContent="space-between">
                      {title}
                      <IconButton
                        tooltip={'Exit modal'}
                        onClick={hideModal}
                        icon={<XLg />}
                      />
                    </Row>
                  </Container>
                  <Divider />
                </Column>
              </Container>
            )}
            <Container height="100%" overflow="auto">
              {body}
            </Container>
            {footer && (
              <Container>
                <Column>
                  <Divider />
                  <Container width="100%" padding={3}>
                    <Row justifyContent="end">{footer}</Row>
                  </Container>
                </Column>
              </Container>
            )}
          </Column>
        </Container>
      </BDDErrorBoundary>
    </Modal>
  );

  const renderModal = ({ size, body, title, footer, context, onRenderHide }) => {
    setBody(body);
    setTitle(title);
    setFooter(footer);
    context && setModalContext(context);
    onRenderHide && setOnRenderHide(() => onRenderHide);

    if (!defaultFullscreen) {
      setSize(size);
    }
  };

  const context = {
    modal,
    modalRef,
    showModal,
    hideModal,
    setBody,
    renderModal,
    defaultFullscreen,
    isFullscreen: size == 'fullscreen',
    setSize,
    modalContext,
    setModalContext,
  };

  return (
    <ModalContext.Provider value={context}>
      {typeof children === 'function' ? children(context) : children}
      {modal}
    </ModalContext.Provider>
  );
};
