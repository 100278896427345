import React from 'react';
import { ErrorMessage } from 'formik';

import BDDFormError from '../bdd/bddformerror';
import Toggle from 'components/bdd/Toggle/Toggle';
import { Typography } from 'components/bdd/Typography';
import { Container, Row } from 'components/bdd/Layout';

export default function BDDToggleField({ title, noTitle, field, ...rest }) {
  const { name, value, onChange } = field; // dont know why error isn't in here
  const touched = rest.form.touched[name];
  return (
    <Container>
      <Row columnGap={2}>
        <Toggle
          id={name}
          name={name}
          label={!noTitle && title}
          toggled={!!value}
          setToggled={(value) => {
            onChange({ target: { name, value } });
          }}
          {...rest}
        />
      </Row>
      <BDDFormError>
        <ErrorMessage name={name} />
      </BDDFormError>
    </Container>
  );
}
