import { useBddStatQueryV2 } from 'apollo/queries';
import { GET_FSETS_V2 } from 'apollo/queries/statsv2.queries';
import usePlaceholder from 'components/Placeholder/usePlaceholder';
import { filtersToQV2 } from 'helpers/stats';
import { useState, useEffect } from 'react';

export const useSLStatContext = ({
  defaults,
  skip: skipArg,
  skipCallback, // called with object {query, store}, if true, will skip
  validateFilters, // called with incoming filter change and store.
  // If returns false, won't update.
  // Must pass thru valid filters, optionally with alterations
}) => {
  const [store, setStore] = useState(defaults);
  const [chartOptions, setChartOptions] = useState({
    type: 'table',
    options: {},
    ...defaults?.chartOptions,
  });

  useEffect(() => {
    setStore({
      ...store,
      ...defaults,
      filters: {
        ...store.filters,
        ...defaults.filters,
      },
    });
  }, [JSON.stringify(defaults)]);

  const setFilters = (filters) => {
    // if a "validateFilters" function is passed in, we'll pass the proposed change through there first
    // This will return false if they're invalid OR will change the incoming "filters" object to be valid
    const validatedFilters = !!validateFilters
      ? validateFilters(filters, store)
      : filters;

    if (!validatedFilters) {
      console.log('Not updating filters--invalid');
      return;
    }

    setStore({
      ...store,
      filters: {
        ...store.filters,
        ...validatedFilters,
      },
    });
  };

  const setQueryValues = (values) => {
    setStore({ ...store, ...values });
  };

  const query = filtersToQV2(store);
  const isQueryValid = query.statDefs || query.statSlugs || query.statReport;

  const skip = skipArg || (!!skipCallback && skipCallback({ query, store }));
  const { data, placeholder, refetch } = usePlaceholder(
    useBddStatQueryV2(query, skip || !isQueryValid)
  );

  const stats = data?.data;

  return {
    store,
    ...store,
    data,
    query,
    stats,
    placeholder,
    setFilters,
    setQueryValues,
    refetch,
    chartOptions,
    setChartOptions,
  };
};
