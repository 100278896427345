import { Scenario } from './Scenario';
import { useTransactionMutations } from '../Transactions/useTransactionMutations';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

export const ProScenario = ({ scenarioId: scenarioIdArg, pageMode=true }) => {
  // A scenario outside of the context of a PO
  const { id } = useParams();
  const scenarioId = scenarioIdArg || id;

  const transactionMutations = useTransactionMutations({});

  return (
    <Scenario
      id={scenarioId}
      pageMode={pageMode}
      contextProps={{
        ...transactionMutations,
      }}
    />
  );
};
