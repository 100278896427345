import { At, FileEarmarkPlay, Image } from 'react-bootstrap-icons';
import { useSlate } from 'slate-react';
import { ToolbarIconButton } from '../..';
import { insertMedia, isMediaActive, unwrapMedia } from './helpers';

export const MediaToolbarButton = ({}) => {
  const editor = useSlate();
  return (
    <ToolbarIconButton
      icon={<Image />}
      active={isMediaActive(editor)}
      onMouseDown={(event) => {
        event.preventDefault();
        if (isMediaActive(editor)) {
          unwrapMedia(editor);
        } else {
          insertMedia(editor);
        }
      }}
    />
  );
};
