import { gql, useQuery } from '@apollo/client';
import 'regenerator-runtime/runtime';
import Tracker from '@openreplay/tracker';
import { useEffect } from 'react';
import trackerAssist from '@openreplay/tracker-assist';
import { startTracker } from './tracker';

export const isLoggedIn = () => {
  const user = localStorage.getItem('user');
  return !!user;
};

export const useUser = () => {
  const { data, error } = useQuery(gql`
    query getMe {
      me {
        id
        admin
        username
        name
        email
        firstname
        lastname
        lastPasswordChange
        isScout
        rinknetUser {
          id
          firstname
          lastname
        }
        permissions
        roles {
          id
          slug
        }
        groups {
          userGroupSlug
        }
      }
    }
  `);
  const user = data?.me;

  useEffect(() => {
    if (!user) return;
    const { innerWidth: width, innerHeight: height } = window;

    startTracker({
      userID: user.username,
      metadata: {
        width,
        height,
        isAdmin: user.admin,
        isExec: user.roles.find((r) => r.slug == 'hockeyOpsExec'),
      },
    });
  }, [user?.id]);

  return {
    user,
    error,

    isUserAdmin: () => !!user?.admin,
    userHasPermission: (permission) => {
      if (!user) return false;

      const permissions = user.permissions;

      if (user.admin) return true;
      if (!permissions) return false;

      return permissions.includes(permission);
    },
  };
};
