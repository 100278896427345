import { theme } from 'constants/theme';

// Utility function to set spacing
export const setSpacing = (value) =>
  value ? (value === 'auto' ? 'auto' : theme.spacing[value]) : value === 0 ? 0 : null;

// Define spacing variants
export const getSpacingVariants = (prefix) => {
  return [0, 1, 2, 3, 4, 5, 6, 8, 10].reduce(
    (acc, value) => {
      acc[value] = `${prefix}-${value}`;
      return acc;
    },
    {}
  );
};

export const handleDimension = (prefix, value) => {
  return typeof value === 'number' ? `${prefix}-[${value}px]` : `${prefix}-[${value}]`;
};

export const generateDimensionVariants = (prefix) => {
  const dimensions = [
    'auto',
    'full',
    'screen',
    '1/2',
    '1/3',
    '2/3',
    '1/4',
    '2/4',
    '3/4',
    '1/5',
    '2/5',
    '3/5',
    '4/5',
    '1/6',
    '2/6',
    '3/6',
    '4/6',
    '5/6',
  ];
  return dimensions.reduce(
    (acc, dimension) => {
      acc[dimension] = `${prefix}-${dimension}`;
      return acc;
    },
    { custom: (value) => handleDimension(prefix, value) }
  );
};
