import React, { useEffect, memo, useState, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { useDrag, useDrop } from 'react-dnd';
import { itemTypes } from '../../constants';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { SectionHeader, TooltipSpan } from '../reports';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faStickyNote } from '@fortawesome/free-solid-svg-icons';
import { BDDTextArea, HoverInteractive, LinkUnstyled } from '../bdd';
import { Button, Modal } from 'react-bootstrap';
import { bruinsGold } from '../../helpers/plotting';
import BDDEditInline from '../bdd/bddeditinline';
import { IconButton } from 'components/bdd/Button';
import { Clipboard, ClipboardData } from 'react-bootstrap-icons';
import { ListComparePlayersLink } from 'components/Lists/Builder/components/ListComparePlayersLink';
import { Container, Row } from 'components/bdd/Layout';
import { ContextMenuWrapper } from 'components/bdd/ContextMenu/ContextMenuWrapper';
import { buildPlayerRouteV2 } from 'helpers/routing';
import { getBDDPlayerSlug } from 'components/Lists/Builder/helpers';

const Styles = styled.div`
  margin-left: 1%;
  margin-right: 1%;
  margin-top: 2px;
  margin-bottom: 2px;

  ${(props) => props.isDragging && 'opacity: 0;'}
`;

export const PlayerListPlayer = memo(function PlayerListPlayer({
  id,
  player,
  renderPlayer,
  playerSize = 'lg', // "lg" or "sm" effects the size of the side items
  hideRightSide=false,
  movePlayer,
  movePlayerByIdx, // need this for moving without drag
  finishedMovingPlayer,
  moveTier,
  resetList,
  removePlayer,
  addPlayerNote,
  deletePlayerNote,
  allowAddPlayerNote,
  disableRemovePlayer,
  listNumber,
  noDragPreview = true,
  className = 'player-list-player',
  activeClassName,
  editMode = true,
  isLastPlayerInTier = false,
  isSelected,
  selectedClassName,
  listLength,
  tiers,
  disableContextMenu = false,
  extraContextMenuOptions = []
}) {
  const [{ isDragging }, drag, dragPreview] = useDrag(
    {
      canDrag: editMode && !isLastPlayerInTier,
      // check if playerId is being dragged (in case parent tier changes)
      isDragging: (monitor) => id === monitor.getItem().id,
      item: {
        type: itemTypes.PLAYER_LIST_PLAYER,
        id: id,
        listId: player.listId,
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          resetList();
        }
      },
    },
    [player]
  );

  const [, drop] = useDrop({
    accept: [itemTypes.PLAYER_LIST_PLAYER, itemTypes.PLAYER_LIST_TIER],
    // canDrop: () => false,
    hover(item) {
      if (item.type === itemTypes.PLAYER_LIST_TIER) {
        moveTier(item.data.listId, item.data.tierId, id, item.data.startEnd);
      } else if (item.type === itemTypes.PLAYER_LIST_PLAYER) {
        const draggedId = item.id;
        if (draggedId !== id) {
          movePlayer(item.listId, draggedId, id);
        }
      }
    },
    drop: (item, monitor) => {
      // we can't terminate tier drags here because they force movement on hover
      // then they get dropped on nothing
      if (item.type === itemTypes.PLAYER_LIST_PLAYER) {
        const draggedId = item.id;
        finishedMovingPlayer(item.listId, draggedId, listNumber);
      }
    },
  });
  var style = {
    cursor: editMode ? (!isLastPlayerInTier ? 'all-scroll' : 'not-allowed') : 'default',
    opacity: isDragging ? 0.5 : 1,
  };

  if (noDragPreview) {
    useEffect(() => {
      dragPreview(getEmptyImage(), { captureDraggingState: true });
    }, []);
  }

  // don't include a tier if player already in it
  const allowMoveToTier = editMode && !isLastPlayerInTier;
  const tierOptions = useMemo(
    () =>
      allowMoveToTier && !!tiers
        ? tiers
            .filter(
              (t) =>
                !(
                  listNumber >= t.startsWithListNumber &&
                  listNumber <= t.endsWithListNumber
                )
            )
            .sort((a, b) => a.startsWithListNumber - b.startsWithListNumber)
        : [],
    [allowMoveToTier, tiers]
  );

  return (
    <Styles isDragging={isDragging}>
      <div
        className={`${className} ${isDragging ? activeClassName : null}`}
        ref={(node) => drop(drag(node))}
        style={style}
      >
        <PLPlayer
          listNumber={listNumber}
          player={player}
          renderPlayer={renderPlayer}
          playerSize={playerSize}
          hideRightSide={hideRightSide}
          removePlayer={removePlayer}
          allowRemoval={editMode && !disableRemovePlayer && !isLastPlayerInTier}
          allowMove={editMode && !isLastPlayerInTier}
          allowMoveToTier={editMode && !isLastPlayerInTier}
          addPlayerNote={addPlayerNote}
          deletePlayerNote={deletePlayerNote}
          allowAddPlayerNote={allowAddPlayerNote}
          isSelected={isSelected}
          selectedClassName={selectedClassName}
          listLength={listLength}
          movePlayerByIdx={movePlayerByIdx}
          finishedMovingPlayer={finishedMovingPlayer}
          tierOptions={tierOptions}
          disableContextMenu={disableContextMenu}
          extraContextMenuOptions={extraContextMenuOptions}
        />
      </div>
    </Styles>
  );
});

const PLPStyles = styled.div`
  display: table;

  border-radius: 10px;
  box-shadow: 0 0 1px #333;
  overflow: hidden;
  width: ${(props) => props.width};
  background: white;

  .list-number {
    display: table-cell;
    width: ${(props) => (props.size === 'sm' ? '30px' : '60px')};
    min-width: ${(props) => (props.size === 'sm' ? '30px' : '60px')};

    text-align: center;
    vertical-align: middle;
    color: #ddd;
    background: #333;
    ${(props) =>
      props.inMotion
        ? `
            font-weight: bold;
            color: white;
        `
        : ''}
  }
  .number-editor {
    size: 5;
    background: inherit;
    color: inherit;
    outline: none;
    border: none;
    width: 100%;
    text-align: center;
  }

  .content {
    display: table-cell;
    padding: 5px;
    overflow: hidden;
  }
  .right-side-info {
    display: table-cell;
    width: ${(props) => (props.size === 'sm' ? '55px' : '70px')};
    border-left: 1px solid #ddd;

    text-align: center;
    color: #777;
    .icon {
      cursor: pointer;
      &:hover {
        color: black;
      }
    }
  }
  .note-icon-container {
    position: relative;
  }
  .badge {
    z-index: 4;
    position: absolute;
    top: -2px;
    right: -2px;
    border-radius: 50%;
    background: red;
    color: white;
    font-size: 8px;
  }
`;
export const PLPlayer = memo(
  function PLPlayer({
    renderPlayer,
    player,
    playerSize = 'lg',
    hideRightSide=false,
    listNumber,
    removePlayer,
    allowRemoval = true,
    addPlayerNote,
    deletePlayerNote,
    allowAddPlayerNote = true,
    inMotion = false,
    width = '98%',
    isSelected,
    selectedClassName = 'selected-player',
    listLength, // needed for setting max in list number edit
    allowMove = false, // do we allow editing the number to move player?
    movePlayerByIdx,
    finishedMovingPlayer,
    allowMoveToTier = false,
    tierOptions,
    disableContextMenu = false,
    extraContextMenuOptions = []
  }) {
    const [showNoteModal, setShowNoteModal] = useState({
      show: false,
      player,
    });

    const content = renderPlayer ? (
      <div>{renderPlayer(player)}</div>
    ) : (
      <div>
        {player.firstname} {player.lastname}
      </div>
    );

    const notes = player.notes ? player.notes : [];
    const requiresFollowUp = player.notes.filter((n) => n.requiresFollowUp).length;

    const handleMovePlayerToListNumber = (destination, destTierId = null) => {
      if (destination != listNumber || !!destTierId) {
        movePlayerByIdx(player.playerId, destination - 1, destTierId);
        // HACK: need to wait till animation is completed and thus tiers updated before sending to API
        setTimeout(
          () => finishedMovingPlayer(player.listId, player.playerId, destination),
          100
        );
      }
    };

    const findSpotForPlayerInTier = (tier) => {
      if (listNumber > tier.endsWithListNumber) {
        return tier.endsWithListNumber + 1;
      } else {
        return tier.endsWithListNumber;
      } 
    }
    const handleMoveToTier = (tier) => {
      // if player is within the tier do nothing issue warning
      if (
        listNumber >= tier.startsWithListNumber &&
        listNumber <= tier.endsWithListNumber
      ) {
        console.warn('Cannot move player to a tier they currently sit in');
        return;
      }
      const newSpot = findSpotForPlayerInTier(tier);
      handleMovePlayerToListNumber(newSpot, tier.id);
    };

    const playerSlug = getBDDPlayerSlug(player, { idType: player.idType });
    const contextMenuOptions = [
      ...(!!playerSlug 
        ? [{ label: <LinkUnstyled to={buildPlayerRouteV2(playerSlug)}>Go to playercard</LinkUnstyled>, onClick: () => null }]
        : []),
      { label: 'Remove from list', onClick: () => removePlayer(player.playerId) },
    ].concat(
      tierOptions?.map((t) => ({
        label: `Move to tier "${t.title || 'Untitled Tier'}" (#${findSpotForPlayerInTier(
          t
        )})`,
        onClick: () => handleMoveToTier(t),
      }))
    );

    return (
      <ContextMenuWrapper
        options={[
          ...contextMenuOptions,
          ...extraContextMenuOptions
        ]}
        disabled={disableContextMenu}
      >
        <PLPStyles
          className={isSelected ? selectedClassName : ''}
          width={width}
          inMotion={inMotion}
          size={playerSize}
        >
          <div className="list-number" onClick={(ev) => ev.stopPropagation()}>
            {allowMove ? (
              <HoverInteractive
                disabled={!allowMoveToTier || tierOptions.length == 0}
                delay={{ show: 1000, hide: 0 }}
                renderContent={(forceClose) => (
                  <>
                    <div>
                      <b>Move to tier:</b>
                    </div>
                    <div style={{ paddingLeft: '5px' }}>
                      {tierOptions.map((t) => (
                        <div key={t.id}>
                          <a
                            key={t.id}
                            href=""
                            onClick={(e) => {
                              e.preventDefault();
                              handleMoveToTier(t);
                              forceClose();
                            }}
                          >
                            {!!t.title ? t.title : 'Untitled tier'}{' '}
                            <small>
                              <em>
                                (#{t.startsWithListNumber} - #{t.endsWithListNumber})
                              </em>
                            </small>
                          </a>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              >
                <BDDEditInline
                  type="number"
                  defaultValue={listNumber}
                  onChange={(n, v) => handleMovePlayerToListNumber(v)}
                  trackValueChanges={true}
                  activeClassName="number-editor"
                  allowEdit={allowMove}
                  editingProps={{ min: 1, max: listLength }}
                  validate={(val) => val >= 1 && val <= listLength}
                />
              </HoverInteractive>
            ) : (
              <>{listNumber}</>
            )}
          </div>
          <div className="content">{content}</div>
          {!hideRightSide && (<div
            className="right-side-info"
            onClick={(ev) => {
              ev.stopPropagation();
            }}
          >
            <Container padding={2}>
              <Row justifyContent="end" flexWrap>
                {playerSize == 'lg' && (
                  <Container paddingRight={2}>
                    <ListComparePlayersLink listPlayer={player} />
                  </Container>
                )}
                {/* {(notes.length > 0 || allowAddPlayerNote) && (
                  <HoverInteractive
                    delay={{ show: 200, hide: 200 }}
                    title={`${player.firstname} ${player.lastname} Notes`}
                    content={
                      <div style={{ maxWidth: '500px' }}>
                        {notes.map((n) => (
                          <div key={n.id}>
                            {allowAddPlayerNote && (
                              <TooltipSpan
                                content="Remove note"
                                style={{ fontSize: '0.6em' }}
                                onClick={() => deletePlayerNote(n.id)}
                              >
                                <FontAwesomeIcon icon={faMinusCircle} />
                              </TooltipSpan>
                            )}{' '}
                            {n.note}{' '}
                            {n.requiresFollowUp && <small>(requires follow up)</small>}
                          </div>
                        ))}
                        {notes.length === 0 && <em>No notes</em>}
                        {allowAddPlayerNote && (
                          <>
                            <hr></hr>
                            <div>
                              <a
                                href=""
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setShowNoteModal({ ...showNoteModal, show: true });
                                }}
                              >
                                Add note
                              </a>
                            </div>
                          </>
                        )}
                      </div>
                    }
                  >
                    <span
                      className="note-icon-container"
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowNoteModal({ ...showNoteModal, show: true });
                      }}
                    >
                      <FontAwesomeIcon
                        className="icon"
                        style={notes.length > 0 && { color: bruinsGold }}
                        icon={faStickyNote}
                      />
                      <span
                        className="badge"
                        style={{ background: requiresFollowUp > 0 ? 'red' : '#333' }}
                      >
                        {requiresFollowUp > 0
                          ? requiresFollowUp
                          : allowAddPlayerNote
                          ? '+'
                          : ''}
                      </span>
                    </span>
                  </HoverInteractive>
                )} */}
                {allowRemoval && (
                  <HoverInteractive
                    delay={{ show: 200, hide: 0 }}
                    content={
                      <>
                        <Button
                          size="sm"
                          variant="danger"
                          onClick={(ev) => {
                            ev.stopPropagation();
                            removePlayer(player.playerId);
                          }}
                        >
                          Remove from list
                        </Button>
                      </>
                    }
                  >
                    <FontAwesomeIcon
                      style={{ marginLeft: '10px' }}
                      className="icon"
                      icon={faMinusCircle}
                    />
                  </HoverInteractive>
                )}
              </Row>
            </Container>
          </div>)}
        </PLPStyles>
        <div onClick={(ev) => ev.stopPropagation()}>
          {showNoteModal.show && (
            <CreateNoteModal
              show={showNoteModal.show}
              player={showNoteModal.player}
              handleClose={() => setShowNoteModal({ ...showNoteModal, show: false })}
              addPlayerNote={addPlayerNote}
            />
          )}
        </div>
      </ContextMenuWrapper>
    );
  },
  (prevProps, nextProps) => {
    // Custom compairison function -- we want to compare all the props we can
    // And we can't compare "tierOptions" because its an object
    return Object.keys(prevProps).reduce((acc, curr) => {
      const propsEq = ['tierOptions'].includes(curr)
        ? true
        : prevProps[curr] == nextProps[curr];
      return acc && propsEq;
    }, true);
  }
);

const CreateNoteModal = ({ show, handleClose, player, addPlayerNote }) => {
  const [requiresFollowUp, setRequiresFollowUp] = useState(false);
  const [value, setValue] = useState('');
  const presets = [
    'Gummy',
    'Skating',
    'Character - Off-ice',
    'Character - Dressing Room',
    'Sense',
    'Compete',
    'Interior',
    'Development Path',
    'Upside',
    'Identity',
  ];

  const handleSubmit = () => {
    if (value.length === 0) {
      handleClose();
      return;
    }
    addPlayerNote(player.id, value, requiresFollowUp);
    handleClose();
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <form onSubmit={handleSubmit}>
        <Modal.Header>
          <SectionHeader>
            Create {player.firstname} {player.lastname} Note
          </SectionHeader>
        </Modal.Header>
        <Modal.Body>
          <div>
            <label htmlFor="requiresFollowUp">
              <small>Requires Follow Up:</small>
            </label>{' '}
            <input
              type="checkbox"
              id="requiresFollowUp"
              value={requiresFollowUp}
              onChange={(ev) => setRequiresFollowUp(ev.target.value)}
            />
          </div>
          <BDDTextArea
            autoFocus
            placeholder="Player note..."
            value={value}
            onChange={(ev) => setValue(ev.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variante="primary" type="submit" onClick={handleSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
