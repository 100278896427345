import { useQuery } from '@apollo/client';
import { GET_STAT_DEFINITIONS_V2 } from 'apollo/queries/statsv2.queries';
import { Column, Container } from 'components/bdd/Layout';
import { StyledBDDSelect } from 'components/bdd/Select/StyledBDDSelect';
import { Typography } from 'components/bdd/Typography';
import useStatDefinitionSearchSelect from 'components/StatManager/v2/useStatDefinitionsSearchSelect';
import { useContext, useEffect } from 'react';
import { SLStatContext } from '..';

export const StatDefinitionFilter = ({ 
  initialStatSlug,
  alwaysInclude=[], // stats to always include with statslugs 
  statTypes=[], // e.g. "CountSumStat"...
}) => {
  const { filters, setFilters, chartOptions, setChartOptions } =
    useContext(SLStatContext);

  const { searchComponent, selected, selectedOption } =
    useStatDefinitionSearchSelect({
      initialSelectedValue: initialStatSlug,
      isClearable: false,
    });

  useEffect(() => {
    if (!initialStatSlug) return;
    setFilters({
      statSlugs: [...alwaysInclude, initialStatSlug],
      statReport: null,
    });
  }, []);

  useEffect(() => {
    if (!!selected) {
      setFilters({ statSlugs: [...alwaysInclude, selected], statReport: null });
      setChartOptions({
        ...chartOptions,
        options: {
          ...chartOptions.options,
          yAxisStat: selected,
        },
      });
    }
  }, [selected]);

  return (
    <Container minWidth={200}>
      <Column>
        <Typography variant="body1">Stat Definition</Typography>
        {searchComponent}
      </Column>
    </Container>
  );
};
