export const RANKING_SPLIT_FIELDS = [
  'position',
  'timeRange',
  'isDraftEligible',
  'leagueGroup',
  'league',
  'predictedGrades',
  'maxViewsPerScout',
  'tieredNorm',
];
export const SCOUTING_METASTATS_SPLIT_FIELDS = [
  'position',
  'league',
  'leagueGroup',
  'wasDraftEligible',
  'ageGroup',
  'scoutId',
];
