import { useQuery } from "@apollo/client";
import { Container } from "components/bdd/Layout";
import usePlaceholder from "components/Placeholder/usePlaceholder";
import { ScoutingGradesV2 } from "./ScoutingGradesV2";
import { GET_FOCUSED_SCOUTED_PLAYER_RANKINGS } from "apollo/queries/scouting.queries";


export const PlayerScoutingGradesRows = ({
  rinknetId,
  filterSplits,
  limitScoutViews, // If true, will limit to 3 views per scout as a split attr
}) => {
  const { data, placeholder } = usePlaceholder(
    useQuery(GET_FOCUSED_SCOUTED_PLAYER_RANKINGS, {
      variables: {
        rinknetId,
        limitScoutViews,
      },
    })
  );
  const rinknetPlayer = data?.focusedScoutedPlayerRankings?.rinknetPlayer;

  if (!!placeholder) return placeholder;

  const allSplits = data?.focusedScoutedPlayerRankings?.splits;
  const filteredSplits = filterSplits
    ? allSplits.filter((s) => {
        return filterSplits(s.split);
      })
    : allSplits;
  return (
    <Container overflow="auto">
      {filteredSplits.map((split) => (
        <ScoutingGradesV2
          key={split.id}
          split={split.split}
          rinknetPlayer={rinknetPlayer}
          rankings={split.rankings}
          attributes={split.attributes}
          position={split.position}
          totalPlayers={split.totalPlayers}
        />
      ))}
    </Container>
  );
};