import { useQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GET_PLAYER_HOCKEY_MODEL_RESULTS } from 'apollo/queries/models.queries';
import { Border, Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import { HighlightBox } from 'components/bdd/warcomponents';
import usePlaceholder from 'components/Placeholder/usePlaceholder';
import { roundToX } from 'helpers/data';
import { getRedGreenColor } from 'helpers/tables';
import styled from 'styled-components';
import { theme } from 'constants';
import { TooltipSpan } from 'components/reports';

import { faPeopleArrows } from '@fortawesome/free-solid-svg-icons';
import SimilarPlayersModal from './SimilarPlayersModal';
import { getCurrentDraftYear } from 'helpers/hockeyutils';
import { useState } from 'react';

const SimilarPlayersIcon = styled.div({
  alignItems: 'center',
  gap: theme.spacing[1],
  padding: theme.spacing[1],
});

export const AnalyticModelScores = ({ rinknetId, bddPlayer }) => {
  const { data, placeholder } = usePlaceholder(
    useQuery(GET_PLAYER_HOCKEY_MODEL_RESULTS, {
      variables: {
        modelSlugs: [
          'draft-prod-f-score',
          'draft-prod-f-center',
          'draft-prod-f-outcome',
          'draft-prod-f-nhl',
          'draft-prod-d-score',
          'draft-prod-d-outcome',
          'draft-prod-d-nhl',
          'draft-scout-f-score',
          'draft-scout-f-center',
          'draft-scout-f-outcome',
          'draft-scout-f-nhl',
          'draft-scout-d-score',
          'draft-scout-d-outcome',
          'draft-scout-d-nhl',
          'draft-overall-f-score',
          'draft-overall-f-center',
          'draft-overall-f-outcome',
          'draft-overall-f-nhl',
          'draft-overall-d-score',
          'draft-overall-d-outcome',
          'draft-overall-d-nhl',
          'draft-similar-players',
          'draft-similar-players-sl',
          "draft-sl-f-score",
          "draft-sl-f-center",
          "draft-sl-f-outcome",
          "draft-sl-f-nhl",
          "draft-sl-d-score",
          "draft-sl-d-outcome",
          "draft-sl-d-nhl",
        ],
        rinknetId: rinknetId,
      },
    })
  );
  const [showSimilarPlayers, setShowSimilarPlayers] = useState(false);
  const pos = bddPlayer?.posFDG;
  if (!data) return placeholder;

  if (!data.hockeyModelResults || data.hockeyModelResults.length == 0) {
    return (
      <Typography variant="body1">
        <em>No model scores found</em>
      </Typography>
    );
  }

  const scoutingModels = [
    {
      title: 'Overall',
      outcomeSlug: pos == 'D' ? 'draft-overall-d-score' : 'draft-overall-f-score',
      nhlSlug: pos == 'D' ? 'draft-overall-d-nhl' : 'draft-overall-f-nhl',
      // pickSlug: 'draft-overall-to-pick-number',
    },
    {
      title: 'Scouting',
      outcomeSlug: pos == 'D' ? 'draft-scout-d-score' : 'draft-scout-f-score',
      nhlSlug: pos == 'D' ? 'draft-scout-d-nhl' : 'draft-scout-f-nhl',
      // pickSlug: 'draft-scouting-to-pick-number',
    },
    {
      title: 'Production',
      outcomeSlug: pos == 'D' ? 'draft-prod-d-score' : 'draft-prod-f-score',
      nhlSlug: pos == 'D' ? 'draft-prod-d-nhl' : 'draft-prod-f-nhl',
      // pickSlug: 'draft-production-to-pick-number',
    },
    {
      title: 'SL',
      outcomeSlug: pos == 'D' ? 'draft-sl-d-score' : 'draft-sl-f-score',
      nhlSlug: pos == 'D' ? 'draft-sl-d-nhl' : 'draft-sl-f-nhl',
      // pickSlug: 'draft-sl-to-pick-number',
    },
  ];

  const similarPlayersModels = [{ title: 'Made It %', slug: 'draft-similar-players' }];

  const modelToScore = Object.fromEntries(
    data.hockeyModelResults.map((result) => [result.modelSlug, result])
  );

  const modelHover = (modelToScore, outcomeSlug, nhlSlug, pickSlug) => {
    const outcomeModelDetails = !!modelToScore[outcomeSlug]?.details
      ? modelToScore[outcomeSlug].parsedDetails
      : null;
    const pNHL = !!modelToScore[nhlSlug]?.modelScore
      ? modelToScore[nhlSlug].modelScore
      : null;
    const score = !!modelToScore[outcomeSlug]?.modelScore
      ? modelToScore[outcomeSlug].modelScore
      : null;
    const percentile = !!modelToScore[outcomeSlug]?.percentile
      ? modelToScore[outcomeSlug].percentile
      : null;
    // const expectedPick = !!modelToScore[pickSlug]?.modelScore
    //   ? modelToScore[pickSlug].modelScore
    //   : null;
    if (!outcomeModelDetails) {
      return <></>;
    }
    const probabilities = [
      { key: 'pT6', title: 'Top' },
      { key: 'pM6', title: 'Middle' },
      { key: 'pB6', title: 'Bottom' },
      { key: 'pDep', title: 'Depth' },
      { key: 'pDW', title: 'Not NHL' },
    ];
    const probs = probabilities.filter((prob) => !!outcomeModelDetails[prob.key]);
    const maxScore = probs
      .map(({ key, title }) => outcomeModelDetails[key])
      .sort((a, b) => b - a)[0];

    return (
      <Container>
        <Row columnGap={2}>
          {probabilities
            .filter((prob) => !!outcomeModelDetails[prob.key])
            .map(({ key, title }) => (
              <HighlightBox
                key={key}
                margin={'0px'}
                title={
                  <Typography variant="body1" textAlign="center">
                    {title}
                  </Typography>
                }
                content={
                  <Typography variant="stat">
                    {roundToX(outcomeModelDetails[key] * 100, 0, true) + '%'}
                  </Typography>
                }
                color={getRedGreenColor(
                  outcomeModelDetails[key],
                  0,
                  maxScore
                )} /* highlight according to max score. Every model score has red and green */
              />
            ))}
          <HighlightBox
            key={'nhl'}
            title={
              <Typography variant="body1" textAlign="center">
                {'NHL'}
              </Typography>
            }
            content={
              <Typography variant="stat">
                {roundToX(pNHL * 100, 0, true) + '%'}
              </Typography>
            }
            color={getRedGreenColor(pNHL, 0, 1)}
          />
          <HighlightBox
            key={'score'}
            title={
              <Typography variant="body1" textAlign="center">
                {'Score'}
              </Typography>
            }
            content={
              <Typography variant="stat">
                {roundToX(score, 1, true)}
              </Typography>
            }
            color={getRedGreenColor(percentile, 0, 1)}
          />
        </Row>
      </Container>
    );
  };

  return (
    <Container>
      <Row columnGap={2}>
        {scoutingModels.map(({ title, outcomeSlug, nhlSlug, pickSlug }) => (
          <HighlightBox
            key={outcomeSlug}
            width={'30px'}
            margin="0px"
            title={
              <Typography variant="body1" textAlign="center">
                {title}
              </Typography>
            }
            content={
              <Typography variant="stat" textAlign="center">
                {roundToX(modelToScore[outcomeSlug]?.percentile * 100, 0)}
              </Typography>
            }
            hoverContent={modelHover(modelToScore, outcomeSlug, nhlSlug, pickSlug)}
            color={getRedGreenColor(
              modelToScore[outcomeSlug]?.percentile,
              0,
              1,
              false,
              2
            )}
          />
        ))}
        <TooltipSpan content="Click for similar players">
          <SimilarPlayersIcon onClick={() => setShowSimilarPlayers(true)}>
            <HighlightBox
              key={'similar-players-made-it'}
              margin="0px"
              title={
                <Typography variant="body1" textAlign="center">
                  {'Made It %'}
                </Typography>
              }
              width={'30px'}
              content={
                <Typography variant="stat" textAlign="center">
                  {roundToX(
                    modelToScore['draft-similar-players']?.parsedDetails.metrics
                      ?.made_it_pct,
                    0
                  )}
                </Typography>
              }
              color={getRedGreenColor(
                modelToScore['draft-similar-players']?.parsedDetails.metrics?.made_it_pct,
                0,
                40,
                false,
                2
              )}
            />
          </SimilarPlayersIcon>
        </TooltipSpan>
        {!!bddPlayer && showSimilarPlayers && (
          <SimilarPlayersModal
            show={showSimilarPlayers}
            handleClose={() => setShowSimilarPlayers(false)}
            bddPlayer={bddPlayer}
            draftYear={getCurrentDraftYear()}
          />
        )}
      </Row>
    </Container>
  );
};
