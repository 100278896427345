import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cn } from '@udecode/cn';
import { cva } from 'class-variance-authority';
import { getSpacingVariants } from './helpers';

export const flexVariants = cva('flex', {
  variants: {
    direction: {
      row: 'flex-row',
      column: 'flex-col',
      rowReverse: 'flex-row-reverse',
      columnReverse: 'flex-col-reverse',
    },
    align: {
      start: 'items-start',
      center: 'items-center',
      end: 'items-end',
      stretch: 'items-stretch',
      baseline: 'items-baseline',
    },
    justify: {
      start: 'justify-start',
      center: 'justify-center',
      end: 'justify-end',
      between: 'justify-between',
      around: 'justify-around',
      evenly: 'justify-evenly',
    },
    wrap: {
      true: 'flex-wrap',
      false: 'flex-nowrap',
    },
    wrapReverse: {
      true: 'flex-wrap-reverse',
      false: null,
    },
    grow: {
      true: 'grow',
      false: null,
    },
    gap: getSpacingVariants('gap'),
    rowGap: getSpacingVariants('gap-y'),
    columnGap: getSpacingVariants('gap-x'),
  },
  defaultVariants: {
    direction: 'row',
    align: 'center',
    justify: 'start',
    wrap: 'nowrap',
  },
});

export const Flex = React.forwardRef(
  (
    {
      asChild = false,
      className,
      direction = 'row',
      align: alignArg,
      justify,
      wrap,
      wrapReverse,
      gap,
      rowGap,
      columnGap,
      grow,
      fill,
      style,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : 'div';
    const align = alignArg || (direction === 'column' ? 'start' : 'center');

    return (
      <Comp
        ref={ref}
        className={cn(
          flexVariants({
            direction,
            align,
            justify,
            wrap,
            wrapReverse,
            gap,
            rowGap,
            columnGap,
            grow,
          }),
          fill && 'w-full',
          className
        )}
        style={style}
        {...props}
      />
    );
  }
);
