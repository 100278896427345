import { IconButton } from 'components/bdd/Button';
import { At } from 'react-bootstrap-icons';
import { useSlate } from 'slate-react';
import { ToolbarIconButton } from '../..';
import { insertMention, isMentionActive, unwrapMention } from './helpers';

export const MentionToolbarButton = ({}) => {
  const editor = useSlate();
  return (
    <ToolbarIconButton
      icon={<At />}
      active={isMentionActive(editor)}
      onMouseDown={(event) => {
        event.preventDefault();
        if (isMentionActive(editor)) {
          unwrapMention(editor);
        } else {
          insertMention(editor);
        }
      }}
    />
  );
};
