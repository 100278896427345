import styled from "styled-components"
import { BDDInput } from "./BDDInput"

const DecoratedInputContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'stretch',
});
const InputDecortion = styled.div(({ isLeft }) => ({
  background: '#ddd',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  borderRadius: isLeft ? `5px 0 0 5px` : `0 5px 5px 0`,
  padding: '0 2px',
}));
export const DecoratedBDDInput = ({
  frontDecoration,
  backDecoration,
  flexGrow,
  ...rest
}) => (
  <DecoratedInputContainer>
    {!!frontDecoration && (
      <InputDecortion isLeft={true}>{frontDecoration}</InputDecortion>
    )}
    <div style={{ flexGrow: flexGrow }}>
      <BDDInput
        flatLeft={!!frontDecoration}
        flatRight={!!backDecoration}
        {...rest}
      />
    </div>
    {!!backDecoration && <InputDecortion>{backDecoration}</InputDecortion>}
  </DecoratedInputContainer>
);