import { useQuery } from '@apollo/client';
import { GET_USER_GROUPS } from 'apollo/queries/user.queries';
import { usePlayerScoutGradeSummary } from 'components/ScoutingV2/hooks';
import { HoverInteractive } from 'components/bdd';
import { LinkButton } from 'components/bdd/Button';
import Icon from 'components/bdd/Icon';
import { Container, Row } from 'components/bdd/Layout';
import { LinkPopover } from 'components/bdd/Popover';
import { Cell, Header } from 'components/bdd/Table';
import { Typography } from 'components/bdd/Typography';
import BDDSortableTable from 'components/bdd/bddsortabletable';
import { roundToX } from 'helpers/data';
import { List, SortDown, SortUp } from 'react-bootstrap-icons';

const getColumns = (scouts, draftRoundOrder) => [
  {
    id: 'scout',
    Header: <Header compact>Scout</Header>,
    Cell: ({
      cell: {
        row: { original: s },
      },
    }) => {
      const scout = scouts.find((scout) => s.scoutId == scout.userid);

      return scout ? <Cell>{`${scout.firstname} ${scout.lastname}`}</Cell> : null;
    },
  },
  {
    id: 'lastViewedDate',
    Header: <Header compact>Last Viewed</Header>,
    accessor: (s) => s.lastViewedDate,
    // sortType: sortDate,
    sortDescFirst: true,
    Cell: ({
      cell: {
        row: { original: s },
      },
    }) => <Cell compact>{s.lastViewedDate}</Cell>,
  },
  {
    id: 'nextViewDate',
    Header: <Header compact>Next View</Header>,
    accessor: (s) => s.nextViewDate,
    // sortType: sortDate,
    sortDescFirst: true,
    Cell: ({
      cell: {
        row: { original: s },
      },
    }) => <Cell compact>{s.nextViewTentative ? s.nextViewDate + '*' : s.nextViewDate}</Cell>,
  },
  {
    id: 'recentDraftRound',
    Header: <Header compact>Most Recent Draft Round</Header>,
    accessor: (s) => draftRoundOrder.indexOf(s.summary.most_recent),
    Cell: ({
      cell: {
        row: { original: s },
      },
    }) => <Cell compact>{s.summary.most_recent || 'N/A'}</Cell>,
  },
  {
    id: 'reportCount',
    Header: <Header compact>Report Count</Header>,
    accessor: (s) => s.reportCount,
    sortDescFirst: true,
    Cell: ({
      cell: {
        row: { original: s },
      },
    }) => <Cell compact>{s.reportCount}</Cell>,
  },
  {
    id: 'listPosition',
    Header: <Header compact>List Position</Header>,
    // sortType: sortNumeric,
    // sortDescFirst: true,
    accessor: (s) => s.listPosition || 999,
    Cell: ({
      cell: {
        row: { original: s },
      },
    }) => (
      <Cell compact>
        {s.listPosition ? `${s.listPosition} / ${s.listLength}` : 'Not Listed'}
      </Cell>
    ),
  },
];

const StatLinkButton = ({ label, icon }) => {
  return (
    <Row columnGap={1}>
      <Icon icon={icon} />
      <Typography variant="stat" noWrap>
        {label}
      </Typography>
    </Row>
  );
};

const getAverageListPercentage = (grades) => {
  if (!grades || grades.length == 0) return 0;

  const totalPercentage = grades.reduce((avgListPosition, grade) => {
    avgListPosition += (grade.listPosition / grade.listLength) * 100;

    return avgListPosition;
  }, 0);

  return totalPercentage / grades.length;
};

export const ScoutReportSummary = ({ rinknetId, showTable }) => {
  let {
    grades: allGrades,
    scouts,
    draftRoundOrder,
  } = usePlayerScoutGradeSummary({ rinknetId });

  const { data: userGroupData } = useQuery(GET_USER_GROUPS);

  if (!allGrades || !userGroupData) return null;

  const userGroups = userGroupData.userGroups;
  const scoutUserIds = userGroups
    .find((ug) => ug.slug == 'amateur-scouts')
    .users.map((u) => u.userId);

  const crossoverScoutUserIds = userGroups
    .find((ug) => ug.slug == 'amateur-crossover-scouts')
    .users.map((u) => u.userId);

  scouts = scouts.filter((s) => scoutUserIds.includes(s.bddUser?.id));

  const grades = allGrades.filter(
    (g) =>
      !!g.summary.most_recent &&
      g.summary.most_recent != 'Under' &&
      scouts.find((s) => s.userid == g.scoutId)
  );

  const gradesByScout = grades.filter((g) => !!g.scoutId);
  if (gradesByScout.length == 0) return null;
  const sortedScoutGrades = [...gradesByScout].sort(
    (a, b) =>
      draftRoundOrder.indexOf(a.summary.most_recent) -
      draftRoundOrder.indexOf(b.summary.most_recent)
  );

  const highestDraftRound = sortedScoutGrades[0].summary.most_recent;
  const lowestDraftRound =
    sortedScoutGrades[sortedScoutGrades.length - 1].summary.most_recent;
  const gradesWithListPosition = gradesByScout.filter(
    (g) => !!g.listPosition && g.listLength > 10
  );
  const averageListPercentage = getAverageListPercentage(gradesWithListPosition);
  const crossoverListPercentage = getAverageListPercentage(
    gradesWithListPosition.filter((g) => {
      const scout = scouts.find((scout) => g.scoutId == scout.userid);
      return crossoverScoutUserIds.includes(scout?.bddUser?.id);
    })
  );

  const table = (
    <BDDSortableTable
      columns={getColumns(scouts, draftRoundOrder)}
      data={grades.filter((g) => !!g.scoutId)}
      flexLayout
      defaultSort={[{ id: 'highestDraftRound', desc: false }]}
      columnJustify="start"
    />
  );

  if (grades.length == 0) return null;
  if (showTable) return table;

  return (
    <Container minWidth={125}>
      <LinkPopover
        hideTransition
        renderLink={(setShow, target) => (
          <LinkButton
            ref={target}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setShow((show) => !show);
            }}
          >
            <Row columnGap={2}>
              <Row columnGap={2}>
                <StatLinkButton icon={<SortUp />} label={highestDraftRound} />
                <StatLinkButton icon={<SortDown />} label={lowestDraftRound} />
              </Row>
              {crossoverListPercentage > 0 && (
                <StatLinkButton
                  icon={<List />}
                  label={`Top ${roundToX(crossoverListPercentage, 0)}% on crossover`}
                />
              )}
            </Row>
          </LinkButton>
        )}
        renderContent={() => <Container padding={2}>{table}</Container>}
      />
      {/* <HoverInteractive content={<Container padding={2}>{table}</Container>}>
        <Container>
          <Row columnGap={2}>
            <Row columnGap={2}>
              <StatLinkButton icon={<SortUp />} label={highestDraftRound} />
              <StatLinkButton icon={<SortDown />} label={lowestDraftRound} />
            </Row>
            {crossoverListPercentage > 0 && (
              <StatLinkButton
                icon={<List />}
                label={`Top ${roundToX(crossoverListPercentage, 0)}% on crossover`}
              />
            )}
          </Row>
        </Container>
      </HoverInteractive> */}
    </Container>
  );
};
