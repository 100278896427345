import { Card } from "components/bdd/Card";
import { Container, Grid, GridArea } from "components/bdd/Layout"
import { theme } from "constants";
import { ScenarioHeader } from "./ScenarioHeader";
import { useContext } from "react";
import { WindowContext } from "components/bdd/context";
import { ScenarioContext } from "./ScenarioContextProvider";
import { ScenarioPicks } from "./ScenarioPicks";
import { Link, useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";
import { IconButton } from "components/bdd/Button";
import { Fullscreen } from "react-bootstrap-icons";
import { ScenarioDepth } from "./ScenarioDepth";
import { ScenarioTx } from "./ScenarioTx";
import { ScenarioDiagnostics } from "./ScenarioDiagnostics";
import { ScenarioNotes } from "./ScenarioNotes";
import { Typography } from "components/bdd/Typography";


const desktopGridStyles = {
  columnGap: theme.spacing[4],
  rowGap: theme.spacing[4],
  // height: '120vh',
  gridTemplateColumns: '1fr 1fr',
  gridTemplateAreas: `
    "header header"
    "diagnostics diagnostics"
    "picks tx"
    "depth tx"
    "notes notes"
  `
};

const mobileGridStyles = {
  columnGap: theme.spacing[2],
  rowGap: theme.spacing[2],
  display: 'flex',
  flexDirection: 'column',
};


export const ScenarioOverview = ({ scenario, hideHeader=false }) => {
  const { width } = useContext(WindowContext);
  const { proObjective, updateScenario, refetchScenario, scenarioLoading } = useContext(ScenarioContext);
  const { url, ...rest } = useRouteMatch();

  const header = !hideHeader && (
    <GridArea area="header">
      <Card>
        <ScenarioHeader scenario={scenario} updateScenario={updateScenario} />
      </Card>
    </GridArea>
  );

  const picks = (
    <GridArea area="picks">
      <Card>
        <ScenarioPicks scenario={scenario} />
      </Card>
    </GridArea>
  );

  const depth = (
    <GridArea area="depth">
      <Card
        header="Roster"
        actionContent={
          <Link to={`${url.replace('/overview', '')}/roster`}>
            <IconButton icon={<Fullscreen />} tooltip="Enlarge depth chart" />
          </Link>
        }
      >
        <ScenarioDepth
          scenario={scenario}
          onUpdate={() => {
            refetchScenario({
              id: scenario.id,
              includeCapSheet: true,
              includeChecks: true,
              includeTeamStrength: true,
              cacheOnly: false,
            });
          }}
        />
      </Card>
    </GridArea>
  );

  const diagnostics = (
    <GridArea area='diagnostics'>
      <Card>
        <ScenarioDiagnostics scenario={scenario} proObjective={proObjective} externalLoading={scenarioLoading} />
      </Card>
    </GridArea>
  )

  const tx = (
    <GridArea area='tx'>
      <Card>
        <Container maxHeight='80vh' overflow='auto'>
          <ScenarioTx scenario={scenario} />
        </Container>
      </Card>
    </GridArea>
  )

  const notes = (
    <GridArea area='notes'>
      <Card>
        <Container maxHeight='80vh' overflow='auto'>
          <Typography variant='h6'>Notes</Typography>
          <ScenarioNotes scenario={scenario} allowEdit={true} />
        </Container>
      </Card>
    </GridArea>
  )

  return (
    <Grid
      style={
        width >= theme.breakpoints.sm
          ? desktopGridStyles
          : mobileGridStyles
      }
    >
      {!hideHeader && header}
      {diagnostics}
      {picks}
      {depth}
      {tx}
      {notes}
    </Grid>
  )
}