import { useFilterContext } from './context';
import { useRegisterFilterTag } from './Group';
import { FilterTag } from '.';
import { Checkbox } from '../Checkbox';

export const CheckboxFilter = ({ id, label, ignoreIndeterminate }) => {
  const filter = useFilterContext((state) => state.filters[id]);
  const { setFilters } = useFilterContext((state) => state.actions);
  useRegisterFilterTag(id, (filter) => (
    <FilterTag name={label} label={filter.checked ? 'True' : 'False'} />
  ));

  return (
    <Checkbox
      label={label}
      checked={filter?.checked}
      ignoreIndeterminate={ignoreIndeterminate}
      onChange={() => setFilters(id, { id, type: 'boolean', checked: !filter?.checked })}
    />
  );
};
