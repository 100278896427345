import { useQuery } from '@apollo/client';
import { GET_BF_TEAM_SEASON_SUMMARY } from 'apollo/queries/bruinsfriendly.queries';
import { HoverInfo } from 'components/bdd';
import { LinkButton } from 'components/bdd/Button';
import { Column, Container, Row } from 'components/bdd/Layout';
import { useModal } from 'components/bdd/Modal';
import { Typography } from 'components/bdd/Typography';
import usePlaceholder from 'components/Placeholder/usePlaceholder';
import { formatDollars } from 'helpers/contracts';
import TeamCapBreakdown from './TeamCapBreakdown';
import { Divider } from 'components/bdd/Divider';
import { CAP_TERM_DEFINITIONS } from 'components/SalaryCap/constants/definitions';
import { GET_PRO_OBJECTIVE_SCENARIOS } from 'apollo/queries/proobjective.queries';
import { useBFOrScenarioTeamSeasonSummary } from './useBFOrScenarioTeamSeasonSummary';
import { TeamCapScenario } from 'components/SalaryCap/TeamCapScenario';

export const TeamCapBrief = ({
  teamSlug,
  scenarioUniqueKey,
  showMoreButton = true,
  fieldsFilter = null, // if supplied as a list of keys, will only show these fields
  truncate = false, // if true, will truncate dollars
  smallPlaceholder = false, // if true, will show a small placeholder
}) => {
  const { teamData, placeholder, loading } = useBFOrScenarioTeamSeasonSummary({
    teamSlug,
    scenarioUniqueKey,
  });

  const { showModal, setShowModal, renderModal } = useModal({
    title: `${teamData?.bfTeam?.name || teamData?.teamSlug} Cap Info`,
    size: 'xl',
  });

  if (!!placeholder)
    return smallPlaceholder ? (
      loading ? (
        <Typography variant="body2">Loading...</Typography>
      ) : null
    ) : (
      placeholder
    );
  return (
    <Container>
      {showModal &&
        renderModal(
          !!scenarioUniqueKey ? (
            <TeamCapScenario
              teamSlug={teamSlug}
              scenarioKey={scenarioUniqueKey}
            />
          ) : (
            <TeamCapBreakdown teamSlug={teamSlug} />
          )
        )}
      {!!teamData && (
        <TeamCapBriefFields
          teamData={teamData}
          showMoreButton={showMoreButton}
          onShowMore={() => setShowModal(true)}
          fieldsFilter={fieldsFilter}
          truncate={truncate}
        />
      )}
    </Container>
  );
};

export const TeamCapBriefFields = ({
  teamData,
  showMoreButton = true,
  onShowMore,
  fieldsFilter = null, // if supplied as a list of keys, will only show these fields
  truncate = false, // if true, will truncate dollars
}) => {
  const fullGroups = [
    [
      {
        key: 'projectedPerfBonus',
        label: 'System Perf Amount',
        isDollars: true,
        info: CAP_TERM_DEFINITIONS.projectedPerfBonus,
      },
      {
        key: 'accumulatedSalary',
        label: 'Accumulated Cap Hit',
        isDollars: true,
        info: CAP_TERM_DEFINITIONS.accumulatedSalary,
      },
      {
        key: 'tradeDeadlineCapSpace',
        label: 'Deadline Cap Space',
        info: CAP_TERM_DEFINITIONS.tradeDeadlineCapSpace,
        isDollars: true,
      },
    ],
    [
      {
        key: 'rosterCapHit',
        label: 'Roster Cap Hit',
        info: CAP_TERM_DEFINITIONS.rosterCapHit,
        isDollars: true,
      },
      {
        key: 'numContracts',
        label: 'Contracts',
      },
      {
        key: 'injuryCount',
        label: 'Injuries',
      },
    ],
    [
      {
        key: 'ltiAcsl',
        label: 'LTI ACSL',
        info: CAP_TERM_DEFINITIONS.ltiACSL,
        isLti: true,
      },
      {
        key: 'ltirSalaryRemaining',
        label: 'LTI Salary Room',
        info: CAP_TERM_DEFINITIONS.ltirSalaryRemaining,
        isLti: true,
      },
      {
        key: 'ltirBonusRemaining',
        label: 'LTI Bonus Room',
        info: CAP_TERM_DEFINITIONS.ltirSalaryRemaining,
        isLti: true,
      },
    ],
    [
      {
        key: 'deadCapHit',
        label: 'Dead Cap Hit',
        info: CAP_TERM_DEFINITIONS.deadCapHit,
        isDollars: true,
      },
      {
        key: 'buriedCapHit',
        label: 'Buried Cap Hit',
        info: CAP_TERM_DEFINITIONS.buriedCapHit,
        isDollars: true,
      },
      {
        key: 'pastSeasonPerfBonusOverage',
        label: 'Past Season Overage',
        info: CAP_TERM_DEFINITIONS.pastSeasonBonusOverage,
        isDollars: true,
      },
    ],
  ];

  const smallGroups = [
    [
      {
        key: 'projectedCapHit',
        label: 'Cap Amt',
        isDollars: true,
        info: CAP_TERM_DEFINITIONS.projectedCapHit,
      },
      {
        key: 'projectedCapSpace',
        label: 'Cap Room',
        isDollars: true,
        info: CAP_TERM_DEFINITIONS.projectedCapSpace,
      },
      {
        key: 'projectedLtirUsed',
        label: 'Proj. LTI Used',
        isLti: true,
        info: CAP_TERM_DEFINITIONS.projectedLtirUsed,
      },
    ],
    [
      {
        key: 'currentCapHit',
        label: 'Curr. Cap Hit',
        isDollars: true,
        info: CAP_TERM_DEFINITIONS.currentCapHit,
      },
      {
        key: 'currentCapSpace',
        label: 'Curr. Cap Space',
        isDollars: true,
        info: CAP_TERM_DEFINITIONS.currentCapSpace,
      },
      {
        key: 'rosterCapHit',
        label: 'Roster Cap Hit',
        isDollars: true,
        info: CAP_TERM_DEFINITIONS.rosterCapHit,
      },
    ],
    [
      {
        key: 'rosterSize',
        label: 'Roster',
      },
      {
        key: 'injuryCount',
        label: 'Injuries',
      },
      {
        key: 'more',
        label: (
          <LinkButton onClick={() => onShowMore()} style={{ color: '#777' }}>
            More cap info...
          </LinkButton>
        ),
        content: null,
      },
    ],
  ];

  const fieldGroups = showMoreButton ? smallGroups : fullGroups;

  const formatLti = (f, is_in_lti) =>
    !!is_in_lti ? formatDollars(f, { truncate }) : '--';

  return (
    <Container>
      <Row justifyContent="space-between" alignItems="start" gap={8}>
        {fieldGroups.map((fields, i) => (
          <Column key={i} gap={1}>
            {fields
              .filter(
                (f) =>
                  (!fieldsFilter || fieldsFilter.includes(f.key)) &&
                  (f.key in teamData || f.key === 'more')
              )
              .map(({ key, label, info, isDollars, isLti, content }) => (
                <Row key={key} gap={6}>
                  <HoverInfo content={info}>
                    <Typography
                      variant="body1"
                      // style={
                      //   !!info
                      //     ? { textDecoration: 'underline', textDecorationStyle: 'dashed' }
                      //     : {}
                      // }
                    >
                      {label}
                      {key !== 'more' && ':'}
                    </Typography>
                  </HoverInfo>
                  <Typography variant="body2">
                    {!!content
                      ? content
                      : !!isLti
                      ? formatLti(teamData?.[key], teamData.isInLti)
                      : !!isDollars
                      ? formatDollars(teamData?.[key], { truncate })
                      : teamData?.[key]}
                  </Typography>
                </Row>
              ))}
          </Column>
        ))}
      </Row>
      {/* <Divider></Divider> */}
    </Container>
  );
};
