import { createApolloStore } from 'apollo/hooks';
import { GET_FULL_SCOUTED_ATTRIBUTES_SET } from 'apollo/queries/scouting.queries';
import { GET_PLAYER_SCOUT_GRADE_SUMMARY } from 'apollo/queries/scoutingobjectives.queries';
import { filterUnique } from 'helpers/helpers';

export const usePlayerScoutGradeSummaryStore = createApolloStore({
  // query: GET_FULL_SCOUTED_ATTRIBUTES_SET
  query: GET_PLAYER_SCOUT_GRADE_SUMMARY,
  idKey: 'rinknetIds',
  setState: (data, get) => {
    return {
      playerScoutGradeSummary: filterUnique(
        [
          ...(get().playerScoutGradeSummary || []),
          ...data.scoutReportSummary.summary,
        ],
        ['scoutId', 'rinknetId']
      ),
      scouts: filterUnique(
        [...(get().scouts || []), ...data.scoutReportSummary.scouts],
        ['userid']
      ),
      draftRoundOrder: data.scoutReportSummary.draftRoundOrder,
    };
  },
});

export const PlayerScoutGradeSummaryLoader = (props) => {
  usePlayerScoutGradeSummaryLoader(props);
};

export const usePlayerScoutGradeSummaryLoader = ({ rinknetIds, startDate }) => {
  const useQuery = usePlayerScoutGradeSummaryStore(
    (store) => store.actions.useEntityQuery
  );

  return useQuery({
    variables: {
      rinknetIds,
      startDate,
    },
  });
};

export const usePlayerScoutGradeSummary = ({ rinknetId }) => {
  const draftRoundOrder = usePlayerScoutGradeSummaryStore(
    (state) => state.draftRoundOrder
  );
  const scouts = usePlayerScoutGradeSummaryStore((state) => state.scouts);
  const grades = usePlayerScoutGradeSummaryStore((state) =>
    state.playerScoutGradeSummary?.filter((e) => e.rinknetId == rinknetId)
  );

  return { scouts, grades, draftRoundOrder };
};
