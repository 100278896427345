import {
  faArrowCircleUp,
  faArrowsAltV,
  faBrain,
  faBullseye,
  faCalculator,
  faExpandArrowsAlt,
  faFire,
  faHockeyPuck,
  faShieldAlt,
  faSkating,
  faChartBar,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDraftPlayerChecks } from 'components/ScoutingV2/hooks/usePlayerDraftChecks';
import { HoverInteractive } from 'components/bdd';
import Icon from 'components/bdd/Icon';
import { Column, Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import { roundToX } from 'helpers/data';

export const FORWARDS_CHECK_ORDER = [
  'F_MODEL_SCORES',
  'F_PROJECTION',
  'F_PRODUCTION',
  'F_SENSE_OR_PM',
  'F_SKATING_OR_HEIGHT',
  'F_PUCK_HANDLING',
];

export const DEFENSEMEN_CHECK_ORDER = [
  'D_MODEL_SCORES',
  'D_PROJECTION_OR_TYPE',
  'D_SCORING_OR_PRODUCTION',
  'D_PUCK_HANDLING',
  'D_SENSE',
  'D_SKATING_OR_HEIGHT',
];

// NOTE: Keep this up-to-date with bs.draft_checks.py
export const CHECK_ID_TO_DESCRIPTION = {
  F_MODEL_SCORES: 'Above 13.5% Made It AND 78.5% OVR Scores (Green) | Above 13.5% Made It OR 78.5% OVR Scores (Yellow)',
  F_PROJECTION: 'M6 or Higher Projection >70% (Green) | M6 or Higher Projection >20% (Yellow)',
  F_PRODUCTION: 'PPG >60% or GPG >60% (Green) | PPG >50% or GPG >42% (Yellow)',
  F_SENSE_OR_PM: 'Hockey Sense >75% or +/- >55% (Green) | Hockey Sense >35% or +/- >40% (Yellow)',
  F_SKATING_OR_HEIGHT: 'Skating >70% or >6ft2.5in (Green) | Skating >25% (Yellow)',
  F_PUCK_HANDLING: 'Puck Handling >80% (Green) | Puck Handling >16% (Yellow)',
  D_MODEL_SCORES: 'Above 13.5% Made It AND 78.5% OVR Scores (Green), Above 13.5% Made It OR 78.5% OVR Scores (Yellow)',
  D_PROJECTION_OR_TYPE:  'MP or Higher Projection >70% or Player Type not Defensive/Physical/Tough >85% (Green) | BP or Higher Projection >60% or Player Type not Defensive/Physical/Tough >60% (Yellow)',
  D_SCORING_OR_PRODUCTION: 'Scoring >70% or PPG >60% or GPG >60% (Green) | Scoring >40% or PPG >32% or GPG >55% (Yellow)',
  D_PUCK_HANDLING: 'Puck Handling >70% (Green) | Puck Handling >38% (Yellow)',
  D_SENSE: 'Hockey Sense >75% (Green) | Hockey Sense >50% (Yellow)',
  D_SKATING_OR_HEIGHT: 'Skating >90% or >6ft4in (Green)',
};

export const checkIdToIcon = { 
  F_MODEL_SCORES: faChartBar,
  F_PROJECTION: faArrowCircleUp,
  F_PRODUCTION: faCalculator,
  F_SENSE_OR_PM: faBrain,
  F_SKATING_OR_HEIGHT: faSkating,
  F_PUCK_HANDLING: faHockeyPuck,
  D_MODEL_SCORES: faChartBar,
  D_PROJECTION_OR_TYPE:  faArrowCircleUp,
  D_SCORING_OR_PRODUCTION: faCalculator,
  D_PUCK_HANDLING: faHockeyPuck,
  D_SENSE: faBrain,
  D_SKATING_OR_HEIGHT: faSkating,
};

export const posToChecks = (position) => {
  position = position?.toLocaleLowerCase();
  return position === 'f' ? FORWARDS_CHECK_ORDER : DEFENSEMEN_CHECK_ORDER;
};

const DraftPlayerCheck = ({ check }) => {
  const payload = !!check.payload?.length ? JSON.parse(check.payload) : {};

  return (
    <Typography
      style={{
        fontSize: '1.0em',
        color: check.checkValue == 'HIGH_PASS' ? 'green' :  check.checkValue == 'LOW_PASS' ? 'gold':  'red',
      }}
    >
      <HoverInteractive
        content={
          <Column>
            <Typography variant="body1">{check.checkDescription}</Typography>
            {Object.keys(payload).map((k) => (
              <Row key={k} gap={8}>
                <Typography variant="body2">{k}:</Typography>
                <Typography variant="body2">
                  {isNaN(payload[k]) ? payload[k] : roundToX(payload[k], 2)}
                </Typography>
              </Row>
            ))}
            <Typography variant="caption">
              Based on {check.draftYear} draft year
            </Typography>
          </Column>
        }
      >
        {!!checkIdToIcon[check.checkId] ? (
          <FontAwesomeIcon icon={checkIdToIcon[check.checkId]} />
        ) : (
          'NA'
        )}
        {/* <Icon icon={!!checkIdToIcon[check.checkId] ? checkIdToIcon[check.checkId] : <XCircle/]} /> */}
      </HoverInteractive>
    </Typography>
  );
};

export const DraftPlayerCheckSimple = ({ id, checkValue }) => {
  console.log(checkValue);
  return (
    <Typography
      style={{
        fontSize: '0.8em',
        color:
          checkValue == 'HIGH_PASS'
            ? 'green'
            : checkValue == 'LOW_PASS'
            ? 'gold'
            : 'red',
      }}
    >
      {!!checkIdToIcon[id] ? (
        <FontAwesomeIcon icon={checkIdToIcon[id]} />
      ) : (
        'NA'
      )}
    </Typography>
  );
};

export const DraftPlayerChecks = ({ rinknetId, rinknetIds, draftYear, pos }) => {
  const checkKeys = posToChecks(pos.toLowerCase());
  const { checks } = useDraftPlayerChecks({ rinknetId, rinknetIds, draftYear });
  const orderedChecks = checks?.sort(
    (a, b) => checkKeys.indexOf(a.checkId) - checkKeys.indexOf(b.checkId)
  );
  return (
    <Container>
      <Row gap={8}>
        {orderedChecks?.map((c) => (
          <DraftPlayerCheck key={c.id} check={c} />
        ))}
      </Row>
    </Container>
  );
};
