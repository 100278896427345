import React, { useEffect, useRef, useState } from 'react';
import { Fade, Overlay, Popover } from 'react-bootstrap';
import { X, XLg } from 'react-bootstrap-icons';
import { Transition } from 'react-transition-group';
import styled from 'styled-components';
import BDDErrorBoundary from '../bdderrorboundary';
import { IconButton, LinkButton } from '../Button';
import { Container, Row } from '../Layout';
import { Typography } from '../Typography';

const PopoverStyles = styled.div(({ arrowColor }) => ({
  '.bs-popover-right .arrow:after': {
    borderRightColor: `${arrowColor} !important`,
  },
}));

export const LinkPopover = React.forwardRef(
  (
    {
      disabled,
      placement,
      hideTransition,
      title,
      closeButton,
      children,
      renderContent,
      renderLink,
      popoverProps,
      popoverBodyProps,
      arrowColor,
      defaultShow,
    },
    ref
  ) => {
    const [show, setShow] = useState(defaultShow);
    const target = useRef(null);
    const forceClose = () => {
      setShow(() => false);
    };

    useEffect(() => {
      const closeModal = (e) => {
        forceClose();
      };

      if (ref && ref.current) {
        ref.current.addEventListener('click', closeModal);
      }

      return () => {
        ref?.current?.removeEventListener('click', closeModal);
      };
    }, []);

    return (
      <>
        {renderLink ? (
          renderLink(setShow, target, show)
        ) : (
          <LinkButton
            underline="none"
            ref={target}
            onClick={() => {
              setShow((show) => !show);
            }}
          >
            {children}
          </LinkButton>
        )}
        {renderContent && (
          <Overlay
            show={show && !disabled}
            onHide={() => {
              setShow(false);
            }}
            placement={placement}
            flip={placement === 'auto'}
            target={target}
            rootClose
            transition={hideTransition ? false : Fade}
          >
            {(props) => (
              <PopoverStyles arrowColor={arrowColor}>
                <Popover
                  {...props}
                  {...popoverProps}
                  style={{
                    maxWidth: '100%',
                    boxShadow: '0 0 5px #ddd',
                    ...props.style,
                    ...(popoverProps?.style ? popoverProps.style : {}),
                  }}
                  id={`popover-hoverinfo-${title || parseInt(Math.random() * 100)}`}
                >
                  {(title || closeButton) && (
                    <Popover.Header>
                      <Row justifyContent="space-between">
                        <Typography variant="subtitle1">{title}</Typography>
                        <IconButton icon={<XLg />} onClick={forceClose} />
                      </Row>
                    </Popover.Header>
                  )}
                  <Popover.Body {...popoverBodyProps}>
                    <BDDErrorBoundary>
                      {renderContent && renderContent(forceClose)}
                    </BDDErrorBoundary>
                  </Popover.Body>
                </Popover>
              </PopoverStyles>
            )}
          </Overlay>
        )}
      </>
    );
  }
);
