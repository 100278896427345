import { Container, Row } from 'components/bdd/Layout';
import { FilterContextProvider, useFilterContext } from './context';
import BDDErrorBoundary from 'components/bdd/bdderrorboundary';
import { CheckboxFilter } from './CheckboxFilter';
import { SelectFilter } from './SelectFilter';
import { InputRangeFilter } from './InputRangeFilter';
import { FilterGroup } from './Group';
import { DateFilter } from './DateFilter';
import { FilterControls, ResetFilters } from './FilterControls';
import { NumberFilter } from './NumberFilter';
import { Field } from './Field';
import { ButtonGroupFilter } from './ButtonGroupFilter';
import { ToggleFilter } from './ToggleFilter';
import { SelectRangeFilter } from './SelectRangeFilter';
import { useEffect } from 'react';
import { SelectAndRangeFilter } from './SelectAndRangeFilter';
import { InputFilter } from './InputFilter';
import { ColorFilter } from './ColorFilter';
import { IconFilter } from './IconFilter';
import { DropdownFilter } from './DropdownFilter';
import { TextareaFilter } from './TextareaFilter';

const FiltersRoot = ({
  children,
  defaults,
  useSearchParams,
  searchParamKey,
  onChange,
  onSearchParamsLoad,
  updateOnDefaultsChange,
  className,
}) => {
  return (
    <BDDErrorBoundary>
      <FilterContextProvider
        onChange={onChange}
        defaults={defaults}
        useSearchParams={useSearchParams}
        searchParamKey={searchParamKey}
        onSearchParamsLoad={onSearchParamsLoad}
      >
        <Container className={className}>{children}</Container>
        {updateOnDefaultsChange && <DefaultsListener defaults={defaults} />}
      </FilterContextProvider>
    </BDDErrorBoundary>
  );
};

const DefaultsListener = ({ defaults }) => {
  const { replaceFilters } = useFilterContext((state) => state.actions);

  useEffect(() => {
    replaceFilters(defaults);
  }, [JSON.stringify(defaults)]);
};

export const Filters = Object.assign(FiltersRoot, {
  Controls: FilterControls,
  Reset: ResetFilters,
  Group: FilterGroup,
  Input: InputFilter,
  InputRange: InputRangeFilter,
  Select: SelectFilter,
  SelectRange: SelectRangeFilter,
  SelectAndRange: SelectAndRangeFilter,
  Checkbox: CheckboxFilter,
  Dropdown: DropdownFilter,
  Date: DateFilter,
  Number: NumberFilter,
  Field: Field,
  ButtonGroup: ButtonGroupFilter,
  Toggle: ToggleFilter,
  Color: ColorFilter,
  Icon: IconFilter,
  Textarea: TextareaFilter,
});
