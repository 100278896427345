import React, { useEffect, useState } from 'react';
import { roundToX } from 'helpers/data';
import { useScoutingModelDataStore, useScoutingRankDataStore, useScoutingRanks } from '.';
import { ListFilterForm } from '../components/ListFilterForm';
import { usePlayerScoutGradeSummaryStore } from 'components/ScoutingV2/hooks';
import { getScoutingRanks } from './useScoutingRankDataLoader';
import { useDraftPlayerCheckStore } from 'components/ScoutingV2/hooks/usePlayerDraftChecks';
import { usePlayerMadeItResultStore } from 'components/DraftApp/DraftListPlayerMadeItResult';

export const usePlayerListFilter = () => {
  const { rankingsByPlayer, splits } = useScoutingRankDataStore();
  const modelToScore = useScoutingModelDataStore((state) => state.modelToScore);
  const grades = usePlayerScoutGradeSummaryStore(
    (state) => state.playerScoutGradeSummary
  );

  const checksByRinknetId = useDraftPlayerCheckStore((state) => state.checksByRinknetId);
  const playerMadeItResultsByRinknetId = usePlayerMadeItResultStore(
    (state) => state.resultsByRinknetId
  );

  const getScoutingValue = (rinknetId, rating) => {
    const { ratingToRank } = getScoutingRanks(rinknetId, rankingsByPlayer, splits, {
      predictedGrades: false,
    });

    if (ratingToRank && ratingToRank[rating]?.percentile) {
      return roundToX(100 * (1 - ratingToRank[rating]?.percentile));
    }

    return 0;
  };

  const getPredictedScoutingValue = (rinknetId, rating) => {
    const { ratingToRank } = getScoutingRanks(rinknetId, rankingsByPlayer, splits, {
      predictedGrades: true,
    });

    if (ratingToRank && ratingToRank[rating]?.percentile) {
      return roundToX(100 * (1 - ratingToRank[rating]?.percentile));
    }

    return 0;
  };

  const getModelScore = (rinknetId, outcomeSlug) => {
    if (modelToScore && modelToScore(rinknetId)[outcomeSlug]?.percentile) {
      return roundToX(100 * modelToScore(rinknetId)[outcomeSlug]?.percentile);
    }

    return 0;
  };

  const getScoutingGradeSummary = (rinknetId) => {
    const overallGrade = grades?.find((g) => g.rinknetId == rinknetId && !g.scoutId);

    return overallGrade?.summary.high;
  };

  const getDraftCheckValue = (rinknetId, checkId) => {
    if (!(rinknetId in checksByRinknetId)) return null;

    const check = checksByRinknetId[rinknetId].find((c) => c.checkId == checkId);

    if (!check) return null;

    return check.checkValue;
  };

  const getPlayerMadeItValue = (rinknetId) => {
    if (!(rinknetId in playerMadeItResultsByRinknetId)) return 0;

    return playerMadeItResultsByRinknetId[rinknetId].madeItPct;
  };

  const filterOptions = {
    getScoutingValue,
    getModelScore,
    getScoutingGradeSummary,
    getPredictedScoutingValue,
    getDraftCheckValue,
    getPlayerMadeItValue,
  };

  const [appliedFilters, setAppliedFilters] = useState();
  const filterForm = (
    <ListFilterForm
      reset={!appliedFilters}
      onChange={(values) => setAppliedFilters(values)}
    />
  );

  const sortAndFilterPlayers = (rinknetPlayers) => {
    // Filter players based on each input filter condition
    const filteredPlayers = !!appliedFilters?.filters
      ? rinknetPlayers?.filter((p) => {
          return Object.values(appliedFilters.filters).every((f) => {
            const filterValue = f.filter.getValue(p.rinknetPlayer, filterOptions);

            return f.filter.type == 'inputRange'
              ? filterValue >= f.value.min && filterValue <= f.value.max
              : f.filter.type == 'checkbox'
              ? f.value == null
                ? true
                : filterValue == f.value
              : filterValue == f.value;
          });
        })
      : rinknetPlayers;

    // Sort players based on state sorted value
    const sorted = appliedFilters?.sorted;
    let sortedPlayers = sorted
      ? filteredPlayers.sort(
          (b, a) =>
            sorted.getValue(a.rinknetPlayer, filterOptions) -
            sorted.getValue(b.rinknetPlayer, filterOptions)
        )
      : filteredPlayers;

    if (sorted?.direction == 'desc') {
      sortedPlayers = sortedPlayers.reverse();
    }

    return sortedPlayers?.map((p) => p.player);
  };

  return {
    sortAndFilterPlayers,
    inputFilters: filterForm,
    filtersChanged: !!appliedFilters,
    resetFilters: () => setAppliedFilters(),
    appliedFilters,
  };
};
