import { X } from 'react-bootstrap-icons';
import { IconButton } from '../Button';
import { Container, Flex, Row } from '../Layout';
import { Typography } from '../Typography';
import { useFilterContext } from './context';
import React from 'react';
import { theme } from 'constants';
import { Text } from '../Text';

export const Field = React.memo(
  ({
    id,
    children,
    width,
    label,
    isClearable,
    subLabel,
    onChange,
    className,
    required,
  }) => {
    const filter = useFilterContext((state) => state.filters[id]);
    const { setFilters, removeFilter } = useFilterContext((state) => state.actions);

    return (
      <Container width={width} className={className}>
        <Row justifyContent="space-between">
          <Flex gap={1}>
            <Text variant="label" color="muted">
              {label}
            </Text>
            {required && !filter?.value && (
              <Text variant="caption" color="destructive">
                *
              </Text>
            )}
          </Flex>
          <Row columnGap={2} flexWrap>
            {subLabel}
            {filter?.value != undefined && filter?.value != null && isClearable && (
              <IconButton
                icon={<X />}
                padding={0}
                hoverColor={theme.colors.light.text.secondary}
                onClick={() => {
                  removeFilter(id);
                  onChange && onChange();
                }}
              />
            )}
          </Row>
        </Row>
        {typeof children === 'function' ? children({ filter, setFilters }) : children}
      </Container>
    );
  }
);
