import { customPlayerFieldToinfo } from "components/Lists/constants"
import { useEffect, useState } from "react"
import { Gear } from "react-bootstrap-icons"
import styled from "styled-components"
import CustomListPlayerModal from "../components/CustomListPlayerModal"

const CustomGear = styled(Gear)({
  paddingBottom: '1px',
  cursor: 'pointer',
  fill: '#777',
  '&:hover': {
    fill: '#000',
  },
  transition: '0.2s'
})


export default function useCustomlistPlayerMode({
  list,
  defaultSettings: defaultSettingsArg={},
  useLocalStorage=true,
  saveOnChange=true
}) {
  const [show, setShow] = useState(false)

  const key = !!list?.idType ? `listplayersettings-${list?.idType}` : null
  let defaultSettings = {}
  
  if (useLocalStorage && !!key) {
    try {
      var parsed = JSON.parse(localStorage.getItem(key))
    } catch {
      parsed = {}
    }
    defaultSettings = { ...parsed, ...defaultSettingsArg }
  } else {
    defaultSettings = defaultSettingsArg
  }


  const handleWriteLocalStorage = value => {
    console.log('writing storage', key, value)
    localStorage.setItem(key, JSON.stringify(value))
  }

  const [settings, _setSettings] = useState(defaultSettings)

  const setSettings = (newSettings, forceWrite=false) => {
    _setSettings(newSettings)
    if (saveOnChange || forceWrite) {
      handleWriteLocalStorage(newSettings)
    }
  }

  const fields = !!settings?.fields ? settings.fields.filter(f => f in customPlayerFieldToinfo) : []

  useEffect(() => {
    if (!!key) {
      _setSettings(defaultSettings)
    }
  }, [list])

  // Catch the case where we somehow have a non-existent field
  useEffect(() => {
    const nonExistentFields = fields.filter(f => !(f in customPlayerFieldToinfo))
    if (nonExistentFields.length) {
      setFields(fields.filter(f => f in customPlayerFieldToinfo), true)
    }
  }, [fields])
  
  const setFields = (fields, forceWrite=false) => {
    const newSettings = {...settings, fields}
    setSettings(newSettings, forceWrite)
  }


  const customFieldsModal = <CustomListPlayerModal
    show={show}
    handleClose={() => setShow(false)}
    list={list}
    fields={fields}
    setFields={setFields}
    settings={settings}
    setSettings={setSettings}
  />

  const customGearIconButton = <span onClick={() => setShow(true)}>
    <CustomGear />
  </span>

  return {
    settings,
    setSettings,
    fields, // ordered list of fields that should appear
    setFields,
    customFieldsModal,
    customGearIconButton,
    show,
    setShow
  }
}