import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faCheck, faPlusCircle } from '@fortawesome/free-solid-svg-icons'

import { ADD_PLAYER_TAG, GET_BDD_TAGS, GET_BDD_TAGS_FOR_PLAYER, GET_BDD_TAG_TYPES, REMOVE_PLAYER_TAG, UPDATE_BDD_TAG_TYPE } from '../../apollo/queries/bddtags.queries'
import BDDApiError from '../bdd/bddapierror'
import { BDDLoader } from '../bdd/bddloader'
import { Row, Col, Container } from 'react-bootstrap'
import BDDTag from './bddtag'
import AddBDDTagForm from './addbddtagform'
import { SubSectionHeader } from '../reports'
import { toastBddApiError, toastInfo } from '../bdd/bddtoasts'
import { bruinsGold } from '../../helpers/plotting'
import { HoverInteractive } from '../bdd'
import Toggle from 'components/bdd/Toggle/Toggle'

const Styles = styled.div`
    .small {
        font-weight: bold;
    }
`
export default function ManagePlayerTags({
    bddPlayerSlug,
    addTagUpdateCallback, // add more fields to the mutation update function
    removeTagUpdateCallback
}) {
    const [editTag, setEditTag] = useState({ editing: false, tag: null })
    const { data, loading, error } = useQuery(GET_BDD_TAGS_FOR_PLAYER, { variables: { slug: bddPlayerSlug }})
    const [addPlayerTag, { loading: addingTag }] = useMutation(ADD_PLAYER_TAG, {
        onError: toastBddApiError,
        update: (cache, { data: { addPlayerTag: { bddPlayer }} }) => {
            cache.modify({
                fields: {
                    bddPlayer(existing={}) {
                        if (existing.slug == bddPlayer.slug) {
                            return bddPlayer
                        }
                        return existing
                    }
                }
            })
            if (!!addTagUpdateCallback) {
              addTagUpdateCallback(cache, bddPlayer.slug, bddPlayer.bddManagedTags)
            }
        }
    })
    const [removePlayerTag, { loading: removingTag }] = useMutation(REMOVE_PLAYER_TAG, {
        onError: toastBddApiError,
        update: (cache, { data: { removePlayerTag: { bddPlayer }} }) => {
            cache.modify({
                fields: {
                    bddPlayer(existing={}) {
                        if (existing.slug == bddPlayer.slug) {
                            return bddPlayer
                        }
                        return existing
                    }
                },
              })
              if (!!removeTagUpdateCallback) {
                removeTagUpdateCallback(cache, bddPlayer.slug, bddPlayer.bddManagedTags)
              } 
        }
    })

    const activePlayerTags = !!data ? data.bddPlayer.bddManagedTags : []
    const activeTags = activePlayerTags.map(pt => pt.tag.tag)
    const activeRoleIsProjection = activePlayerTags.filter(pt => pt.isProjection && pt.tag.tagType === 'ROLE').length > 0
    const handleAddPlayerTag = (tag, flags) => {
        addPlayerTag({ variables: {
            bddPlayerSlug,
            tagId: tag.id,
            isPublic: true,
            flags
        }})
    }
    const handleRemovePlayerTag = tag => {
        removePlayerTag({ variables: { 
            bddPlayerSlug,
            tagId: tag.id,
            isPublic: true
        }})
    }
    const handleSelectPlayerTag = (tag, flags=[]) => {
        if (activeTags.includes(tag.tag)) {
            handleRemovePlayerTag(tag)
        } else {
            handleAddPlayerTag(tag, flags)
        }
    }

    if (loading) return <BDDLoader/>
    if (error) return <BDDApiError error={error} />
    const bddPlayer = data.bddPlayer
    const playerTags = data.bddPlayer.bddManagedTags
    return <>
    {editTag.editing ? <>
        <Row>
            <Col sm='1' className='backarrow' onClick={() => setEditTag({ editing: false, tag: null })}>
                <FontAwesomeIcon icon={faArrowLeft}/>
            </Col>
            <Col>
                <SubSectionHeader>Edit Tag</SubSectionHeader>
            </Col>
            <Col sm='1'></Col>
        </Row>
        <Container fluid style={{ border: '1px solid #ddd', padding: '5px' }}>
            <AddBDDTagForm isUpdate={true} 
                initialValues={editTag.tag} 
                onCompleted={() => setEditTag({ editing: false, tag: null })} 
            />
        </Container>
    </> : <Styles>
        <Row className='small'>
            <Col sm='auto'>
                {bddPlayer.lastname}'s tags:
            </Col>
            <Col>
                {playerTags.map(pt => <BDDTag tag={pt.tag} key={pt.id} isProjection={pt.isProjection}/>)}
                {playerTags.length == 0 && <em>No tags found</em>}
            </Col>
        </Row>
        <hr></hr>
        <BDDTagsBank 
            handleEdit={tag => setEditTag({ editing: true, tag })} 
            handleSelect={handleSelectPlayerTag}
            activeTags={activeTags}
            posFDG={data.bddPlayer.posFDG}
            activeRoleIsProjection={activeRoleIsProjection}
        />
    </Styles>}
    </>
}

const TagsBankStyles = styled.div`
    padding: 20px;
    border: 1px solid #ddd;
    min-height: 30px;
    max-height: 600px;
    overflow: scroll;
    font-size: 0.8em;
    text-align: center;

    table {
        text-align: center;
        width: 100%;
        table-layout: fixed;
        border-collapse:separate; 
        border-spacing: 5px;
        border-style: hidden;
        tr {
            box-shadow: 0 0 3px #ddd;
            border-radius: 10px;
        }
        
        td.isactive {
            opacity: 1;
        }
        td.inactive {
            opacity: 0.5;
        }

        td {
            vertical-align: middle;
            padding: 5px 0px;
            &:hover {
                opacity: 1;
            }
            transition: opacity 0.2s ease;
        }
        padding: 5px;
    }
    .add-icon {
        color: #ddd;
        cursor: pointer;
        &:hover {
            color: #333;
        }
        transition: color 0.2s ease;
    }
`
export const BDDTagsBank = ({
    handleEdit,
    allowEdit=true,
    handleSelect,
    activeTags=[],
    posFDG,
    activeRoleIsProjection=false
}) => {
    const { data, loading, error } = useQuery(GET_BDD_TAGS)
    const { data: ttData } = useQuery(GET_BDD_TAG_TYPES)

    const [updateBddTagType] = useMutation(UPDATE_BDD_TAG_TYPE, {
      onCompleted: () => toastInfo('Tag type updated'),
      onError: toastBddApiError
    })

    if (loading) return <BDDLoader />
    if (error) return <BDDApiError error={error} />
    
    const handleUpdateMutuallyExclusive = (tagType, mutuallyExclusive) => {
      updateBddTagType({
        variables: {
          input: {
            slug: tagType,
            isMutuallyExclusive: mutuallyExclusive
          }
        }
      })
    }

    const tagTypeToTags = {AVAILABILITY: [], ROLE: []}
    // filter tags for position (if we know position to filter for)
    var filteredTags = !!posFDG ? 
        data.bddTags.filter(t => !t.requiresPosition || t.requiresPosition.length == 0 || t.requiresPosition.includes(posFDG)) 
        : [...data.bddTags]
    // sort tags by priority
    filteredTags = filteredTags.sort((a,b) => b.priority - a.priority)

    filteredTags.forEach(t => {
        const tagType = !!t.tagType ? t.tagType : 'OTHER'
        if (!(tagType in tagTypeToTags)) {
            tagTypeToTags[tagType] = []
        }
        tagTypeToTags[tagType].push(t)
    })
    var tagTypes = []
    Object.keys(tagTypeToTags).forEach(tt => {
        if (!(tagTypes.includes(tt))) {
            tagTypes.push(tt)
        }
    })
    const tagOrder = ['AVAILABILITY', 'ROLE']
    tagTypes = tagTypes.sort((a,b) => tagOrder.indexOf(b) - tagOrder.indexOf(a))
    return <TagsBankStyles>
        <center><small><em>Note: Availability and Role tags are mutually exclusive</em></small></center>
        <Row>
            {tagTypes.map(tt => <Col key={tt} sm={6}>
                <center><b>{tt}</b></center>
                {!tagOrder.includes(tt) && !!ttData?.bddTagTypes?.find(t => t.slug === tt) && (
                  allowEdit
                    ? (
                      <Toggle
                        id={tt}
                        label='Mutually Exclusive'
                        toggled={ttData.bddTagTypes.find(t => t.slug === tt)?.isMutuallyExclusive}
                        setToggled={val => handleUpdateMutuallyExclusive(tt, val)}
                      />
                    ) : (
                      <em>{ttData.bddTagTypes.find(t => t.slug === tt)?.isMutuallyExclusive && 'Mutually exclusive'}</em>
                    )
                )}
                <table>
                <tbody>
                    {tagTypeToTags[tt].map(tag => {
                        const isActive = activeTags.includes(tag.tag)
                        return <tr key={tag.id} onClick={() => handleSelect(tag)}>
                            <td>
                                {isActive ? <FontAwesomeIcon icon={faCheck} />
                                    : <HoverInteractive 
                                        delay={300}
                                        content={<>
                                            <a href='' style={{color: 'black'}} onClick={ev => {
                                                ev.preventDefault()
                                                ev.stopPropagation()
                                                handleSelect(tag, ['isProjection'])
                                            }}>
                                                <small>
                                                    Add as projection
                                                </small>
                                            </a>
                                        </>}
                                    >
                                        <FontAwesomeIcon icon={faPlusCircle} className='add-icon' />
                                    </HoverInteractive>
                                }
                            </td>
                            <td className={isActive ? 'isactive' : 'inactive'}>
                                <BDDTag tag={tag} isProjection={tag.tagType === 'ROLE' && activeRoleIsProjection}/>
                            </td>
                            <td>
                                {allowEdit && <a href=""
                                    onClick={ev => {
                                        ev.preventDefault()
                                        ev.stopPropagation()
                                        handleEdit(tag)
                                    }}
                                    style={{ fontStyle: 'italic' }}
                                >
                                    edit
                                </a>}
                            </td>
                        </tr>
                    })}
                </tbody>
                </table>
            </Col>)}
        </Row>
    </TagsBankStyles>
}