import React, { useState, useCallback, useEffect } from "react"
import { gql, useLazyQuery } from "@apollo/client"
import { Col, Form, Row } from "react-bootstrap"
import { components } from "react-select"
import styled from "styled-components"
import { Link, useHistory } from "react-router-dom"


import { debounce } from "../../helpers/throttle_debounce"
import { getNHLTeamLogoUrl } from "../../helpers/logos";
import BDDSelect from "../bdd/bddselect"

const Option = ({data, ...props}) => {
    return (
        <components.Option {...props}>
            <div style={{fontSize: "0.8em", color: 'black'}}>
                {data.teamid ? 
                    <img 
                        src={getNHLTeamLogoUrl(data.teamid)} 
                        style={{height: "18px"}}
                    /> : null}
                {data.type === 'team' ? <b>{data.label}</b> : <>{data.label}</>}
                {data.teamid ? null :
                    data.subLabel ?
                    <em style={{fontSize: "0.6em", margin: "0px", padding: "0px" }}>
                        - {data.subLabel}
                    </em>
                    : null
                }
            </div>
        </components.Option>
    );
};


const Styles = styled.div`
    // z-index: 9999;
    width: 100%;
`


export default function SearchSelect({ 
    debounceMS=400,
    placeholder='Search',
    menuPrompt='Try Pasta',
    resultTypes=['BDDPLAYERS', 'TEAMS'],
    handleSelect,
    customStyles: customStylesArg,
    selectRef, 
    defaultTerm='',
    league=null
}) {
    const [searchTerm, setSearchTerm] = useState(defaultTerm)
    const [selected, setSelected] = useState(null)
    const [latestResults, setLatestResults] = useState([])
    const [getSearchResults, { data, error, loading }] = useLazyQuery(gql`
        query Search(
            $term: String!, 
            $n: Int, 
            $minScore: Float, 
            $resultTypes: [SearchResultTypes!],
            $league: [String!]
        ) {
            search(term: $term, n: $n, minScore: $minScore, resultTypes: $resultTypes, league: $league) {
                score
                result {
                    __typename
                    ... on BDDPlayerSlug {
                        slug
                        firstname
                        lastname
                        slUid
                        rinknetId
                        rinknetPlayer {
                            id
                            currentteam
                            currentteamnhlid
                        }
                    }
                    ... on RinknetPlayer {
                        id
                        realId
                        rpfirstname: firstname
                        rplastname: lastname
                        currentteam
                        currentteamnhlid
                        currentleague
                        bddPlayerSlug {
                            slug
                        }
                    }
                    ... on SLPlayer {
                        slSlUid: slUid
                        playerId
                        leagueSlug
                        slTeam {
                          slug
                          nhlid
                        }
                        slfirstname: firstname
                        sllastname: lastname
                    }
                    ... on SLTeam {
                        id
                        nhlid
                        slug
                        longname
                        shorthand
                    }
                }
            }
        }`)

    const logAndQuery = (t) => {
        getSearchResults(t)
    }
    const debouncedGetSearchResults = useCallback(debounce(logAndQuery, debounceMS), []);

    const handleSubmit = (ev) => {
        ev.preventDefault()
    }

    const handleChange = (opt) => {
        handleSelect(opt)
    }

    const handleInputChange = (t, action) => {
        setSearchTerm(t)
        if (t.length) {
            debouncedGetSearchResults({variables: {
                term: t,
                minScore: 0.10,
                resultTypes,
                league: !!league ? [league]: null
            }})
        }
    }

    useEffect(() => {
        if (!!defaultTerm && defaultTerm.length > 0) {
            logAndQuery({variables: {
                term: defaultTerm,
                minScore: 0.10,
                resultTypes,
                league
            }})
        }
    }, [])

    const customStyles = {...customStylesArg}

    const components = { 
        Option: Option, 
        DropdownIndicator: () => null,
        IndicatorSeparator:() => null
    }

    useEffect(() => {
        if (!loading && !!data) {
            setLatestResults(data.search.map(s => {
                if (s.result.__typename === 'BDDPlayerSlug') {
                    if(!s.result.rinknetPlayer) {
                        return null;
                    }

                    return {
                        label: `${s.result.firstname} ${s.result.lastname}`,
                        value: s.result.slug,
                        teamid: s.result.rinknetPlayer.currentteamnhlid,
                        subLabel: s.result.rinknetPlayer.currentteam,
                        league: s.result.rinknetPlayer.currentleague,
                        type: 'player'
                    }
                } else if (s.result.__typename === 'SLTeam') {
                    return {
                        label: s.result.longname, 
                        value: s.result.slug,
                        teamid: s.result.nhlid,
                        type: 'team'
                    }
                } else if (s.result.__typename === 'RinknetPlayer') {
                    return {
                        label: `${s.result.rpfirstname} ${s.result.rplastname}`,
                        value: s.result.realId,
                        teamid: s.result.currentteamnhlid,
                        subLabel: s.result.currentteam,
                        league: s.result.currentleague,
                        type: 'player'
                    }
                } else if (s.result.__typename === 'SLPlayer') {
                    return {
                        label: `${s.result.slfirstname} ${s.result.sllastname}`,
                        value: `${s.result.slSlUid}_${s.result.leagueSlug}_${s.result.playerId}`,
                        teamid: s.result.leagueSlug === 'NHL' ? s.result.slTeam?.nhlid : null,
                        subLabel: s.result.slTeam?.longname,
                        league: s.result.leagueSlug,
                        type: 'player'
                    }
                }
            })
            .filter(s => !!s))
        }
    }, [loading, data])
    const options = searchTerm.length ? latestResults : []
    return (
      <Form inline={'1'} onSubmit={handleSubmit}>
        <Styles>
          <BDDSelect
            selectRef={selectRef}
            defaultOnChange
            name="search"
            placeholder={placeholder}
            isSearchable
            isLoading={loading || (searchTerm.length && !options.length)}
            options={options}
            value={selected}
            onChange={handleChange}
            filterOption={({ label, value, data }) => true}
            inputValue={searchTerm}
            onInputChange={handleInputChange}
            components={components}
            autosize={false}
            styles={customStyles}
            noOptionsMessage={(input) =>
              input.inputValue.length ? 'No results found' : menuPrompt
            }
            menuPortalTarget={document.body}
          />
        </Styles>
      </Form>
    );
        
}
