import React, { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_SCOUTED_PLAYER_RANKINGS } from 'apollo/queries/scouting.queries';
import { BDDLoader } from 'components/bdd/bddloader';
import BDDApiError from 'components/bdd/bddapierror';
import { numberToOrdinal } from 'helpers/helpers';
import { SectionHeader, TooltipSpan } from 'components/reports';
import BDDLinkButton from 'components/bdd/bddlinkbutton';
import { Button, Modal } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChooseSplitTable } from 'components/Scouting/AggregatedRatings/choosesplittable';
import RankingsTable from 'components/Scouting/AggregatedRatings/rankingstable';

import {
  faBrain,
  faBullseye,
  faEye,
  faHockeyPuck,
  faSkating,
  faArrowsAltH,
  faVectorSquare,
  faShieldAlt,
  faMagic,
  faChevronCircleRight,
  faFire,
  faChartLine,
  faListOl,
  faHouseUser,
  faUsers,
  faClock,
} from '@fortawesome/free-solid-svg-icons';
import { theme } from 'constants';
import styled from 'styled-components';
import { getRedGreenColor } from 'helpers/tables';
import { roundToX } from 'helpers/data';
import { Typography } from 'components/bdd/Typography';
import {
  getCurrentSeason,
  getSeasonPlusMinusYears,
  nameToInitials,
  season8to4,
  seasonToEndYear,
  seasonToStartYear,
} from 'helpers/hockeyutils';
import ReportsByScoutModal from './ReportsByScoutModal';
import { HoverInteractive } from 'components/bdd';
import { Border, Container, Row } from 'components/bdd/Layout';
import Icon from 'components/bdd/Icon';
import { Shield } from 'react-bootstrap-icons';
import { proProjectionToText } from 'components/Scouting/playerformhelpers';

const ScoutingRatingIcon = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing[1],
  padding: theme.spacing[1],
});

const SplitControlContainer = styled.div({
  ...theme.typography.body4,
  marginBottom: theme.spacing[1],
});

export const ratingToIcon = {
  skating: faSkating,
  hockeySense: faBrain,
  scoring: faBullseye,
  puckHandling: faHockeyPuck,
  positioning: faArrowsAltH,
  reboundControl: faVectorSquare,
  creativity: faMagic,
  defending: faShieldAlt,
  passing: faChevronCircleRight,
  intensity: faFire,
  interior: faHouseUser,
};

export const ratingToIconText = {
  intensity: 'IN',
  creativity: 'CR',
  passing: 'PS',
  postTracking: 'PT',
  trackingPucks: 'TP',
  handsGloveBlocker: 'GB',
  defending: 'DE',
  interior: 'IN',
  offensiveInvolvement: 'OI',
};

export const ratingToText = {
  skating: 'Skating',
  hockeySense: 'Hockey Sense',
  scoring: 'Scoring',
  puckHandling: 'Puck Possession',
  positioning: 'Positioning',
  reboundControl: 'Rebound Control',
  intensity: 'Intensity / Compete',
  creativity: 'Creativity',
  passing: 'Passing',
  postTracking: 'Post Tracking',
  trackingPucks: 'Tracking Pucks',
  handsGloveBlocker: 'Hands Glove Blocker',
  defending: 'Defending',
  interior: 'Interior',
  offensiveInvolvement: 'Offensive Involvement',
};

export const posToRatings = (position) => {
  position = position?.toLocaleLowerCase();

  switch (position) {
    case 'f':
      return [
        'skating',
        'hockeySense',
        'scoring',
        'interior',
        'intensity',
        'puckHandling',
        'creativity',
        'defending',
      ];
    case 'd':
      return [
        'skating',
        'hockeySense',
        'scoring',
        'offensiveInvolvement',
        'intensity',
        'puckHandling',
        'passing',
        'defending',
      ];
    case 'g':
      return [
        'positioning',
        'reboundControl',
        'postTracking',
        'trackingPucks',
        'handsGloveBlocker',
      ];
    default:
      return ['skating', 'hockeySense', 'scoring', 'intensity', 'puckHandling'];
  }
};

const renderRatingIcon = (icon, text, rank, predictedRank) => {
  if (!rank) return null;

  const color = getRedGreenColor(1 - rank.percentile, 0, 1, false, 2);
  const predictedColor =
    predictedRank && getRedGreenColor(1 - predictedRank.percentile, 0, 1, false, 2);

  return (
    <TooltipSpan
      content={
        <div>
          <b>{rank.rating.primaryName}</b>
          <div>
            {numberToOrdinal(roundToX(100 * (1 - rank.percentile), 1))} percentile
          </div>
          <div>
            {numberToOrdinal(rank.rank)} out of {rank.total}
          </div>
          <i>Based on {rank.count} reports</i>
        </div>
      }
      key={`rating-${rank.rating.primaryName}`}
    >
      <Container width={'37px'}>
        <Border>
          <Row>
            <Container padding={0.5} paddingRight={1} style={{ backgroundColor: color }}>
              {!!icon ? <FontAwesomeIcon icon={icon} /> : text}{' '}
            </Container>
            <Container padding={0.5} flexGrow style={{ backgroundColor: color }}>
              {!!rank ? roundToX(100 * (1 - rank.percentile), 0, true) : <em>NA</em>}
            </Container>
            {predictedRank && (
              <>
                <Container padding={0.5} style={{ backgroundColor: predictedColor }}>
                  {roundToX(100 * (1 - predictedRank.percentile), 0, true)}
                </Container>
              </>
            )}
          </Row>
        </Border>
      </Container>
    </TooltipSpan>
  );
};

export const ScoutingRankSplitDescription = ({
  split,
  totalPlayers,
  brief = false,
  descriptionHoverContent,
}) => {
  const description = brief ? (
    <Typography variant="stat">
      From {totalPlayers}
      <b>
        {!!split.league ? (
          <TooltipSpan content={split.league}>
            {split.league.split(' ').map((word) => word[0])}
          </TooltipSpan>
        ) : !!split.leagueGroup ? (
          split.leagueGroup
        ) : null}{' '}
        {split.isDraftEligible ? 'draft elig.' : ''}{' '}
        {!!split.position ? `${split.position.toLocaleUpperCase()}s` : 'plyrs'}{' '}
        {split.timeRange === 'PAST_YEAR'
          ? 'past yr'
          : split.timeRange === 'PAST_2_YEARS'
          ? 'past 2 yrs'
          : ''}{' '}
        {!!split.scoutName && (
          <>
            <TooltipSpan content={split.scoutName}>
              {nameToInitials(split.scoutName)}'s
            </TooltipSpan>{' '}
            views
          </>
        )}{' '}
        {!!split.predictedGrades && `using predicted grades`}
      </b>
    </Typography>
  ) : (
    <Typography variant="stat">
      Ranked against {totalPlayers} other{' '}
      <b>
        {!!split.league ? (
          <TooltipSpan content={split.league}>
            {split.league.split(' ').map((word) => word[0])}
          </TooltipSpan>
        ) : !!split.leagueGroup ? (
          split.leagueGroup
        ) : null}{' '}
        {split.isDraftEligible ? 'draft elig.' : ''}{' '}
        {!!split.position ? `${split.position.toLocaleUpperCase()}s` : 'players'}{' '}
        {split.timeRange === 'PAST_YEAR'
          ? 'from past year'
          : split.timeRange === 'PAST_2_YEARS'
          ? 'from past 2 years'
          : split.timeRange?.startsWith('DRAFT_YEAR_')
          ? `from ${split.timeRange.replace('DRAFT_YEAR_', '')}`
          : ''}{' '}
        {!!split.scoutName && `based on ${split.scoutName}'s views`}{' '}
        {!!split.predictedGrades && `using predicted grades`}
      </b>
      {!!split.maxViewsPerScout && `(limit ${split.maxViewsPerScout} views per scout)`}
    </Typography>
  );

  return descriptionHoverContent ? (
    <HoverInteractive placement="top" content={descriptionHoverContent}>
      {description}
    </HoverInteractive>
  ) : (
    description
  );
};

const ScoutingRankingSplitShorthand = ({ split }) => {
  let text;
  if (!!split.scoutName) {
    text = 'MINE';
  } else if (!!split.predictedGrades) {
    text = 'SL';
  } else {
    text = 'SCOUTS';
  }
  return <Typography variant="stat">{text}</Typography>;
};

export const ScoutingGradeBoxes = ({
  rankings,
  attributes,
  split,
  position, // fdg lower
  shortSplitDesc = false,
  noSplitDescription,
  predictedRankings,
  rinknetId, // used to pull up by scout modal
  playerName, // also for the modal
  hideScoutReportModal,
}) => {
  const [showByScout, setShowByScout] = useState(false);
  const ratingToRank = useMemo(() => {
    if (!!rankings) {
      return rankings.reduce((acc, curr) => ({ ...acc, [curr.rating.slug]: curr }), {});
    }
  }, [rankings]);
  const attributeLookup = useMemo(() => {
    if (!!attributes) {
      return attributes.reduce((acc, curr) => ({ ...acc, [curr.attribute]: curr }), {});
    }
  }, [attributes]);

  const predictedRatingToRank = useMemo(() => {
    if (!!predictedRankings) {
      return predictedRankings.reduce(
        (acc, curr) => ({ ...acc, [curr.rating.slug]: curr }),
        {}
      );
    }
  }, [predictedRankings]);

  // if (Object.keys(ratingToRank).length == 0)
  //   return (
  //     <Typography variant={'body1'}>
  //       <em>No scout ranking data found</em>
  //     </Typography>
  //   );

  const ratingsToPlot = posToRatings(position);
  const totalPlayers = rankings?.[0]?.total;
  const reportCount = attributeLookup?.['views']?.values.total_views;

  const attributesToPlot = [
    {
      id: 'views',
      title: 'Total Views',
      icon: faEye,
      content: 'Click for breakdown by scout',
      onClick: () => setShowByScout(true),
      value: reportCount,
    },
    {
      id: 'proProjection',
      title: 'Pro Projection',
      icon: faChartLine,
      value: !!attributeLookup?.pro_projection?.summary.mode
        ? proProjectionToText(attributeLookup?.pro_projection?.summary.mode, position)
        : null,
    },
    ...(!!attributeLookup?.draft_round
      ? [
          {
            id: 'draftRound',
            title: 'Draft Round',
            icon: faListOl,
            value: attributeLookup?.draft_round?.summary.mode,
          },
        ]
      : []),
    {
      id: 'unqiueScouts',
      title: 'Unique Scouts',
      icon: faUsers,
      value: attributeLookup?.views?.values.unique_scouts,
    },
    {
      id: 'uniqueMonths',
      title: 'Unique Months',
      icon: faClock,
      value: attributeLookup?.views?.values.unique_months,
    },
  ];

  return (
    <Container>
      <Row gap={8}>
        <Container minWidth={35}>
          <HoverInteractive
            content={
              <ScoutingRankSplitDescription split={split} totalPlayers={totalPlayers} />
            }
          >
            <ScoutingRankingSplitShorthand split={split} totalPlayers={totalPlayers} />
          </HoverInteractive>
        </Container>

        <Container>
          {Object.keys(ratingToRank).length == 0 && (
            <Typography variant={'body1'}>
              <em>No data found</em>
            </Typography>
          )}
          <Typography variant="stat">
            <Row gap={1}>
              {ratingsToPlot.map((rating) =>
                renderRatingIcon(
                  rating in ratingToIcon && ratingToIcon[rating],
                  rating in ratingToIconText && <b>{ratingToIconText[rating]}</b>,
                  ratingToRank[rating],
                  predictedRatingToRank?.[rating]
                )
              )}
              {!split.predictedGrades
                ? attributesToPlot.map((att) => (
                    <TooltipSpan key={att.id} content={att.title}>
                      <ScoutingRatingIcon onClick={att.onClick}>
                        <FontAwesomeIcon icon={att.icon} />
                        <Typography noWrap>{att.value || 0}</Typography>
                      </ScoutingRatingIcon>
                    </TooltipSpan>
                  ))
                : null}
            </Row>
          </Typography>
        </Container>
      </Row>
      {!!rinknetId && showByScout && !hideScoutReportModal && (
        <ReportsByScoutModal
          show={showByScout}
          handleClose={() => setShowByScout(false)}
          rinknetId={rinknetId}
          playerName={playerName}
        />
      )}
    </Container>
  );
};

export const ScoutingGradesV2 = ({
  rinknetPlayer,
  split,
  rankings,
  attributes,
  position = null,
}) => {
  if (!position && rankings.length > 0) {
    position = rinknetPlayer.posFDG.toLocaleLowerCase();
  }
  const playerName = `${rinknetPlayer.firstname} ${rinknetPlayer.lastname}`;
  return (
    <>
      <ScoutingGradeBoxes
        rankings={rankings}
        attributes={attributes}
        split={split}
        position={position}
        rinknetId={rinknetPlayer.id}
        playerName={playerName}
      />
    </>
  );
};

const ChooseSplitModal = ({
  show,
  handleClose,
  playerName,
  splits,
  handleSetSplitId,
  currentSplitId,
}) => {
  return (
    <Modal size="lg" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <SectionHeader>Valid Splits for {playerName}</SectionHeader>
      </Modal.Header>
      <Modal.Body>
        <ChooseSplitTable
          showScout
          splits={splits}
          currentSplitId={currentSplitId}
          handleSetSplitId={handleSetSplitId}
        />
      </Modal.Body>
    </Modal>
  );
};
