import Icon from 'components/bdd/Icon';
import { Border, Column, Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import { useContext, useEffect, useState } from 'react';
import { ChevronDown, ChevronUp } from 'react-bootstrap-icons';
import { StyledBDDSelect } from 'components/bdd/Select/StyledBDDSelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Cell, Header } from 'components/bdd/Table';
import PlayerLinkHover from 'components/PlayersV2/Hover/PlayerLinkHover';
import {
  posToRatings,
  ratingToIcon,
  ratingToIconText,
} from 'components/PlayersV2/Scouting/ScoutingGradesV2';
import { TooltipSpan } from 'components/reports';
import { theme } from 'constants/theme';
import { roundToX } from 'helpers/data';
import { heightInchesToStr } from 'helpers/hockeyutils';
import { getColorWithOpacity } from 'helpers/plotting';
import { getRedGreenColor, sortNumeric } from 'helpers/tables';
import { VerticalDivider } from 'components/bdd/Divider';
import { numberToOrdinal } from 'helpers/helpers';
import { DraftPlayerCheckSimple, posToChecks } from 'components/Lists/Builder/components/DraftPlayerChecks';

const defaultColumnIds = [
  'player',
  'season',
  'bio',
  'league_strength',
  'position',
  'scouting',
  'checks',
  'total_season_stats_G_per_game',
  'total_season_stats_A_per_game',
  'total_season_stats_PIM_per_game',
  'draft_round_weight',
  'pro_projection_weight',
  'current_role_weight',
  'draft',
  'made-it',
  'similarity',
];

const baseColumns = [
  {
    id: 'player',
    Header: <Header>Player</Header>,
    accessor: (s) => s.last_name,
    width: 125,
    Cell: ({
      cell: {
        row: { original: s },
      },
    }) => (
      <Container>
        <PlayerLinkHover
          name={`${s.first_name} ${s.last_name}`}
          slug={s.bdd_player_slug}
          rinknetId={s.rinknet_id}
        />
      </Container>
    ),
  },
  {
    id: 'season',
    Header: <Header>Season</Header>,
    width: 70,
    accessor: (s) => s.season,
    Cell: ({
      cell: {
        row: { original: s },
      },
    }) => <Cell>{s.season}</Cell>,
  },
  {
    id: 'bio',
    Header: <Header>Bio</Header>,
    accessor: (s) => s.height,
    width: 110,
    Cell: ({
      cell: {
        row: { original: s },
      },
    }) => (
      <Container>
        <Row columnGap={2}>
          <Container width={40}>
            <Cell>{heightInchesToStr(s.height, 2)}</Cell>
          </Container>
          <Container width={40}>
            <Cell>{roundToX(s.draft_age, 1)} yrs</Cell>
          </Container>
        </Row>
      </Container>
    ),
  },
  {
    id: 'league_strength',
    Header: <Header>League / View Strength</Header>,
    width: 125,
    accessor: (s) => s.league_strength,
    Cell: ({
      cell: {
        row: { original: s },
      },
    }) => <Cell>{s.leagueAbbreviation} ({roundToX(s.league_strength, 2)} / {roundToX(s.views_league_strength, 2)})</Cell>,
  },

  {
    id: 'position',
    Header: <Header>Positions</Header>,
    accessor: (s) => (s.pos === 'f' ? s.report_c_pct : s.report_rd_pct),
    width: 120,
    Cell: ({
      cell: {
        row: { original: s },
      },
    }) => (
      <Cell>
        {s.pos === 'f'
          ? 'C: ' +
            roundToX(s.report_c_pct, 1) +
            ' LW: ' +
            roundToX(s.report_lw_pct, 1) +
            ' RW: ' +
            roundToX(s.report_rw_pct, 1)
          : 'LD: ' +
            roundToX(s.report_ld_pct, 1) +
            ' RD: ' +
            roundToX(s.report_rd_pct, 1)}
      </Cell>
    ),
  },
  {
    id: 'scouting',
    Header: <Header>Scouting</Header>,
    width: 380,
    accessor: (s) =>
      posToRatings(s.pos).reduce((sum, ranking) => sum + s[ranking], 0),
    Cell: ({
      cell: {
        row: { original: s },
      },
    }) => (
      <Container>
        <Row columnGap={1}>
          {posToRatings(s.pos).map((ranking) => {
            return (
              <Border key={`${s.rinknet_id}-${ranking}`}>
                <Container
                  width={40}
                  padding={1}
                  style={{
                    backgroundColor: getRedGreenColor(
                      !!s[ranking + '_pctile'] ? 1 - s[ranking + '_pctile'] : null,
                      0,
                      1,
                      false,
                      1.5
                    ),
                  }}
                >
                  <Typography variant="stat">
                    <Row columnGap={1}>
                      {ratingToIcon[ranking] ? (
                        <FontAwesomeIcon icon={ratingToIcon[ranking]} />
                      ) : (
                        <b>{ratingToIconText[ranking]}</b>
                      )}
                      {!!s[ranking + '_pctile'] ? roundToX((1 - s[ranking + '_pctile']) * 100) : 'NaN'}
                    </Row>
                  </Typography>
                </Container>
              </Border>
            );
          })}
        </Row>
      </Container>
    ),
  },
  {
    id: 'checks',
    Header: <Header>Checks</Header>,
    width: 130,
    accessor: (s) => s.pass_count,
    Cell: ({
      cell: {
        row: { original: s },
      },
    }) => (
      <Container>
        <Row gap={8}>
          {posToChecks(s.pos)?.map((c) => (
            <DraftPlayerCheckSimple id={c} checkValue={s[c]} />
          ))}
        </Row>
      </Container>
    ),
  },
  {
    id: 'total_season_stats_G_per_game',
    Header: <Header>G/GP</Header>,
    width: 60,
    accessor: (s) => s.total_season_stats_G_per_game,
    Cell: ({
      cell: {
        row: { original: s },
      },
    }) => <Cell>{roundToX(s.total_season_stats_G_per_game, 2)}</Cell>,
  },
  {
    id: 'total_season_stats_A_per_game',
    Header: <Header>A/GP</Header>,
    width: 60,
    accessor: (s) => s.total_season_stats_A_per_game,
    Cell: ({
      cell: {
        row: { original: s },
      },
    }) => <Cell>{roundToX(s.total_season_stats_A_per_game, 2)}</Cell>,
  },
  {
    id: 'total_season_stats_PIM_per_game',
    Header: <Header>PIM/GP</Header>,
    width: 60,
    accessor: (s) => s.total_season_stats_PIM_per_game,
    Cell: ({
      cell: {
        row: { original: s },
      },
    }) => <Cell>{roundToX(s.total_season_stats_PIM_per_game, 2)}</Cell>,
  },
  {
    id: 'draft_round_weight',
    Header: <Header>Round Weight</Header>,
    width: 60,
    accessor: (s) => s.draft_round_weight,
    Cell: ({
      cell: {
        row: { original: s },
      },
    }) => <Cell>{roundToX(s.draft_round_weight, 2)}</Cell>,
  },
  {
    id: 'pro_projection_weight',
    Header: <Header>Projection Weight</Header>,
    width: 60,
    accessor: (s) => s.pro_projection_weight,
    Cell: ({
      cell: {
        row: { original: s },
      },
    }) => <Cell>{roundToX(s.pro_projection_weight, 2)}</Cell>,
  },
  {
    id: 'current_role_weight',
    Header: <Header>Role Weight</Header>,
    width: 60,
    accessor: (s) => s.current_role_weight,
    Cell: ({
      cell: {
        row: { original: s },
      },
    }) => <Cell>{roundToX(s.current_role_weight, 2)}</Cell>,
  },
];



const similarPlayerColumns = [
  {
    id: 'draft',
    width: 110,
    Header: <Header>Draft</Header>,
    accessor: (s) => s.draft_pick,
    Cell: ({
      cell: {
        row: { original: s },
      },
    }) => (
      <Container>
        <Row columnGap={1}>
          {s.draft_pick ? (
            <>
              <Cell>{s.draft_pick && `${numberToOrdinal(s.draft_pick)}`}</Cell>/
              <Cell>{s.draft_year}</Cell>/<Cell>{s.draft_age >= 20 ? 'DY+2' : s.draft_age >= 19 ? 'DY+1' : 'DY'}</Cell>
            </>
          ) : (
            <Cell>
              <em>No draft info</em>
            </Cell>
          )}
        </Row>
      </Container>
    ),
  },
  {
    id: 'draftYear',
    width: 100,
    Header: <Header>Draft Year</Header>,
    accessor: (s) => s.draft_year,
    Cell: ({
      cell: {
        row: { original: s },
      },
    }) => <Cell>{s.draft_year}</Cell>,
  },
  {
    id: 'draftPick',
    width: 100,
    Header: <Header>Draft Pick</Header>,
    accessor: (s) => s.draft_pick,
    Cell: ({
      cell: {
        row: { original: s },
      },
    }) => <Cell>{s.draft_pick}</Cell>,
  },

  {
    id: 'made-it',
    width: 80,
    Header: <Header>Made NHL</Header>,
    accessor: (s) => s.made_it,
    Cell: ({
      cell: {
        row: { original: s },
      },
    }) => (
      <Border>
        <Container
          // padding={1}
          backgroundColor={
            s.made_it === ''
              ? theme.colors.light.background
              : !!s.made_it
              ? getColorWithOpacity(theme.colors.states.success, 0.5)
              : getColorWithOpacity(theme.colors.states.danger, 0.5)
          }
        >
          <Cell variant="stat">
            {s.made_it === '' ? 'Maybe' : !!s.made_it ? 'Yes' : 'No'}
          </Cell>
        </Container>
      </Border>
    ),
  },
  {
    id: 'similarity',
    Header: <Header>Similarity</Header>,
    width: 80,
    accessor: (s) => s.distance,
    Cell: ({
      cell: {
        row: { original: s },
      },
    }) => {
      return (
        <Border>
          <Container>
            <Cell variant="stat">{roundToX((1 - s.distance) * 100, 2)}</Cell>
          </Container>
        </Border>
      );
    },
    sortType: (a, b, colId) => sortNumeric(b, a, 'distance'),
  },
  {
    id: 'views',
    width: 100,
    Header: <Header>Views</Header>,
    accessor: (s) => s.views_total_views,
    Cell: ({
      cell: {
        row: { original: s },
      },
    }) => <Cell>{s.views_total_views}</Cell>,
  },
];

export const useSimilarPlayerColumns = () => {
  const [showColumnOptions, setShowColumnOptions] = useState();
  const [columnIds, setColumnIds] = useState(defaultColumnIds);

  const allColumns = [...baseColumns, ...similarPlayerColumns];
  const columns = [...baseColumns, ...similarPlayerColumns].filter((c) =>
    columnIds.includes(c.id)
  );
  const targetColumns = baseColumns.filter((c) => columnIds.includes(c.id));

  const playerColumnSelect = (
    <StyledBDDSelect
      selectProps={{
        placeholder: 'Player info',
        isMulti: true,
        isSearchable: true,
      }}
      selectedValue={columnIds}
      options={allColumns.map((c) => ({
        value: c.id,
        label: c.Header,
      }))}
      onChange={(n, v) => {
        setColumnIds(v);
      }}
    />
  );

  return {
    columns,
    targetColumns,
    columnSelect: (
      <Container>
        <Column gap={2}>
          <Container
            variant="button"
            onClick={() => setShowColumnOptions(!showColumnOptions)}
          >
            <Typography variant="body1">
              <Row columnGap={1}>
                {!showColumnOptions ? 'Show/Hide columns' : 'Hide column options'}
                <Icon icon={!showColumnOptions ? <ChevronDown /> : <ChevronUp />} />
              </Row>
            </Typography>
          </Container>
        </Column>
        {!!showColumnOptions && (
          <Container>
            <Row>
              <Container width={'100%'}>{playerColumnSelect}</Container>
            </Row>
          </Container>
        )}
      </Container>
    ),
  };
};
