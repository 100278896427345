import { useFilterContext } from './context';
import { useRegisterFilterTag } from './Group';
import { FilterTag } from '.';
import React from 'react';
import { Button } from '../Button';
import { Text } from '../Text';
import { Flex } from '../Layout';
import { Divider } from '../Divider';
import { DropdownMenu } from '../Dropdown';

export const DropdownFilter = React.memo(
  ({
    id,
    label,
    icon,
    cond = '=',
    variant = 'body2',
    options,
    onChange,
    meta,
    hide,
    readOnly,
    renderLabel,
  }) => {
    const filter = useFilterContext((state) => state.filters[id]);
    const { setFilters, removeFilter } = useFilterContext((state) => state.actions);

    useRegisterFilterTag(
      id,
      filter && (
        <FilterTag
          name={label}
          label={options.find((o) => o.value === filter.value)?.label}
        />
      ),
      filter
    );

    const dropdownLabel = (
      <Text variant="label" asChild>
        <Flex gap={1} className="space-x-5">
          {icon}
          {filter?.value}
        </Flex>
      </Text>
    );

    const getOptionItem = (o, index) => {
      return (
        <DropdownMenu.Item
          key={o.value || index}
          onClick={() => {
            setFilters(id, {
              id,
              type: 'select',
              cond,
              value: o.value,
              meta,
            });

            onChange && onChange(o.value, { setFilters, removeFilter });
          }}
        >
          <Text variant="caption">{o.label}</Text>
        </DropdownMenu.Item>
      );
    };

    const selectedOption = options
      .concat(options.flatMap((o) => o.options))
      .find((o) => o.value === filter?.value);

    const dropdown = (
      <DropdownMenu>
        <DropdownMenu.Trigger asChild>
          <Button
            variant={!!filter?.value ? 'outline' : 'secondary'}
            size="sm"
            style={{
              backgroundColor: selectedOption?.color,
            }}
          >
            {renderLabel ? renderLabel(filter?.value) : dropdownLabel}
          </Button>
        </DropdownMenu.Trigger>
        <DropdownMenu.Content>
          {options?.map((o, j) => {
            if (o.groupTitle) {
              return (
                <Flex key={o.groupTitle} direction="column" fill gap={1}>
                  <DropdownMenu.Label>{o.groupTitle}</DropdownMenu.Label>
                  {o.options.map((o, j) => getOptionItem(o, j))}
                </Flex>
              );
            }

            return getOptionItem(o, j);
          })}
        </DropdownMenu.Content>
      </DropdownMenu>
    );

    return (
      !hide && (
        <Flex gap={1} align="center">
          {readOnly ? (
            <Button asChild variant="outline" size="sm">
              {dropdownLabel}
            </Button>
          ) : (
            dropdown
          )}
        </Flex>
      )
    );
  }
);
