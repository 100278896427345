import { compareObjects } from 'helpers/object';
import { useState, useEffect, useRef } from 'react'
import styled from 'styled-components';
import { Container, Row } from '../Layout';
import Icon from '../Icon';
import { CaretLeft, CaretRight } from 'react-bootstrap-icons';
import { theme } from 'constants';


const ScrollableRow = styled(Row)({
  scrollBehavior: 'smooth',
  maxWidth: "100%", 
  paddingBottom: '5px',
  overflowX: 'auto' 
})


const ShadowButton = styled.div(({ isLeft, show }) => ({
  zIndex: 10,
  transition: 'boxShadow ease 1s',
  // transition: ' ease 0.5s',
  visibility: show ? 'visible' : 'hidden',
  position: 'absolute',
  display: 'flex',
  flexDirection: 'vertical',
  justifyContent: 'center',
  top: 0,
  right: !isLeft && 0 ,
  left: isLeft && 0 ,
  height: '100%',
  cursor: 'pointer',
  background: theme.colors.light.background,
  paddingLeft: '5px',
  paddingRight: '5px',
  border: '1px solid #aaa',
  boxShadow: isLeft ? `10px 0px 15px #aaa` : `-10px 0px 15px #aaa`,
  '&:hover': {
    boxShadow: isLeft ? `10px 0px 15px #777` : `-10px 0px 15px #777`,

  }
}))


export default function useInnerScroll({
  // ref,
  tolerance = 5, // number of pixels to say scroll is at the edge
  rowProps = {},
  externalTrigger, // a variable that will be monitored for changes -- to trigger a check of scroll state
  doubleCheck = true, // if true, will check scroll state twice on mount -- for when initial render has data (so no width until 1 pass)
}) {
  const ref = useRef();

  const [state, setState] = useState({
    updateCount: 0,
    hasLeftScroll: false,
    hasRightScroll: false,
  });
  const hasLeftScrollRef = useRef(state.hasLeftScroll);
  const hasRightScrollRef = useRef(state.hasRightScroll);

  const updateState = () => {
    if (!ref?.current) return;
    const fullWidth = ref?.current?.scrollWidth;
    const clientWidth = ref?.current?.clientWidth;
    const scrollRight = ref?.current?.scrollLeft + clientWidth;
    const prevState = {
      hasLeftScroll: hasLeftScrollRef.current,
      hasRightScroll: hasRightScrollRef.current,
    };
    hasLeftScrollRef.current = ref?.current?.scrollLeft >= tolerance;
    hasRightScrollRef.current = scrollRight <= fullWidth - tolerance;
    const newState = {
      updateCount: state.updateCount + 1,
      hasLeftScroll: hasLeftScrollRef.current,
      hasRightScroll: hasRightScrollRef.current,
    };
    if (!compareObjects(prevState, newState)) {
      setState(newState);
    }
  };

  useEffect(() => {
    if (doubleCheck && state.updateCount === 1) {
      updateState();
    }
  }, [state]);

  useEffect(() => {
    window.addEventListener('resize', updateState);
    return () => window.removeEventListener('resize', updateState);
  }, []);

  useEffect(() => {
    if (!!ref.current) {
      ref.current.addEventListener('scroll', updateState);
      updateState();
    }
    return () => window.removeEventListener('scroll', updateState);
  }, [ref]);

  useEffect(() => {
    updateState();
  }, [externalTrigger]);

  const handleScroll = (direction) => {
    if (!ref.current) return;
    const scrollStep = 500;
    if (direction === 'left') {
      ref.current.scrollTo(ref.current.scrollLeft - scrollStep, 0);
    } else {
      ref.current.scrollTo(ref.current.scrollLeft + scrollStep, 0);
    }
  };

  const hasLeftScroll = hasLeftScrollRef.current;
  const hasRightScroll = hasRightScrollRef.current;
  const leftShadowButton = (
    <ShadowButton
      isLeft={true}
      show={hasLeftScroll}
      onClick={() => handleScroll('left')}
    >
      <Icon icon={<CaretLeft />} />
    </ShadowButton>
  );

  const rightShadowButton = (
    <ShadowButton show={hasRightScroll} onClick={() => handleScroll('right')}>
      <Icon icon={<CaretRight />} />
    </ShadowButton>
  );

  const renderScrollableRow = (children) => {
    return (
      <Container>
        <ScrollableRow ref={ref} alignItems="stretch" {...rowProps}>
          {children}
        </ScrollableRow>
        {leftShadowButton}
        {rightShadowButton}
      </Container>
    );
  };

  return {
    renderScrollableRow, // call this
    handleScroll,
    hasLeftScroll,
    hasRightScroll,
    leftShadowButton,
    rightShadowButton,
  };
}