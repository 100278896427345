import { IconButton } from 'components/bdd/Button';
import { cn } from '@udecode/cn';

export const ToolbarIconButton = ({ pressed, active, icon, children, ...props }) => {
  const className = cn([
    'h-full p-1',
    !pressed && !active
      ? 'text-secondary hover:bg-bos-100 hover:rounded-sm'
      : '!bg-bos-100 text-foreground rounded',
  ]);

  return (
    <IconButton
      hoverColor={'black'}
      className={className}
      icon={icon || children}
      {...props}
    />
  );
};
