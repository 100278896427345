import TeamDraftCaptal from "components/TeamsV2/DraftPicks/TeamDraftCapital"

export const ScenarioPicks = ({ scenario }) => {
  const teamSlug = scenario?.teamSlug;
  if (!teamSlug) return null;
  return (
    <TeamDraftCaptal 
      teamSlug={teamSlug}
      picksIn={scenario.picksIn.map(p => ({
        ...p.bfDraftPick,
        source: 'PRO_OBJECTIVE'
      }))}
      picksOut={scenario.picksOut.map(p => ({
        ...p.bfDraftPick,
        currentTeam: { slug: 'not us', abbreviation: p.toTeamSlug.split(".")[1].toUpperCase() },
        source: 'PRO_OBJECTIVE'
      }))}
    />
  )
}