import PlayerLinkHover from "components/PlayersV2/Hover/PlayerLinkHover"
import PlayerHover from "components/PlayersV2/Hover/playerhover"
import { Card } from "components/bdd/Card"
import { Column, Row } from "components/bdd/Layout"
import { Typography } from "components/bdd/Typography"
import BDDEditInline from "components/bdd/bddeditinline"
import { ScenarioPlayer } from "./ScenarioPlayer"
import { BDDTradeDraftPick } from "components/ProScouting/BDDTrades/BDDTradeAsset"
import { ScenarioPick } from "./ScenarioPick"
import { ScenarioSynopsis } from "./ScenarioSynopsis"


export const ScenarioHeader = ({ scenario, updateScenario }) => {
  return (
    <Row gap={20} justifyContent='space-between' flexWrap>
      <Column justifyContent='start'>
        <Typography variant='h4'>
          <BDDEditInline
            name='title'
            defaultValue={scenario.title}
            onChange={(name, val) => updateScenario({ variables: { input: { id: scenario.id, proObjectiveSlug: scenario.proObjectiveSlug, title: val }}})}
          />
        </Typography>
        <Typography variant='body1'>
          <BDDEditInline
            name='description'
            defaultValue={scenario.description || 'No description'}
            onChange={(name, val) => updateScenario({ variables: { input: { id: scenario.id, proObjectiveSlug: scenario.proObjectiveSlug, description: val }}})}
          />
        </Typography>
      </Column>

      <ScenarioSynopsis scenario={scenario} />

      {/* <Column gap={0}>
        <Typography variant='body1' color='#777'>Targets In</Typography>
        {scenario.playersIn?.map(t => (
          <PlayerLinkHover 
            key={t.slug} 
            slug={t.slug}
            name={`${t.bddPlayer.firstname} ${t.bddPlayer.lastname}`}
            variant='caption'
          />
        ))}
      </Column>

      <Column gap={0} justifyContent='start'>
        <Typography variant='body1' color='#777'>Assets Out</Typography>
        {scenario.playersOut?.map(t => (
          <PlayerLinkHover 
            key={t.slug} 
            slug={t.slug}
            name={`${t.bddPlayer.firstname} ${t.bddPlayer.lastname}`}
            variant='caption'
          />
        ))}
      </Column> */}
    </Row>
  )
}