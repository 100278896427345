import { Border, Column, Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import BFDraftPick from 'components/ProScouting/Draft/BFDraftPick';
import { BFTradedPlayer } from './BFTradeBreakdown';
import { getNHLTeamLogoUrl } from 'helpers/logos';
import { bruinsGold } from 'helpers/plotting';
import { LinkButton } from 'components/bdd/Button';
import { useState } from 'react';
import { UpdateBFTrade } from 'components/ProScouting/BFTrades/UpdateBFTrade';
import { useUser } from 'helpers/user';
import { permissions } from 'constants';
import usePlaceholder from 'components/Placeholder/usePlaceholder';
import { useQuery } from '@apollo/client';
import { GET_BF_TRADE } from 'apollo/queries/bruinsfriendly.queries';


export const BFTrade = ({ trade, renderTradePick, renderTradePlayer }) => {
  return (
    <Container>
      <Row gap={4} alignItems="start">
        {trade.teamSummaries.map((s) => (
          <Container key={s.teamSlug} style={{ flex: 1 }}>
            <Column justifyContent="center" alignItems="top">
              <Row gap={1} justifyContent="center">
                <img alt="" height="20px" src={getNHLTeamLogoUrl(s.bfTeam.nhlid)} />
                <Typography variant="body1">{s.bfTeam.abbreviation} Receives</Typography>
              </Row>
              <Column>
                {s.isFutureConsiderations && (
                  <Typography
                    variant="body2"
                    textAlign="center"
                    style={{ fontStyle: 'italic' }}
                  >
                    Future Considerations
                  </Typography>
                )}
                {trade.tradedDraftPicks
                  .filter((p) => p.toTeamSlug === s.teamSlug)
                  .map((p) =>
                    renderTradePick ? (
                      renderTradePick(p, trade)
                    ) : (
                      <BFDraftPick
                        key={p.bfDraftPick.id}
                        draftPick={p.bfDraftPick}
                        justifyContent="center"
                        variant={p.highlighted ? 'body1' : 'body2'}
                      />
                    )
                  )}
                {trade.tradedPlayers
                  .filter((p) => p.toTeamSlug === s.teamSlug)
                  .map((p) =>
                    renderTradePlayer ? (
                      renderTradePlayer(p, s)
                    ) : (
                      <BFTradedPlayer
                        key={p.playerSlug}
                        bfTradedPlayer={p}
                        tradeDate={trade.date}
                        justifyContent="center"
                        isTradedAgain={trade.tradedPlayers.find(
                          (tp) =>
                            tp.playerSlug === p.playerSlug &&
                            tp.fromTeamSlug === s.teamSlug
                        )}
                      />
                    )
                  )}
              </Column>
            </Column>
          </Container>
        ))}
      </Row>
    </Container>
  );
};

export const BFTradeBox = ({ trade, renderTradePick, renderTradePlayer, onClick, highlight, showEdit=false }) => {
  const { userHasPermission } = useUser();
  const [edit, setEdit] = useState(false);

  const handleEditToggle = () => {
    setEdit(!edit);
  }

  const highlightColor = highlight === true ? bruinsGold : highlight;
  return (
    <Border onClick={onClick} style={{ cursor: !!onClick ? 'pointer' : 'default', boxShadow: highlight ? `0 0 4px ${highlightColor}` : 'none' }}>
      <Container padding={2} minWidth={300}>
        {showEdit && userHasPermission(permissions.manageSalaryCap) && <Row justifyContent="end">
          <LinkButton onClick={ev => {
            ev.stopPropagation();
            handleEditToggle()
          }}>
            <Typography variant='stat'>
              {edit ? 'Cancel' : 'Edit'}
            </Typography>
          </LinkButton>
        </Row>}
        {edit
          ? <UpdateBFTrade bfTrade={trade} onSubmit={() => setEdit(false)}/>
          : (
            <BFTrade
              trade={trade}
              renderTradePick={renderTradePick}
              renderTradePlayer={renderTradePlayer}
            />
          )}
      </Container>
    </Border>
  );
};
