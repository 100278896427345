import { useQueryWithPlaceholder } from 'apollo/hooks';
import {
  GET_REPORT_FILTER_OPTIONS,
  SEARCH_FOR_REPORT_PLAYERS,
} from 'apollo/queries/scouting.queries';
import RangeInputBoxes from 'components/bdd/Range/RangeInputBoxes';
import { StyledBDDSelect } from 'components/bdd/Select/StyledBDDSelect';
import Toggle from 'components/bdd/Toggle/Toggle';
import { Typography } from 'components/bdd/Typography';
import { BDDDateRangePicker } from 'components/bdd/bdddatepicker';
import BDDRangeSlider from 'components/bdd/bddrangeslider';
import BDDSelect from 'components/bdd/bddselect';
import PlayerSearch from 'components/filters/playersearch';
import React from 'react';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import styled from 'styled-components';

const Container = styled.div({
  borderBottom: '1px solid #aaa',
  paddingBottom: '10px',
  '.row': {
    marginBottom: '10px',
  },
});

export default function FilterBank({
  scoutingObjectiveSlug,
  filters,
  setFilters,
  handleFetch,
  loading,
  requiresRefetch,
  hideDateRangeFilter,
  hideFetchButton,
}) {
  const { data, placeholder } = useQueryWithPlaceholder(GET_REPORT_FILTER_OPTIONS, {
    scoutingObjectiveSlug,
  });

  if (!data) return placeholder;
  const leagueOptions = data.scoutingReportFilterOptions.leagues.map((l) => ({
    value: l.realId,
    label: l.name,
  }));
  const scoutOptions = data.scoutingReportFilterOptions.scouts.map((s) => ({
    value: s.id,
    label: s.username,
  }));

  const handleChange = (name, value) => {
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  return (
    <Container>
      <Row>
        <Col>
          <StyledBDDSelect
            selectProps={{
              placeholder: 'Leagues',
              isSearchable: true,
              isMulti: true,
              isClearable: true,
            }}
            name="leagues"
            options={leagueOptions}
            onChange={handleChange}
            selectedValue={filters.leagues}
          />
        </Col>
        <Col>
          <StyledBDDSelect
            selectProps={{
              placeholder: 'Scouts',
              isSearchable: true,
              isMulti: true,
              isClearable: true,
            }}
            name="scouts"
            options={scoutOptions}
            onChange={handleChange}
            selectedValue={filters.scouts}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <PlayerSearch
            isClearable
            gqlQuery={SEARCH_FOR_REPORT_PLAYERS}
            responseToResults={(data) =>
              data.searchForReportPlayers.map((p) => ({
                value: p.realId,
                label: p.firstname + ' ' + p.lastname,
                subLabel: (
                  <>
                    {p.currentteam}, {p.currentleagueabbr}
                  </>
                ),
              }))
            }
            variables={{
              filters: null,
              scoutingObjectiveSlug,
            }}
            handleSelect={(id) => {
              setFilters({
                ...filters,
                rinknetIds: [id],
              });
            }}
          />
        </Col>
        {!hideDateRangeFilter && (
          <Col>
            <BDDDateRangePicker
              zIndex={6}
              placeholderText="Date Range"
              selected={filters.startDate}
              startDate={filters.startDate}
              endDate={filters.endDate}
              onChange={(dates) => {
                const sdate =
                  dates[0] instanceof Object
                    ? dates[0].getTime()
                    : typeof dates[0] == 'number'
                    ? dates[0]
                    : null;
                const edate =
                  dates[1] instanceof Object
                    ? dates[1].getTime()
                    : typeof dates[1] == 'number'
                    ? dates[1]
                    : null;
                setFilters({ ...filters, startDate: sdate, endDate: edate });
              }}
              showYearDropdown
              isClearable
            />
          </Col>
        )}
      </Row>
      <Row>
        <Col>
          <RangeInputBoxes
            name="current_age"
            label={<Typography variant="body1">Current Age</Typography>}
            rangeMin={0}
            value={!!filters.current_age ? filters.current_age : { min: null, max: null }}
            onChange={(t) => handleChange(t.target.name, t.target.value)}
          />
        </Col>
        <Col>
          <RangeInputBoxes
            name="age_at_report"
            label={<Typography variant="body1">Age at Report</Typography>}
            rangeMin={0}
            value={
              !!filters.age_at_report ? filters.age_at_report : { min: null, max: null }
            }
            onChange={(t) => handleChange(t.target.name, t.target.value)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Toggle
            id="bdd-only"
            label="BDD Reports Only"
            field={{
              value: !!filters.bddOnly,
              onChange: (toggled) => setFilters({ ...filters, bddOnly: toggled }),
            }}
          />
        </Col>
      </Row>
      {!hideFetchButton && (
        <Row>
          <Col>
            <Button
              disabled={loading || !requiresRefetch}
              variant="warning"
              onClick={handleFetch}
            >
              <Typography variant="body1">
                {loading ? (
                  <>
                    Fetching... <Spinner />
                  </>
                ) : !requiresRefetch ? (
                  'Data fetched.'
                ) : (
                  'Fetch Data'
                )}
              </Typography>
            </Button>
          </Col>
        </Row>
      )}
    </Container>
  );
}
