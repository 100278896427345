import React, { useState } from 'react';
import { Redirect, Route, Switch, useRouteMatch, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import BDDErrorBoundary from '../bdd/bdderrorboundary';
import { BDDTabLink, BDDTabLinkContainer } from 'components/bdd';
import { TooltipContent, TooltipProvider, TooltipTrigger, Tooltip as TooltipV2 } from 'components/Tooltip';

export const PageBreak = styled.div`
  @media print {
    page-break-after: always;
    page-break-inside: avoid;
  }
`;
export const HiddenControls = styled.div`
  @media print {
    display: none;
  }
`;

export const Unprinted = styled.span`
  @media print {
    display: none;
  }
`;

export const AlwaysPrinted = styled.div`
  display: ${(props) => (props.hide ? 'none' : 'inline')};
  @media print {
    display: inline;
  }
`;

export function BDDPage(props) {
  return (
    <BDDErrorBoundary>
      <PageBreak>
        <AlwaysPrinted>{props.children}</AlwaysPrinted>
      </PageBreak>
    </BDDErrorBoundary>
  );
}

export function BDDHiddenControls(props) {
  const [show, setShow] = useState(true);
  const header = (
    <div>
      <span style={{ float: 'right' }}>
        <Button variant="dark" size="sm" onClick={() => setShow(!show)}>
          {show ? 'Hide Controls' : 'Show Controls'}
        </Button>
      </span>
    </div>
  );

  return (
    <HiddenControls>
      <div style={{ margin: '1%', ...props.style }}>
        {props.hideable ? header : null}
        {show ? props.children : <center>...</center>}
      </div>
    </HiddenControls>
  );
}

export const SectionHeader = styled.div`
  color: #333;
  text-align: center;
  font-size: 1.5em;
  font-weight: bold;
  font-family: Oswald;
`;
export const SubSectionHeader = styled.div`
  color: #333;
  text-align: center;
  font-size: 1.1em;
  font-weight: bold;
  font-family: Oswald;
  ${(props) => (props.inline ? 'display: inline-block;' : '')}
`;

export const ControlSectionHeader = styled.div`
  color: #ddd;
  text-align: center;
  font-size: 1em;
  font-weight: bold;
  margin: 0;
  padding: 0;
`;

export const StickyControlsStyles = styled.div`
  position: sticky;
  top: 10px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;

  opacity: 0.7;
  width: 50%;
  padding: 5px;
  background: #333;
  border-radius: 5px;
  color: white;
  z-index: 99;

  &:hover {
    opacity: 1;
  }
  @media print {
    display: none;
  }
`;

export function StickyAdminControls({ children, style }) {
  return <StickyControlsStyles style={style}>{children}</StickyControlsStyles>;
}

export function Editable(props) {
  return (
    <span contentEditable={true} suppressContentEditableWarning={true}>
      {props.children}
    </span>
  );
}

export const LinkControlledSection = withRouter((props) => {
  const show = props.location.pathname.indexOf(props.contains) >= 0;
  return <AlwaysPrinted hide={!show}>{props.children}</AlwaysPrinted>;
});

export const HideableSelect = ({ hideSelect, ...props }) => {
  const className = hideSelect
    ? 'appearance-none border-0'
    : '';

  return (
    <select
      className={`${className} disabled:text-black print:appearance-none print:border-0`}
      {...props}
    />
  );
};

export const TooltipSpan = ({
  content,
  placement,
  show,
  hide,
  trigger,
  children,
  ...props
}) => {
  if (content) {
    return (
      <TooltipProvider>
        <TooltipV2 delayDuration={show || 150}>
          <TooltipTrigger asChild>
            <span {...props} style={{ cursor: 'pointer', ...props.style }}>
              {children}
            </span>
          </TooltipTrigger>
          <TooltipContent>{content}</TooltipContent>
        </TooltipV2>
      </TooltipProvider>
    );
  }
  return <span {...props}>{children}</span>;
};

export const ThStat = (props) => {
  if (props.description) {
    return (
      <OverlayTrigger
        placement="top"
        delay={{ show: 150, hide: 150 }}
        overlay={<Tooltip>{props.description}</Tooltip>}
      >
        <th style={{ cursor: 'pointer', ...props.style }} onClick={props.onClick}>
          {props.children}
        </th>
      </OverlayTrigger>
    );
  }

  return <th>{props.children}</th>;
};

export const ReportSwitch = ({
  showTabLinks = false,
  routes = [],
  renderAll,
  showPageTitles,
  redirect,
  defaultProps = {},
}) => {
  /* A Router Switch component which can be toggled to render all children.
    This is useful for Reports that need to be printed*/
  const { url: baseRoute } = useRouteMatch();
  if (renderAll) {
    const filteredRoutes = routes.filter((r) => !r.unprinted);
    return filteredRoutes.map((r, i) => (
      <div key={r.path}>
        {showPageTitles && r.title && <SectionHeader>{r.title}</SectionHeader>}
        {React.createElement(r.component, { ...defaultProps })}
        {i !== filteredRoutes.length - 1 && (
          <>
            <Unprinted>
              <hr></hr>
            </Unprinted>
            <PageBreak />
          </>
        )}
      </div>
    ));
  }

  return (
    <>
      {showTabLinks && (
        <BDDTabLinkContainer leftAlign bottomBorder>
          {routes.map((r) => (
            <BDDTabLink key={r.path} to={r.path}>
              {r.label}
            </BDDTabLink>
          ))}
        </BDDTabLinkContainer>
      )}
      <Switch>
        {routes.map((r) => {
          const Component = r.component;
          return (
            <Route
              key={r.path}
              path={r.path}
              render={() => (
                <BDDErrorBoundary>
                  <Component {...defaultProps} {...r.props} />
                </BDDErrorBoundary>
              )}
            />
          );
        })}
        {!!redirect ? <Redirect to={`${baseRoute}/${redirect}`} /> : null}
      </Switch>
    </>
  );
};

// Skater and group table styles
export const ReportTableStyles = styled.div`
  text-align: center;
  table {
    width: 100%;
    text-align: center;

    td th {
      padding-left: 5px;
      padding-right: 5px;
    }

    th {
      border-right: 1px solid #ddd;
    }
    td {
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
    }
  }
`;
