import { faSlidersH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { SubSectionHeader, TooltipSpan } from '../reports';
import GameTypeSelect from './gametypeselect';
import SeasonSelect from './seasonselect';
import StrengthSelect from './strengthselect';
import { StyledBDDSelect } from 'components/bdd/Select/StyledBDDSelect';

export default function ModalFilterBank({
  filters,
  setFilters,
  fetchData,
  precalc,
  league,
}) {
  const [show, setShow] = useState(false);

  return (
    <>
      <TooltipSpan content="Filters">
        <Button
          size="sm"
          style={{ fontSize: '12px', border: 0 }}
          variant="outline-dark"
          onClick={() => setShow(true)}
        >
          <FontAwesomeIcon icon={faSlidersH} />
        </Button>
      </TooltipSpan>
      <Modal id="filter-modal" show={show} onHide={() => setShow(false)}>
        <Modal.Header>
          <SubSectionHeader>Filters</SubSectionHeader>
        </Modal.Header>
        <Modal.Body>
          <SeasonSelect
            useBDDSelect
            value={filters.season}
            onChange={(n, v) => setFilters({ ...filters, [n]: v })}
          />
          <hr></hr>
          <GameTypeSelect
            useBDDSelect
            value={filters.game_type}
            mode={league}
            onChange={(n, v) => {
              setFilters({ ...filters, [n]: v });
            }}
          />
          <hr></hr>
          <StrengthSelect
            useBDDSelect
            precalc={precalc}
            value={filters.strength}
            onChange={(n, v) => setFilters({ ...filters, [n]: v })}
          />
          <hr></hr>
          <StyledBDDSelect
            options={[
              {
                value: 'f',
                label: 'Forwards',
              },
              {
                value: 'd',
                label: 'Defensemen',
              },
              {
                value: null,
                label: 'All Skaters',
              },
            ]}
            selectedValue={filters.position}
            onChange={(_, v) => {
              if (!v) {
                const newFilters = { ...filters };
                delete newFilters.position;
                setFilters(newFilters);
              } else {
                setFilters({ ...filters, position: v });
              }
            }}
          />
          <hr></hr>
          <Button
            block
            size="sm"
            variant="warning"
            onClick={() => {
              fetchData();
              setShow(false);
            }}
          >
            Fetch Data
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
}
