import { theme } from 'constants/theme';
import styled from 'styled-components';

const stickyPosition = {
  position: 'sticky',
  left: 0,
  textAlign: 'left',
  whiteSpace: 'nowrap',
  paddingRight: '10px',
  zIndex: 1,
};

const TableStyles = styled.div``;

// TODO: Ideally we find a way to make the sticky and hover styled tables work together
const StickyStyles = styled.div(
  ({ rowHeight, stickyChild = 1, withBorders, withHighlight }) => {
    const trChild = `th:nth-child(${stickyChild})`;
    const tdChild = `td:nth-child(${stickyChild})`;

    const borderStyles = {
      ...theme.borders.thin,
      ...theme.borders.bottom,
      ...theme.borders.light,
    };

    const highlightStyles = withHighlight
      ? {
          backgroundColor: theme.colors.light.background,
          '&:hover': {
            // backgroundColor: theme.colors.light.secondary,
            ...borderStyles,
            borderColor: theme.colors.light.text.primary,
          },
        }
      : { backgroundColor: theme.colors.light.background };

    return {
      table: {
        thead: {
          [trChild]: {
            ...stickyPosition,
            top: 0,
            zIndex: 2,
          },
          th: {
            position: 'sticky',
            top: 0,
            zIndex: 1,
            padding: 0,
            ...highlightStyles,
          },
        },
        tr: {
          height: rowHeight,
          verticalAlign: 'baseline',
          ...(withBorders ? borderStyles : {}),
          ...highlightStyles,
        },
        // 'td:first-child': {
        //   ...stickyPosition,
        //   backgroundColor: theme.colors.light.background,
        // },
        [tdChild]: {
          ...stickyPosition,
          backgroundColor: 'inherit',
          ...highlightStyles,
        },
        // 'tr:nth-child(even)': {
        //   backgroundColor: '#f2f2f2',
        //   'td:first-child': {
        //     ...stickyPosition,
        //     backgroundColor: '#f2f2f2',
        //   },
        // },
      },
    };
  }
);

const HoverStyles = styled.div(({ rowMinHeight, darkHeader }) => ({
  table: {
    thead: {
      th: {
        backgroundColor: darkHeader ? '#333' : '#fff',
        color: darkHeader ? '#ddd' : '#000',
      },
    },
    tbody: {
      tr: {
        alignItems: 'center',
        cursor: 'pointer',
        '&:hover': {
          boxShadow: '1px 1px 15px #ddd',
        },
        borderBottom: '1px solid #ddd',
        rowMinHeight,
      },
      'tr.selected': {
        backgroundColor: theme.colors.light.focus,
      },
      'tr.greyed': {
        backgroundColor: '#f1f1f1',
      }
    },
    tfoot: {
      backgroundColor: '#f1f1f1',
    },
  },
}));

export const StyledTable = ({ children, variant = 'hover', ...styleProps }) => {
  return (
    <TableStyles as={variant == 'sticky' ? StickyStyles : HoverStyles} {...styleProps}>
      {children}
    </TableStyles>
  );
};
