import React from 'react';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';

import { theme } from 'constants';
import { BDDLoader } from 'components/bdd/bddloader';
import BDDApiError from 'components/bdd/bddapierror';

const NoDataTitle = styled.div({
  ...theme.font.base,
  ...theme.typography.subtitle1,
  paddingLeft: theme.spacing[2],
});

const useQueryWithPlaceholder = (query, variables, placeholderProps, skip) => {
  const { data, loading, error, refetch } = useQuery(query, {
    variables,
    skip,
  });
  let placeholder;

  if (skip) {
    placeholder = null;
  } else if (loading) {
    placeholder = <BDDLoader variant="squares" {...placeholderProps} />;
  } else if (error) {
    placeholder = <BDDApiError error={error} />;
  } else if (!data) {
    placeholder = <NoDataTitle>No data found</NoDataTitle>;
  }

  return {
    data,
    error,
    refetch,
    placeholder,
    loading,
  };
};

export default useQueryWithPlaceholder;
