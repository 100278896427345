import { bruinsBlack, bruinsGold } from "helpers/plotting"
import styled from "styled-components"

const ContractBubble = styled.div({
  display: 'inline-block',
  background: (props) => props.background || 'white',
  color: (props) => props.color || bruinsBlack,
  borderColor: (props) => props.borderColor || bruinsBlack,
  borderWidth: '1px',
  borderStyle: 'solid',
  borderRadius: '10px',
  padding: '2px 5px',
  cursor: 'pointer',
  '&:hover': {
    boxShadow: `2px 2px 5px #333`,
  },
  transition: 'all 0.2s ease',
});

const GROUP_TO_STYLE = {
  'RFA': { background: bruinsGold, color: bruinsBlack },
  'UFA': { background: bruinsBlack, color: bruinsGold },
  '10.2(c)': { background: 'lightblue', color: 'black' },
  'GROUP VI': { background: 'white', color: 'black', borderColor: 'black' },
};


export default function BFContractBubble({
  children,
  expiryGroup,
  onClick
}) {
  return <ContractBubble style={GROUP_TO_STYLE[expiryGroup]} onClick={onClick}>
    {children}
  </ContractBubble>
}