import { useSearchParamsState } from 'components/bdd/hooks';
import { StyledBDDSelect } from 'components/bdd/Select/StyledBDDSelect';
import { useContext } from 'react';
import { PlayerSearchContext } from '..';
import { positionOptions } from '../constants';

export const usePositionFilter = () => {
  const { store, setStore } = useContext(PlayerSearchContext);
  const positions = store?.positions || [];

  const positionSelect = (
    <StyledBDDSelect
      selectedValue={positions}
      options={positionOptions}
      selectProps={{
        isMulti: true,
        isClearable: true,
        placeholder: 'Filter to position',
      }}
      onChange={(n, v) => {
        setStore((store) => ({
          ...store,
          positions: v,
        }));
      }}
    />
  );

  return {
    getPositionFilter: (statQuery, target) =>
      positions.length > 0
        ? {
            col: statQuery ? 'bf_player_position' : 'position',
            val: positions,
            cond: 'includes_substr',
          }
        : {
            col: statQuery ? 'bf_player_position' : 'position',
            val: 'G',
            cond: target == 'goalies' ? '=' : '!=',
          },
    positionSelect,
  };
};
