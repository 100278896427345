import { createApolloStore } from 'apollo/hooks';
import { GET_FULL_SCOUTED_RANKING_SET } from 'apollo/queries/scouting.queries';
import { GET_PLAYERS_SCOUTING_MODEL_RESULTS } from 'apollo/queries/models.queries';
import { PLAYER_LIST_TYPES } from 'constants';

export const useScoutingRankDataStore = createApolloStore({
  idKey: 'rinknetIds',
  query: GET_FULL_SCOUTED_RANKING_SET,
  setState: (scoutingData, get) => {
    // Group by rinknet player
    // Group by split id
    // Default to first split if split id is not provided

    const playerRankingData = scoutingData.fullScoutedRankingSet.playerRankings;
    const split = scoutingData.fullScoutedRankingSet.split;

    let rankingsByPlayer = get().rankingsByPlayer || {};
    rankingsByPlayer = playerRankingData.reduce((rankingsByPlayer, player) => {
      const rinknetId = player.rinknetPlayer.realId;
      if (!(rinknetId in rankingsByPlayer)) {
        rankingsByPlayer[rinknetId] = {};
      }

      rankingsByPlayer[rinknetId] = {
        ...rankingsByPlayer[rinknetId],
        [split.id]: player.rankings.reduce((ratingToRank, ranking) => {
          ratingToRank[ranking.ratingSlug] = ranking;
          return ratingToRank;
        }, {}),
      };

      return rankingsByPlayer;
    }, rankingsByPlayer);

    let splits = get().splits || {};
    if (!split.id in splits) {
      splits[split.id] = {};
    }

    splits[split.id] = split;
    return {
      rankingsByPlayer,
      splits,
    };
  },
});

export const getScoutingRanks = (rinknetId, rankingsByPlayer, splits, splitFilters) => {
  if (!rankingsByPlayer || !(rinknetId in rankingsByPlayer)) {
    return {};
  }

  const rankings = rankingsByPlayer[rinknetId];

  let filteredSplitIds = !!splits ? Object.keys(splits) : [];

  Object.keys(splitFilters).map((splitKey) => {
    filteredSplitIds = filteredSplitIds.filter(
      (id) => splits[id][splitKey] == splitFilters[splitKey]
    );
  });

  const splitId = filteredSplitIds[0];
  const split = splits[splitId];
  const ratingToRank = rankings[splitId];

  return {
    ratingToRank,
    split,
  };
};

export const useScoutingRanks = ({ rinknetId, splitFilters = {} }) => {
  const { rankingsByPlayer, splits } = useScoutingRankDataStore();

  return getScoutingRanks(rinknetId, rankingsByPlayer, splits, splitFilters);
};

export const useScoutingModelDataStore = createApolloStore({
  idKey: 'rinknetIds',
  query: GET_PLAYERS_SCOUTING_MODEL_RESULTS,
  setState: (modelData) => {
    const modelToScore = (rinknetId) => {
      const ret = modelData.scoutingModelResults.filter(
        (r) => r.rinknetPlayer.realId == rinknetId
      );
      return ret.reduce((acc, curr) => {
        acc[curr.hockeyModelResult.modelSlug] = curr.hockeyModelResult;
        return acc;
      }, {});
    };

    return {
      modelToScore,
    };
  },
});
const modelSlugs =[
  "draft-prod-f-score",
  "draft-prod-f-center",
  "draft-prod-f-outcome",
  "draft-prod-f-nhl",
  "draft-prod-d-score",
  "draft-prod-d-outcome",
  "draft-prod-d-nhl",
  "draft-scout-f-score",
  "draft-scout-f-center",
  "draft-scout-f-outcome",
  "draft-scout-f-nhl",
  "draft-scout-d-score",
  "draft-scout-d-outcome",
  "draft-scout-d-nhl",
  "draft-sl-f-score",
  "draft-sl-f-center",
  "draft-sl-f-outcome",
  "draft-sl-f-nhl",
  "draft-sl-d-score",
  "draft-sl-d-outcome",
  "draft-sl-d-nhl",
  "draft-overall-f-score",
  "draft-overall-f-center",
  "draft-overall-f-outcome",
  "draft-overall-f-nhl",
  "draft-overall-d-score",
  "draft-overall-d-outcome",
  "draft-overall-d-nhl",
  "draft-similar-players",
  "draft-similar-players-sl",
];

// Apollo has some built-in rules that prevents running the same query twice in one component
export const ScoutingRankDataLoader = (props) => {
  useScoutingRankDataLoader(props);
};

export const useScoutingRankDataLoader = ({
  skip,
  rinknetIds,
  listType = PLAYER_LIST_TYPES.PRO_LIST,
  queryVariables,
}) => {
  const useScoutingRankingSetQuery = useScoutingRankDataStore(
    (store) => store.actions.useEntityQuery
  );
  let variables =
    listType === PLAYER_LIST_TYPES.PRO_LIST
      ? {
          league: 'National Hockey League',
          timeRange: 'PAST_2_YEARS',
          scoutId: null,
          isDraftEligible: null,
          ...(!!queryVariables ? queryVariables : {}),
        }
      : [PLAYER_LIST_TYPES.DRAFT_LIST, PLAYER_LIST_TYPES.AGGREGATED_RANK_LIST].includes(
          listType
        )
      ? {
          league: null,
          isDraftEligible: true,
          scoutId: null,
          ...(!!queryVariables ? queryVariables : {}),
        }
      : {
          league: null,
          timeRange: 'PAST_2_YEARS',
          scoutId: null,
          isDraftEligible: null,
          ...(!!queryVariables ? queryVariables : {}),
        };

  const scoutingRankQuery = useScoutingRankingSetQuery({
    skip,
    variables: {
      ...variables,
      rinknetIds,
    },
  });

  const useScoutingModelSetQuery = useScoutingModelDataStore(
    (store) => store.actions.useEntityQuery
  );

  const scoutingModelQuery = useScoutingModelSetQuery({
    skip,
    variables: {
      modelSlugs,
      rinknetIds,
    },
  });

  return {
    loading: scoutingRankQuery.loading || scoutingModelQuery.loading,
    error: scoutingRankQuery.error || scoutingRankQuery.error,
  };
};
