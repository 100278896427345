import { useQuery } from "@apollo/client";
import { GET_PLAYER_CARD } from "apollo/queries/players.queries";
import usePlaceholder from "components/Placeholder/usePlaceholder";
import CareerStats from "components/PlayersV2/CareerStats";
import { ComparePlayerBio } from "components/PlayersV2/Compare/components";
import PlayerLinkHover from "components/PlayersV2/Hover/PlayerLinkHover";
import { WarProjectionBrief } from "components/PlayersV2/PlayerWar/WarProjectionBrief";
import { WarProjectionChart } from "components/PlayersV2/PlayerWar/WarProjectionChart";
import { SkillsOverview } from "components/PlayersV2/SkillsOverview";
import { SkillHighlightBoxes } from "components/PlayersV2/components/SkillHighlightBoxes";
import { IconButton } from "components/bdd/Button";
import { Divider } from "components/bdd/Divider";
import Icon from "components/bdd/Icon";
import { Border, Column, Container, Row } from "components/bdd/Layout";
import { Typography } from "components/bdd/Typography";
import BDDErrorBoundary from "components/bdd/bdderrorboundary";
import { TooltipSpan } from "components/reports";
import { theme } from "constants";
import { useState } from "react";
import { ArrowsCollapse, ArrowsExpand, CaretDown, CaretUp } from "react-bootstrap-icons";
import { BDDTradedPlayerBrief } from "./BDDTradeAsset";

const collapsedSkillCategories = [{
  label: '',
  statDefinitions: [
    {
      label: 'PBR',
      flatLabel: 'PBR',
      slug: 'pbrXPG',
      manpowerSituation: 'ES', strength: 55
    },
    {
      label: 'Pass',
      flatLabel: 'iXA',
      slug: 'iXA',
    },
    {
      label: 'Shoot',
      flatLabel: 'iXG',
      slug: 'iXG',
    },
    {
      label: 'Finish',
      flatLabel: 'A2E',
      slug: 'Act2Expected',
    },
    {
      label: '% OZ Poss',
      flatLabel: '% OZ Poss',
      slug: 'percentOZPossession',
    },
    {
      label: 'XPGF',
      flatLabel: 'XPGF',
      slug: 'XPGF',
      manpowerSituation: 'ES', strength: 55
    },
    {
      label: 'XPGA',
      flatLabel: 'XPGA',
      slug: 'XPGA',
      manpowerSituation: 'ES', strength: 55
    },
    {
      label: 'SCGPs',
      flatLabel: 'SCGPs',
      slug: 'scoringChanceGeneratingPlay',
      manpowerSituation: 'ES', strength: 55
    },
    {
      label: '%SCGP',
      flatLabel: '%SCGP',
      slug: 'percentageSCGP',
      manpowerSituation: 'ES', strength: 55
    },
    {
      label: 'PDPs',
      flatLabel: 'PDPs',
      slug: 'PDPs',
      manpowerSituation: 'ES', strength: 55
    },
    {
      label: '%PDP',
      flatLabel: '%PDP',
      slug: 'percentagePDP',
      manpowerSituation: 'ES', strength: 55
    },
    {
      label: 'PP Use',
      flatLabel: 'PP TOI/GP',
      slug: 'toiPerGP',
      manpowerSituation: 'PP',
    },
    {
      label: 'SH Use',
      flatLabel: 'SH TOI/GP',
      slug: 'toiPerGP',
      manpowerSituation: 'SH',
    },
  ]
}]

const collapsedGoalieSkillCategories = [{
  label: '',
  statDefinitions: [
    {
      label: 'SV%',
      flatLabel: 'SV%',
      slug: 'savePercentage',
    },
    {
      label: 'GSAA',
      flatLabel: 'GSAA',
      slug: 'goalsSavedAboveAverage',
    },
    {
      label: 'Rebound %',
      flatLabel: 'Rebound %',
      slug: 'dangerousReboundsGivenUpRate',
    },
    {
      label: 'Pass %',
      flatLabel: 'Pass %',
      slug: 'passPercentage',
    },
    {
      label: 'GBR',
      flatLabel: 'GBR',
      slug: 'GBR',
    },
  ]
}]


const ExpandButton = ({ toggleSection, isExpanded }) => {
  return <Row 
    onClick={() => toggleSection()}
    justifyContent='center'
    style={{ 
      transition: 'all 0.2s ease-in-out',
      cursor: 'pointer',
      border: '1px solid #ddd',
      '&:hover': {
        boxShadow: '0 -5px 4px #ddd',
      }
    }}
  >
    <IconButton icon={isExpanded ? <CaretUp/> : <CaretDown/>} />
  </Row>
}

const SectionWrapper = ({ toggleSection, isExpanded, children }) => {
  return <Container style={{ 
    transition: 'all 0.2s ease-in-out',
    // border: '1px solid #ddd',
    minHeight: isExpanded ? '50px' : null,
  }}>
    {children}
    {/* <ExpandButton toggleSection={toggleSection} isExpanded={isExpanded}/> */}
  </Container>
}


export const BDDTradePlayerAnalysis = ({
  slug,
  setAsset,
  retainedSalary,
  retainedSalaryPct,
  previousRetainedSalary,
  allowEdit = true,
  allowRetention = false,
  handleRemove,
  allowLayover = false,
  handleLayoverAsset,
  allowHover = true,
}) => {
  const [expanded, setExpanded] = useState([]);

  const { data, placeholder } = usePlaceholder(
    useQuery(GET_PLAYER_CARD, {
      variables: { slug },
    })
  );

  if (!data) return placeholder;
  const bddPlayer = data.bddPlayer;

  const toggleSection = (section) => {
    if (expanded.includes(section)) {
      setExpanded(expanded.filter((s) => s !== section));
    } else {
      setExpanded([...expanded, section]);
    }
  };
  const isExpanded = (section) => expanded.includes(section);

  return (
    <BDDErrorBoundary>
      <Container padding={1}>
        <Column gap={1}>
          <Row>
            <Container padding={1}>
              <BDDTradedPlayerBrief
                slug={slug}
                bddPlayer={bddPlayer}
                setAsset={setAsset}
                retainedSalary={retainedSalary}
                retainedSalaryPct={retainedSalaryPct}
                previousRetainedSalary={previousRetainedSalary}
                allowEdit={allowEdit}
                allowRetention={allowRetention}
                handleRemove={handleRemove}
                allowLayover={allowLayover}
                handleLayoverAsset={handleLayoverAsset}
                allowHover={allowHover}
                variant="subtitle1"
              />
            </Container>
          </Row>

          <Divider />

          {/* <Container height={'100%'} width={'100%'}>
            <SectionWrapper toggleSection={() => toggleSection('war')} isExpanded={isExpanded('war')}>
              {isExpanded('war') ? (
                <WarProjectionChart slug={bddPlayer.slug} posFDG={bddPlayer.posFDG} maxHeight={'200px'}/>
              ) : (
                <WarProjectionBrief slug={bddPlayer.slug} posFDG={bddPlayer.posFDG} />
              )}
            </SectionWrapper>
          </Container> */}

          <Container height={'100%'} width={'100%'}>
            <SectionWrapper
              toggleSection={() => toggleSection('careerStats')}
              isExpanded={isExpanded('careerStats')}
            >
              <CareerStats
                compact={!isExpanded('careerStats')}
                defaultVisibleRows={isExpanded('careerStats') ? 3 : 2}
                showFooter={isExpanded('careerStats')}
                includeTitle
                slug={bddPlayer.slug}
                epId={bddPlayer.epId}
              />
            </SectionWrapper>
          </Container>

          <Container height="100%" width="100%">
            <SectionWrapper
              toggleSection={() => toggleSection('skills')}
              isExpanded={isExpanded('skills')}
            >
              <Container padding={1}>
                {isExpanded('skills') ? (
                  <SkillsOverview
                    bddPlayer={bddPlayer}
                    showPlaceholder={true}
                  />
                ) : (
                  <SkillHighlightBoxes
                    bddPlayer={bddPlayer}
                    leagueSlug={'NHL'}
                    categories={
                      bddPlayer.posFDG === 'G'
                        ? collapsedGoalieSkillCategories
                        : collapsedSkillCategories
                    }
                    minWidth={theme.spacing[8]}
                    padding={0.5}
                  />
                )}
              </Container>
            </SectionWrapper>
          </Container>
        </Column>
      </Container>
    </BDDErrorBoundary>
  );
};