import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { Typography } from 'components/bdd/Typography';
import useInputRange from 'components/bdd/Range/useInputRange';
import { Column, Container, Row } from 'components/bdd/Layout';
import { heightInchesToStr } from 'helpers/hockeyutils';
import Icon from 'components/bdd/Icon';
import BDDErrorBoundary from 'components/bdd/bdderrorboundary';
import { ArrowDown, ArrowUp, X } from 'react-bootstrap-icons';
import { positionOptions } from 'components/Scouting/playerformhelpers';
import { StyledBDDSelect } from 'components/bdd/Select/StyledBDDSelect';
import { Divider } from 'components/bdd/Divider';
import React, { useEffect, useState } from 'react';
import useDebounce from 'components/bdd/hooks/usedebounce';
import { IconButton } from 'components/bdd/Button';
import { theme } from 'constants';
import produce from 'immer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox } from 'components/bdd/Checkbox';
import {
  CHECK_ID_TO_DESCRIPTION,
  checkIdToIcon,
  DEFENSEMEN_CHECK_ORDER,
  FORWARDS_CHECK_ORDER,
} from './DraftPlayerChecks';
import { TooltipSpan } from 'components/reports';
import { DraftCheckFilter } from './DraftCheckFilter';

const heightScale = { min: 66, max: 78 };
const weightScale = { min: 140, max: 250 };
const defaultScale = { min: 0, max: 100 };
const ageScale = { min: 16, max: 45 };

const draftRoundOptions = [
  'T10',
  '1M',
  '1L',
  '2nd',
  'Mid',
  'Late',
  'Mon',
  'ND',
  'Res',
  'FA',
].map((r) => ({ label: r, value: r }));

const buildFilters = (appliedFilters, filter, value) => ({
  ...appliedFilters,
  filters: {
    ...appliedFilters.filters,
    [filter.id]: {
      filter: filter,
      value,
    },
  },
});

const groupFilters = [
  {
    groupName: 'Player Bio',
    filters: [
      {
        id: 'height',
        label: 'Height',
        type: 'inputRange',
        scale: heightScale,
        unit: 'ft',
        getValue: (rinknetPlayer) =>
          rinknetPlayer.heightFt
            ? rinknetPlayer.heightFt * 12
            : rinknetPlayer.height_inches,
        formatLabel: (value) => heightInchesToStr(value),
      },
      {
        id: 'weight',
        label: 'Weight',
        type: 'inputRange',
        scale: weightScale,
        unit: 'lbs',
        getValue: (rinknetPlayer) => rinknetPlayer.weight,
      },
      {
        id: 'age',
        label: 'Age',
        type: 'inputRange',
        scale: ageScale,
        getValue: (rinknetPlayer) => rinknetPlayer.age,
        unit: 'yrs',
      },
      {
        id: 'position',
        label: 'Position',
        type: 'singleSelect',
        options: Object.values(positionOptions).flatMap((p) => p),
        getValue: (rinknetPlayer) => rinknetPlayer.position,
      },
      {
        id: 'handedness',
        label: 'Handedness',
        type: 'singleSelect',
        getValue: (rinknetPlayer) =>
          rinknetPlayer.handedness
            ? rinknetPlayer.handedness
            : rinknetPlayer.position == 'Goalie'
            ? rinknetPlayer.catchside
            : rinknetPlayer.shotside,
        options: [
          {
            value: 'L',
            label: 'Left',
          },
          {
            value: 'R',
            label: 'Right',
          },
        ],
      },
    ],
  },
  {
    groupName: 'Player Scouting',
    filters: [
      {
        id: 'scouting-skating',
        label: 'Skating',
        type: 'inputRange',
        scale: defaultScale,
        unit: '%',
        getValue: (rinknetPlayer, { getScoutingValue }) =>
          getScoutingValue(rinknetPlayer.realId, 'skating', 'Percentile.skating'),
      },
      {
        id: 'scouting-hockey-sense',
        label: 'Hockey Sense',
        type: 'inputRange',
        scale: defaultScale,
        unit: '%',
        getValue: (rinknetPlayer, { getScoutingValue }) =>
          getScoutingValue(
            rinknetPlayer.realId,
            'hockeySense',
            'Percentile.hockey_sense'
          ),
      },
      {
        id: 'scouting-scoring',
        label: 'Scoring',
        type: 'inputRange',
        scale: defaultScale,
        unit: '%',
        getValue: (rinknetPlayer, { getScoutingValue }) =>
          getScoutingValue(rinknetPlayer.realId, 'scoring', 'Percentile.scoring'),
      },
      {
        id: 'scouting-intensity',
        label: 'Intensity',
        type: 'inputRange',
        scale: defaultScale,
        unit: '%',
        getValue: (rinknetPlayer, { getScoutingValue }) =>
          getScoutingValue(rinknetPlayer.realId, 'intensity', 'Percentile.compete'),
      },
      {
        id: 'scouting-puck-possesion',
        label: 'Puck Possession',
        type: 'inputRange',
        scale: defaultScale,
        unit: '%',
        getValue: (rinknetPlayer, { getScoutingValue }) =>
          getScoutingValue(
            rinknetPlayer.realId,
            'puckHandling',
            'Percentile.puck_handling'
          ),
      },
      {
        id: 'scouting-creativity',
        label: 'Creativity',
        type: 'inputRange',
        scale: defaultScale,
        unit: '%',
        getValue: (rinknetPlayer, { getScoutingValue }) =>
          getScoutingValue(rinknetPlayer.realId, 'creativity', 'Percentile.creativity'),
      },
      {
        id: 'scouting-ovr-score',
        label: 'OVR Model Score',
        type: 'inputRange',
        scale: defaultScale,
        unit: '%',
        getValue: (rinknetPlayer, { getModelScore }) =>
          getModelScore(rinknetPlayer.realId, 'draft-overall-outcome'),
      },
    ],
  },
  {
    groupName: 'Predicted Player Scouting',
    filters: [
      {
        id: 'predicted-scouting-skating',
        label: 'Skating',
        type: 'inputRange',
        scale: defaultScale,
        unit: '%',
        getValue: (rinknetPlayer, { getPredictedScoutingValue }) =>
          getPredictedScoutingValue(
            rinknetPlayer.realId,
            'skating',
            'Percentile.skating'
          ),
      },
      {
        id: 'predicted-scouting-hockey-sense',
        label: 'Hockey Sense',
        type: 'inputRange',
        scale: defaultScale,
        unit: '%',
        getValue: (rinknetPlayer, { getPredictedScoutingValue }) =>
          getPredictedScoutingValue(
            rinknetPlayer.realId,
            'hockeySense',
            'Percentile.hockey_sense'
          ),
      },
      {
        id: 'predicted-scouting-scoring',
        label: 'Scoring',
        type: 'inputRange',
        scale: defaultScale,
        unit: '%',
        getValue: (rinknetPlayer, { getPredictedScoutingValue }) =>
          getPredictedScoutingValue(
            rinknetPlayer.realId,
            'scoring',
            'Percentile.scoring'
          ),
      },
      {
        id: 'predicted-scouting-intensity',
        label: 'Intensity',
        type: 'inputRange',
        scale: defaultScale,
        unit: '%',
        getValue: (rinknetPlayer, { getPredictedScoutingValue }) =>
          getPredictedScoutingValue(
            rinknetPlayer.realId,
            'intensity',
            'Percentile.compete'
          ),
      },
      {
        id: 'predicted-scouting-puck-possesion',
        label: 'Puck Possession',
        type: 'inputRange',
        scale: defaultScale,
        unit: '%',
        getValue: (rinknetPlayer, { getPredictedScoutingValue }) =>
          getPredictedScoutingValue(
            rinknetPlayer.realId,
            'puckHandling',
            'Percentile.puck_handling'
          ),
      },
      {
        id: 'predicted-scouting-creativity',
        label: 'Creativity',
        type: 'inputRange',
        scale: defaultScale,
        unit: '%',
        getValue: (rinknetPlayer, { getPredictedScoutingValue }) =>
          getPredictedScoutingValue(
            rinknetPlayer.realId,
            'creativity',
            'Percentile.creativity'
          ),
      },
    ],
  },
  {
    groupName: 'Draft Filters',
    compact: true,
    filters: [
      {
        id: 'draft-checks-F',
        label: 'Forward Draft Checks',
        filters: FORWARDS_CHECK_ORDER.map((checkId) => ({
          id: `draft-checks-${checkId}`,
          type: 'checkbox',
          getValue: (rinknetPlayer, { getDraftCheckValue }) =>
            getDraftCheckValue(rinknetPlayer.realId, checkId),
          renderFilter: (filterValue, setFilters) => (
            <Container key={checkId}>
              <DraftCheckFilter
                checkId={checkId}
                checkDescription={CHECK_ID_TO_DESCRIPTION[checkId]}
                checkValue={filterValue}
                onClick={setFilters}
              />
            </Container>
          ),
        })),
      },
      {
        id: 'draft-checks-D',
        label: 'Defensemen Draft Checks',
        filters: DEFENSEMEN_CHECK_ORDER.map((checkId) => ({
          id: `draft-checks-${checkId}`,
          type: 'checkbox',
          getValue: (rinknetPlayer, { getDraftCheckValue }) =>
            getDraftCheckValue(rinknetPlayer.realId, checkId),
          renderFilter: (filterValue, setFilters) => (
            <Container key={checkId}>
              <DraftCheckFilter
                checkId={checkId}
                checkDescription={CHECK_ID_TO_DESCRIPTION[checkId]}
                checkValue={filterValue}
                onClick={setFilters}
              />
            </Container>
          ),
        })),
      },
      {
        id: 'scouting-draft-round',
        id: 'draftRound',
        label: 'Highest Draft Round',
        type: 'singleSelect',
        options: draftRoundOptions,
        getValue: (rinknetPlayer, { getScoutingGradeSummary }) =>
          getScoutingGradeSummary(rinknetPlayer.realId),
      },
      {
        id: 'made-it-pct',
        label: 'Made It %',
        type: 'inputRange',
        scale: defaultScale,
        unit: '%',
        getValue: (rinknetPlayer, { getPlayerMadeItValue }) => {
          return getPlayerMadeItValue(rinknetPlayer.realId);
        },
      },
    ],
  },
];

export const ListFilterForm = ({ reset, onChange }) => {
  const [appliedFilters, setAppliedFilters] = useState({
    filters: {},
  });

  useEffect(() => {
    setAppliedFilters({
      filters: {},
    });
  }, [reset]);

  const debouncedFilters = useDebounce(appliedFilters, 200);

  useEffect(() => {
    onChange && onChange(debouncedFilters);
  }, [JSON.stringify(debouncedFilters)]);

  const applyFilter = (filter, value) =>
    setAppliedFilters({
      ...appliedFilters,
      filters: {
        ...appliedFilters.filters,
        [filter.id]: {
          filter: filter,
          value,
        },
      },
    });

  const getFilter = (f) =>
    f.renderFilter ? (
      f.renderFilter(appliedFilters.filters[f.id]?.value, (value) =>
        applyFilter(f, value)
      )
    ) : f.type == 'inputRange' ? (
      <Container key={f.label} paddingLeft={2}>
        <ListFilterInputRange
          filter={f}
          value={appliedFilters.filters[f.id]?.value}
          sorted={appliedFilters.sorted}
          onChange={(value) => applyFilter(f, value)}
          onSorted={(sorted) =>
            setAppliedFilters({
              ...appliedFilters,
              sorted,
            })
          }
        />
      </Container>
    ) : f.type == 'checkbox' ? (
      <Container key={f.id}>
        <Checkbox
          checked={appliedFilters.filters[f.id]?.value}
          label={f.label}
          id={f.id}
          onChange={(e) => applyFilter(filter, e.target.checked)}
        />
      </Container>
    ) : (
      <Container key={f.label}>
        <Row justifyContent="space-between">
          <Typography variant="label">{f.label}</Typography>
          {appliedFilters.filters[f.id]?.value != undefined &&
            appliedFilters.filters[f.id]?.value != null && (
              <IconButton
                icon={<X />}
                padding={0}
                hoverColor={theme.colors.light.text.secondary}
                onClick={() =>
                  setAppliedFilters(
                    produce((appliedFilters) => {
                      const filters = appliedFilters.filters;
                      delete filters[f.id];
                    })
                  )
                }
              />
            )}
        </Row>
        <StyledBDDSelect
          size="sm"
          variant={'outlined'}
          selectedValue={appliedFilters.filters[f.id]?.value}
          options={f.options}
          onChange={(n, v) => applyFilter(f, v)}
        />
      </Container>
    );

  return (
    <Container>
      <Column gap={2}>
        {groupFilters.map((gf, index) => (
          <React.Fragment key={gf.groupName}>
            <Container>
              <Column gap={1}>
                <Typography variant="body1">{gf.groupName}</Typography>
                <Row columnGap={gf.compact ? 4 : 8} rowGap={3} flexWrap alignItems="end">
                  {gf.filters.map((f) => {
                    if (!!f.filters) {
                      return (
                        <Container key={f.id}>
                          <Typography variant="label">{f.label}</Typography>
                          <Row columnGap={2}>{f.filters.map((f) => getFilter(f))}</Row>
                        </Container>
                      );
                    } else {
                      return getFilter(f);
                    }
                  })}
                </Row>
              </Column>
            </Container>
            {index < groupFilters.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </Column>
    </Container>
  );
};

export const ListFilterInputRange = ({ filter, value, sorted, onChange, onSorted }) => {
  const { inputRange } = useInputRange({
    ...filter.scale,
    valueSuffix: filter.unit,
    formatLabel: filter.formatLabel,
    initialMinValue: value?.min,
    initialMaxValue: value?.max,
    label: (
      <Row>
        {sorted?.id == filter.id ? (
          <>
            {sorted.direction == 'asc' && <Icon icon={<ArrowUp />} />}
            {sorted.direction == 'desc' && <Icon icon={<ArrowDown />} />}
          </>
        ) : (
          <Container width={14} />
        )}
        {filter.label}
        {/* {sorted?.label != filter.label && <Sublabel>(Click to Sort)</Sublabel>} */}
      </Row>
    ),
    onLabelSelected: () => {
      sorted?.id == filter.id && sorted.direction == 'desc'
        ? onSorted(null)
        : onSorted({
            id: filter.id,
            direction: !sorted ? 'asc' : 'desc',
            getValue: filter.getValue,
          });
    },
    onChange,
  });

  return inputRange;
};

export const ListFilterGroup = ({}) => {};
