import { Label } from "components/bdd/Label/Label";
import { Container, Row } from "components/bdd/Layout";
import { Typography } from "components/bdd/Typography";
import { useCreateUpdateBFTrade } from "./useCreateUpdateBFTrade";
import { Button } from "react-bootstrap";

export const BFTradeForm = ({
  existingBfTradeId,
  defaultTeams, // ['nhl.bos', 'nhl.ana']
  defaultAssets, // [{ fromTeamSlug, toTeamSlug, assetType, assetId, retainedSalary, retainedPct }]
  defaultDate,
  transactionId, // if creating this through a transaction, can link to it to mark it as completed
  onCancel,
  onSubmit,
}) => {
  const isUpdate = !!existingBfTradeId;
  const { form, submitButton, datePicker, assets } = useCreateUpdateBFTrade({
    isUpdate,
    tradeId: existingBfTradeId,
    defaultTeams,
    defaultAssets,
    defaultDate,
    transactionId,
    onSubmit: (bfTrade) => {
      !!onSubmit && onSubmit(bfTrade);
    },
  });

  const handleCancel = () => {
    if (!onCancel) return;
    if (assets.length > 0) {
      if (
        confirm(
          'Are you sure you want to abandon this trade? All changes will be lost.'
        )
      ) {
        onCancel();
      }
    } else {
      onCancel();
    }
  };

  return (
    <Container>
      <Container width={200}>
        <Label>Trade Date</Label>
        {datePicker}
      </Container>
      {form}
      <Row gap={8} alignItems="end">
        {!!onCancel && (
          <Button block variant="secondary" onClick={handleCancel}>
            <Typography variant="body1" textAlign="center">
              Cancel
            </Typography>
          </Button>
        )}

        {submitButton}
      </Row>
    </Container>
  );
};