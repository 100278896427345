import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TooltipSpan } from 'components/reports';
import { checkIdToIcon } from './DraftPlayerChecks';
import { Container } from 'components/bdd/Layout';

export const DraftCheckFilter = ({ checkId, checkDescription, checkValue: checkValue, onClick }) => {
  const checkValues = [null, 'HIGH_PASS', 'LOW_PASS', 'FAIL']
  return (
    <TooltipSpan content={checkDescription}>
      <Container
        variant="button"
        onClick={() => {
          const currentIdx = checkValue ? checkValues.indexOf(checkValue) : 0
          const newValue = checkValues[currentIdx + 1]
          onClick(newValue);
        }}
      >
        <FontAwesomeIcon
          icon={checkIdToIcon[checkId]}
          color={checkValue == 'HIGH_PASS' ? 'green' : checkValue == 'LOW_PASS' ? 'gold' : checkValue == 'FAIL' ? 'red' : 'grey'}
        />
      </Container>
    </TooltipSpan>
  );
};
