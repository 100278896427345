import { Container, Row } from 'components/bdd/Layout';
import { useMutation } from '@apollo/client';
import { UPDATE_PRO_OBJECTIVE_SCENARIO } from 'apollo/queries/proobjective.queries';
import { toastBddApiError, toastInfo } from 'components/bdd/bddtoasts';
import { ScenarioDepth } from './ScenarioDepth';
import { Card } from 'components/bdd/Card';
import { ScenarioDiagnostics } from './ScenarioDiagnostics';
import { IconButton } from 'components/bdd/Button';
import { ArrowLeft } from 'react-bootstrap-icons';
import {
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router-dom/cjs/react-router-dom.min';
import { Divider } from 'components/bdd/Divider';
import { Typography } from 'components/bdd/Typography';
import { useScenario } from './hooks/useScenario';
import { ScenarioContext, ScenarioContextProvider } from './ScenarioContextProvider';
import { ScenarioCapBreakdown } from './ScenarioCapBreakdown';
import { ScenarioOverview } from './ScenarioOverview';
import { Page } from 'components/bdd/Page';
import { useContext } from 'react';
import BDDEditInline from 'components/bdd/bddeditinline';

export const Scenario = ({
  id,
  proObjective, // optional -- only if we're inside of a PO
  allTargets,
  contextProps = {},
  pageMode = false, // if true, renders as the whole page
}) => {
  const { url } = useRouteMatch();

  // when we "refetch" based on DC changes, loading becomes true--but its not changing the base scenario data, just the DepthChecks and TeamStrength
  const { scenario, scenarioPlaceholder, loading, refetch } = useScenario({
    id,
    allTargets,
    cacheOnly: false,
  });

  const [updateScenario, { loading: updateScenarioLoading }] = useMutation(UPDATE_PRO_OBJECTIVE_SCENARIO, {
    onError: toastBddApiError,
    onCompleted: () => toastInfo('Scenario updated'),
  });

  const handleUpdate = (name, value) => {
    updateScenario({
      variables: {
        id,
        input: {
          [name]: value,
          id,
        },
      },
    });
  }

  const scenarioContext = {
    scenario,
    refetchScenario: refetch,
    scenarioLoading: loading,
    proObjective,
    proObjectiveSlug: proObjective?.slug,
    updateScenario,
    updateScenarioLoading,
    ...contextProps,
  };

  if (pageMode) {
    const routes = [
      {
        name: 'Overview',
        route: 'overview',
        render: () => <ScenarioOverview scenario={scenario} hideHeader={true} />,
      },
      {
        name: 'Roster',
        route: 'roster',
        render: () => <FullscreenDepth scenario={scenario} proObjective={proObjective} />,
      },
      {
        name: 'Cap Breakdown',
        route: 'cap',
        render: () => (
          <Card overflow="auto">
            <ScenarioCapBreakdown scenario={scenario} allowEdit={true} defaultEdit={true} />
          </Card>
        ),
      },
    ];
    return (
      <Page>
        <Page.Header
          showHistoryBack
          title={!!scenario
            ? (
              <BDDEditInline
                name='title'
                defaultValue={scenario.title}
                onChange={(n, v) => handleUpdate(n, v)}
                editingProps={{
                  style: {
                    width: '500px'
                  }
                }}
              />
            ) : 'Loading...'}
          subtitle={!!scenario && (
            <Typography variant='body2' style={{fontStyle: 'italic'}}>
              <BDDEditInline
                name='description'
                defaultValue={scenario.description || 'No description'}
                editingElement='textarea'
                editingProps={{
                  style: {
                    width: '500px'}
                }}
                onChange={(n, v) => handleUpdate(n, v)}
              />
            </Typography>
          )}
          routes={routes}
        />
        <Page.Body>
          {!scenario && scenarioPlaceholder}
          {scenario && (
            <ScenarioContextProvider context={scenarioContext}>
              <Page.Router routes={routes} />
            </ScenarioContextProvider>
          )}
        </Page.Body>
      </Page>
    );
  } else {
    return (
      scenario && (
        <ScenarioContextProvider context={scenarioContext}>
          <Container padding={2}>
            <Switch>
              <Route path={`${url}/roster`}>
                <FullscreenDepth scenario={scenario} proObjective={proObjective} />
              </Route>
              <Route path={`${url}/cap`}>
                <ScenarioCapBreakdown scenario={scenario} />
              </Route>
              <Route path={`${url}`}>
                <ScenarioOverview scenario={scenario} hideHeader={pageMode} />
              </Route>
            </Switch>
          </Container>
        </ScenarioContextProvider>
      )
    );
  }
};

const FullscreenDepth = ({ scenario, proObjective }) => {
  const { refetchScenario, scenarioLoading } = useContext(ScenarioContext);
  const history = useHistory();
  return (
    <Card
      overflow="auto"
      header={
        <Row gap={4}>
          <IconButton icon={<ArrowLeft />} onClick={() => history.goBack()} size="16px" />
          <Typography variant="h5">{scenario.title} Roster</Typography>
        </Row>
      }
      caption={
        <Typography variant='body1'>
          <ScenarioDiagnostics scenario={scenario} proObjective={proObjective} externalLoading={scenarioLoading} />
        </Typography>
      }
    >
      <Divider />
      <ScenarioDepth 
        scenario={scenario} 
        size="lg" 
        onUpdate={() => {
          refetchScenario({
            id: scenario.id,
            includeCapSheet: true,
            includeChecks: true,
            includeTeamStrength: true,
            cacheOnly: false,
          })
        }}
      />
    </Card>
  );
};
