import * as React from 'react';

import { Slot } from '@radix-ui/react-slot';
import { cn, withRef } from '@udecode/cn';
import { cva } from 'class-variance-authority';
import { TooltipSpan } from 'components/reports';

export const buttonVariants = cva(
  'font-display tracking-wide inline-flex items-center justify-center gap-1 whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0',
  {
    defaultVariants: {
      size: 'sm',
      variant: 'default',
    },
    variants: {
      isMenu: {
        true: 'w-full cursor-pointer justify-start',
      },
      size: {
        icon: 'w-[32px] h-[26px] rounded-md px-2',
        lg: 'h-9 rounded-md px-4',
        md: 'h-8 px-3 text-sm',
        none: '',
        sm: 'h-[26px] rounded-sm px-2 text-xs',
        xs: 'h-[24px] rounded-sm px-2 text-xs',
      },
      variant: {
        default: '!bg-primary border-input !text-primary-foreground hover:!bg-primary/80',
        action:
          '!bg-bos-500 border-input !text-primary hover:!bg-bos-700 active:!bg-bos-500',
        destructive:
          'bg-red-600 border-input !text-destructive-foreground hover:bg-red-700 active:bg-red-800',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        inlineLink: 'text-base text-primary underline underline-offset-4',
        link: 'text-primary underline-offset-4 hover:underline',
        outline:
          'border border-input !text-secondary-foreground bg-background hover:bg-accent hover:text-accent-foreground',
        secondary: '!bg-secondary !text-secondary-foreground hover:!bg-secondary/80',
      },
    },
  }
);

export const Button = withRef(
  (
    {
      asChild = false,
      className,
      isMenu,
      size,
      variant,
      tooltip,
      tooltipPlacement,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : 'button';

    const button = (
      <Comp
        ref={ref}
        className={cn(buttonVariants({ className, isMenu, size, variant }))}
        {...props}
      />
    );

    if (!!tooltip) {
      return (
        <TooltipSpan className={className} content={tooltip} placement={tooltipPlacement}>
          {button}
        </TooltipSpan>
      );
    }

    return button;
  }
);
