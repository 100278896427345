import { useRef, useState } from "react";
import { Container } from "../Layout"
import { Dropdown, Overlay, Popover } from "react-bootstrap";
import BDDErrorBoundary from "../bdderrorboundary";
import styled from "styled-components";
import { theme } from "constants";
import { Typography } from "../Typography";


export const useContextMenuWrapper = ({
  //  must supply one of the following
  content, // content to show as the trigger
  renderContent, // callback to render
  options, // [{ label, onClick }]

  title='',
  placement='bottom',
  disabled=false,
  closeButton=false,
  rootClose=true
}) => {
  /* This hook allows you to build a custom context menu for a component
  Supply either content, renderContent or options to determine the content of the menu
  Then call "render" and pass in your trigger component -- the thing you right click to open the menu */
  const [show, setShow] = useState(false);
  const target = useRef(null);
  
  if (!content && !options && !renderContent) throw Error('Must supply "content", "options", or "renderContent"')
  const forceClose = () => setShow(false);

  const render = (children) => {
    if (!content && !title && !renderContent && !options) return children;

    return (
      <Container>
        <div
          ref={target}
          onContextMenu={ev => {
            ev.preventDefault();
            setShow(true);
          }}
        >
          {children}
        </div>
        <ContextMenu
          target={target}
          show={show}
          setShow={setShow}
          forceClose={forceClose}
          title={title}
          content={content}
          options={options}
          placement={placement}
          disabled={disabled}
          closeButton={closeButton}
          rootClose={rootClose}
        />
      </Container>
    )
  }

  return {
    render,
    show,
    forceClose
  }
}


const DropdownMenuContent = styled.div({
  maxHeight: theme.spacing[48],
  overflowY: 'auto',
});

const DropdownButton = styled.div({ ...theme.typography.body3 });


export const ContextMenu = ({
  target, // ref
  show,
  setShow,
  forceClose,
  title, 
  content, 
  renderContent, 
  options, // [{ label, onClick }]
  placement="bottom", 
  disabled=false,
  closeButton=false,
  rootClose=true
}) => {

  return <Overlay
    show={show && !disabled}
    onHide={() => setShow(false)}
    placement={placement}
    flip={placement === 'auto'}
    target={target?.current}
    rootClose={rootClose}
    // trigger={['hover', 'focu']}
  >
    {props => <Popover {...props} style={{ maxWidth: "100%", boxShadow: "0 0 5px #ddd", ...props.style }}
        id={`popover-hoverinfo-${title || parseInt(Math.random()*100)}`}
    >
        {(title || closeButton) && (
          <Popover.Header as="h4">
            {title}
          </Popover.Header>
        )}
        <Popover.Body style={{ padding: 0 }}>
          <BDDErrorBoundary>
            <Typography variant='stat'>
              {renderContent 
                ? renderContent(forceClose) 
                : !!content
                ? typeof(content) === 'function' ? content() : content
                : !!options
                ? (
                  <DropdownMenuContent>
                    {options.map((option, index) => (
                      <Dropdown.Item
                        key={`dropdown-${index}`}
                        onClick={() => {
                          option.onClick();
                          forceClose();
                        }}
                      >
                        <DropdownButton>{option.label}</DropdownButton>
                      </Dropdown.Item>
                    ))}
                  </DropdownMenuContent>
                ) : null }
              </Typography>
          </BDDErrorBoundary>
        </Popover.Body>
    </Popover> }
  </Overlay>
}