import React, { useState } from "react"
import styled from 'styled-components'

import { OverlayTrigger, Tooltip, Popover, Button } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons"

import BDDPlotly from "../bdd/bddplotly"
import TeamsStatDetailModal from "./teamsstatdetailmodal"
import { getRedGreenColor } from "../../helpers/tables"
import { roundToX } from "../../helpers/data"
import { getNHLTeamLogoUrl } from "../../helpers/logos"
import { Unprinted } from "./report.elements"
import { reportStateVar } from "../../apollo/client"
import { NUM_TEAMS } from "../../constants"

const VerticalTable = styled.table`
width: 100%;
text-align: center;
`
const TdRank = styled.td`
width: 5%;
${props => `background: ${getRedGreenColor(props.rank, props.numTeams || NUM_TEAMS, 0)}`}
`
const TdValue = styled.td`
width: 20%;
${props => `background: linear-gradient(to ${props.isRight ? 'left' : 'right'}, ${getRedGreenColor(props.rank, props.numTeams || NUM_TEAMS, 0)}, #fff)`}
`
const TrStat = styled.tr`
    border-bottom: 1px solid #DDDDDDDD;
    ${props => `${props.gray ? 
        `color: #DDD;

        td {
            background: #777;
        };
        @media print {
            display: none;
        };
        `
    : null}`}
`
const TdStat = props => {

    return <OverlayTrigger
        placement="top"
        delay={{ show: 150, hide: 150 }}
        overlay={<Tooltip>
            {props.description}
        </Tooltip>}
    >
        <td
            style={{width: "50%", cursor: "pointer"}}
            onDoubleClick={props.onDoubleClick}
        >
            {props.children}
        </td>
    </OverlayTrigger>
}

const MAX_GREEN = getRedGreenColor(1, 31, 0)
const MAX_RED = getRedGreenColor(31, 31, 0)

const MiniTrendLine = ({xs, ys, n, avgLine, negative}) => {
    const len = xs.length
    const numGames = n ? n : len
    const start = Math.max(0, len-numGames)
    const halfPoint = start + Math.ceil((len - start)/2)
    const firstHalf = ys.slice(start, halfPoint)
    const firstHalfAvg = firstHalf.reduce((a, b) => a+b) / firstHalf.length
    const secondHalf = ys.slice(halfPoint, len)
    const secondHalfAvg = secondHalf.reduce((a, b) => a+b) / secondHalf.length
    const compLine = 0.5 * avgLine
    if (firstHalfAvg - secondHalfAvg > compLine) {
        var color = negative ? MAX_GREEN : MAX_RED
    } else if (secondHalfAvg - firstHalfAvg > compLine) {
        var color = negative ? MAX_RED : MAX_GREEN
    } else {
        var color = 'black'
    }

    return <BDDPlotly
        data={[{
            type: 'scatter',
            mode: 'lines',
            x: [xs[start], xs[len-1]],
            y: [avgLine, avgLine],
            opacity: 0.5,
            line: { color: '#555', width: 1, dash: 'dot' }
        }, {
            type: 'scatter',
            mode: 'lines',
            x: xs.slice(start, len),
            y: ys.slice(start, len),
            opacity: 0.5,
            line: { color: color }
        }]}
        layout={{
            margin: {t: 0, b: 0, l: 0, r: 0},
            height: 20,
            width: 110,
            showlegend: false,
            hovermode: false,
            xaxis: {
                autorange: true,
                showgrid: false,
                zeroline: false,
                showline: false,
                autotick: true,
                ticks: '',
                showticklabels: false
            },
            yaxis: {
                autorange: true,
                showgrid: false,
                zeroline: false,
                showline: false,
                autotick: true,
                ticks: '',
                showticklabels: false
            },
            annotations: [{  
                x: xs[len-1],  // arrows' head
                y: ys[len-1],  // arrows' head
                ax: xs[len-2],  // arrows' tail
                ay: ys[len-2],  // arrows' tail
                xref: 'x',
                yref: 'y',
                axref: 'x',
                ayref: 'y',
                text: '',
                showarrow: true,
                arrowhead: 1,
                arrowsize: 1,
                arrowwidth: 2,
                arrowcolor: color,
                opacity: 0.5
            }]
        }}
        config={{ displayModeBar: false, staticPlot: true }}
    />
}

export function VerticalStatTable({ 
    title,
    teams, 
    data, 
    stats,
    format, 
    rankings,
    sectionkey, 
    showTeams, 
    adminMode,
    hiddenStats=[],
    dataByGame,
    numTeams=31
}) {
    const [showAll, setShowAll] = useState(false)
    const [statModalInfo, setStatModalInfo] = useState({show: false, statSlug: null, data: null})

    const idxToData = {}
    data.forEach(d => idxToData[d.slug] = d)

    // const idxToDataByGame = {}
    // teams.forEach(t => idxToDataByGame[t.slug] = dataByGame.filter(d => d.slug === t.slug))

    const slugToDescription = {}
    const slugToPercentage = {}
    const slugToNegative = {}
    const statSlugToLabel = {}
    format.stat_definitions.forEach(s => {
        slugToDescription[s.slug] = s.description
        slugToPercentage[s.slug] = s.is_percentage
        slugToNegative[s.slug] = s.negative
        statSlugToLabel[s.slug] = s.label
    })

    const toggleHideStat = statSlug => {
        const oldState = reportStateVar()
        const newState = {...oldState}
        newState.hiddenStats = [...oldState.hiddenStats]
        const sectionStat = `${sectionkey}:${statSlug}`
        const statIdx = newState.hiddenStats.indexOf(sectionStat)
        if (statIdx >= 0) {
            newState.hiddenStats.splice(statIdx, 1)
        } else {
            newState.hiddenStats.push(sectionStat)
        }
        reportStateVar(newState)
    }

    const handleDoubleClick = statSlug => {
        setStatModalInfo({show: true, statSlug: statSlug, data: {
            [teams[0].slug]: rankings[teams[0].slug][0].rankings[statSlug],
            [teams[1].slug]: rankings[teams[1].slug][0].rankings[statSlug],
        }})
    }

    return <>
    <VerticalTable>
        <thead>
            <tr style={{borderBottom: "1px solid #33333333"}}>
                <th colSpan={!!rankings ? 3 : 2}>
                    {showTeams ? <>
                        <img src={getNHLTeamLogoUrl(teams[0].nhlid)} height="20px"/>
                        {teams[0].longname}
                    </> : null}
                </th>
                <th>
                    {title}
                </th>
                <th colSpan={!!rankings ? 3 : 2}>
                    {showTeams ? <>
                        <img src={getNHLTeamLogoUrl(teams[1].nhlid)} height="20px"/>
                        {teams[1].longname}
                    </>
                    : null}
                </th>
            </tr>
        </thead>
        <tbody>
        {stats.map((statSlug, i) => {
            const statLabel = statSlugToLabel[statSlug]
            if (!adminMode) {
                if (hiddenStats.includes(statSlug) && !showAll) {
                    return <tr style={{display: 'none'}} key={`hidden-${i}`}></tr>
                }
            }
            try {
                var rank1 = rankings[teams[0].slug][0].rankings[statSlug].ranking
                var rank2 = rankings[teams[1].slug][0].rankings[statSlug].ranking
            } catch {
                var rank1 = null
                var rank2 = null
            }

            return <TrStat key={statSlug} gray={hiddenStats.includes(statSlug) && adminMode}>
                {rank1 && <TdRank rank={rank1} numTeams={numTeams}>{rank1}</TdRank>}
                <TdValue rank={rank1} numTeams={numTeams}>
                    {roundToX(idxToData[teams[0].slug][statLabel], 2)}{slugToPercentage[statSlug] ? '%' : ''}
                </TdValue>
                <td>
                    {!!dataByGame ? <MiniTrendLine
                        // data={dataByGame[teams[0].slug]}
                        xs={dataByGame[teams[0].slug].xs}
                        ys={dataByGame[teams[0].slug][statLabel]}
                        avgLine={idxToData[teams[0].slug][statLabel]}
                        negative={slugToNegative[statSlug]}
                        // statLabel={statLabel}
                        n={12}
                    /> : null}
                </td>
                <TdStat 
                    description={slugToDescription[statSlug]}
                    onDoubleClick={() => handleDoubleClick(statSlug)}
                >
                    {statLabel}
                </TdStat>
                <td>
                    {!!dataByGame ? <MiniTrendLine
                        xs={dataByGame[teams[1].slug].xs}
                        ys={dataByGame[teams[1].slug][statLabel]}
                        avgLine={idxToData[teams[1].slug][statLabel]}
                        negative={slugToNegative[statSlug]}
                        // data={dataByGame[teams[1].slug]}
                        // statLabel={statLabel}                      
                        n={12}
                    /> : null}
                </td>
                <TdValue isRight={true} rank={rank2} numTeams={numTeams}>
                    {roundToX(idxToData[teams[1].slug][statLabel], 2)}{slugToPercentage[statSlug] ? '%' : ''}
                </TdValue>
                {rank2 && <TdRank rank={rank2} numTeams={numTeams}>{rank2}</TdRank>}
            </TrStat>
        })}
        </tbody>

    </VerticalTable>
    {adminMode ? 
    <Unprinted>
        <small><em>Double click a stat for more info</em></small>
    </Unprinted>
    : <Unprinted style={{width: "100%", textAlign: 'right', marginRight: "10px"}}>
        <div style={{right: 0}}>
        <Button variant="outline-dark" size="sm" onClick={() => setShowAll(!showAll)}>
            {showAll ? "Filter" : "Show All"} Stats
        </Button>
        {' '}
        <OverlayTrigger trigger={['hover', 'focus']} placement="top" 
        overlay={<Popover id='popover-showstat-info'>
            <Popover.Header as="h4">
                Control the stats you see
            </Popover.Header>
            <Popover.Body>
                By default, you'll see the stats curated by the analytics department.
                The "Show All Stats"/"Filter Stats" toggle allows you to flip between the
                complete view, and the curated view.
            </Popover.Body>
            </Popover>}
        >
            <a style={{cursor: 'pointer'}}>
                <FontAwesomeIcon icon={faQuestionCircle}/>
            </a>
        </OverlayTrigger>
        </div>
    </Unprinted> }
    {/* {statModalInfo.show ? <TeamsStatDetailModal
        show={statModalInfo.show}
        handleClose={() => setStatModalInfo({show: false, statSlug: null, data: null})}
        adminMode={adminMode}
        toggleHideStat={toggleHideStat}
        statSlug={statModalInfo.statSlug}
        statLabel={statSlugToLabel[statModalInfo.statSlug]}
        situation={props.situation}
        description={slugToDescription[statModalInfo.statSlug]}
        teamData={statModalInfo.data}
        teams={teams}
        forwardData={props.forwardData}
        defData={props.defData}
        skaterData={props.skaterData}
    /> : null } */}
    </>
    }