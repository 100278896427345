import { useMutation, useQuery } from "@apollo/client";
import { ADD_PRO_OBJECTIVE_TRANSACTION_VARIANT, CREATE_PRO_OBJECTIVE_TRANSACTION, EDIT_PRO_OBJECTIVE_TRANSACTION } from "apollo/queries/proobjective.queries";
import { toastBddApiError } from "components/bdd/bddtoasts";
import { UPDATE_BDD_TRADE } from "apollo/queries/bddtransactions.queries";


export const useTransactionMutations = ({}) => {
  const [createPOTransaction, { loading: transactionMutationLoading }] = useMutation(CREATE_PRO_OBJECTIVE_TRANSACTION, {
    onError: toastBddApiError,
    update: (cache, { data: { createProObjectiveTransaction: { proObjectiveTransaction }}}) => {
      cache.modify({
        fields: {
          proObjectiveTransactions(existing=[]) {
            return existing.concat([proObjectiveTransaction]);
          }
        }
      })
    }
  });

  const [addPOTransactionVariant, { loading: addPOTransactionVariantLoading }] = useMutation(ADD_PRO_OBJECTIVE_TRANSACTION_VARIANT, {
    onError: toastBddApiError,
    update: (cache, { data: { addProObjectiveTransactionVariant: { proObjectiveTransaction }}}) => {
      cache.modify({
        fields: {
          proObjectiveTransactions(existing=[]) {
            // replace the old po transaction with the new one
            return existing.map(t => t.id === proObjectiveTransaction.id ? proObjectiveTransaction : t);
          }
        }
      })
    }
  });


  const [editPOTransaction, { loading: editTransactionMutationLoading }] = useMutation(EDIT_PRO_OBJECTIVE_TRANSACTION, {
    onError: toastBddApiError
  })

  const [updateTrade] = useMutation(UPDATE_BDD_TRADE, {
    onError: (err) => toastBddApiError(err)
  })

  return {
    createPOTransaction,
    transactionMutationLoading,
    addPOTransactionVariant,
    addPOTransactionVariantLoading,
    editPOTransaction,
    editTransactionMutationLoading,
    updateBDDTrade: updateTrade
  }
}