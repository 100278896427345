import React, { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { GET_FULL_SCOUTED_RANKING_SET } from '../../../apollo/queries/scouting.queries'
import { ChooseSplitTable } from './choosesplittable'
import { BDDLoader } from '../../bdd/bddloader'
import BDDApiError from '../../bdd/bddapierror'
import { Table } from '../../Stats/stattable'
import { roundToX } from '../../../helpers/data'
import { getRedGreenColor, sortNumeric } from '../../../helpers/tables'
import { HoverInfo } from '../../bdd'
import PlayerHover from '../../PlayersV2/Hover/playerhover'

export default function RankingsTable({
    splitId,
    handleSetSplitId
}) {
    const { data, loading, error } = useQuery(GET_FULL_SCOUTED_RANKING_SET, {
      variables: { splitId },
      skip: !splitId,
    });

    const tableData = useMemo(() => {
      if (!data) return [];
      const ret = [];
      data.fullScoutedRankingSet?.playerRankings.forEach((r) => {
        const row = {
          ...r.rinknetPlayer,
          slug: r.rinknetPlayer?.bddPlayerSlug?.slug,
        };
        r.rankings.forEach((rnk) => {
          row[rnk.ratingSlug] = rnk;
          row[`${rnk.ratingSlug}_pct`] = 1 - rnk.percentile;
        });
        // Add "_pct" for even missing ratings
        const ratings = data.fullScoutedRankingSet.ratings;
        ratings.forEach((ratingSlug) => {
          if (!(`${ratingSlug}_pct` in row)) {
            row[`${ratingSlug}_pct`] = null;
          }
        });
        ret.push(row);
      });
      return ret;
    }, [data]);

    const sortTypeFunc = useMemo(() => sortNumeric);
    const columns = useMemo(() => {
      if (!!data) {
        const ratings = data.fullScoutedRankingSet?.ratings;
        const ret = [
          {
            Header: 'Player',
            accessor: (d) => (
              <PlayerHover playerSlug={d.slug}>
                {d.firstname} {d.lastname}
              </PlayerHover>
            ),
            sortType: (a, b) =>
              a.original.lastname > b.original.lastname ? 1 : -1,
          },
        ];
        return ret.concat(
          ratings.map((r) => ({
            id: `${r}_pct`,
            Header: r,
            accessor: (d) =>
              !!d[r] ? roundToX(100 * (1 - d[r].percentile), 1) : null,
            Cell: ({ value, row, column }) => {
              const ratingSlug = column.id.split('_pct')[0];
              const background = getRedGreenColor(value, 0, 100, false, 2);
              return (
                <div style={{ background }}>
                  <HoverInfo
                    content={
                      ratingSlug in row.original ? (
                        <>{row.original[ratingSlug].count} ratings</>
                      ) : (
                        <em>No ratings</em>
                      )
                    }
                  >
                    {!!value ? value : ' '}
                  </HoverInfo>
                </div>
              );
            },
            sortType: sortTypeFunc,
            sortDescFirst: true,
          }))
        );
      }
      return [];
    }, [data]);

    if (loading) return <BDDLoader/>
 
    const ratings = !!data ? data.fullScoutedRankingSet.ratings : []
    const split = !!data ? data.fullScoutedRankingSet.split : null
    const numPlayers = !!data ? data.fullScoutedRankingSet.playerRankings.length : null

    return <>
        {!!split ? <div style={{ fontSize: '0.8em', textAlign: 'center' }}>
            Ranking of {numPlayers}
            {' '}
            {!!split.league ? split.league.split(' ').map(word => word[0])
            : !!split.leagueGroup ? split.leagueGroup
            : null}
            {' '}
            {split.isDraftEligible ? 'Draft Elig.' : ''}
            {' '}
            {!!split.position ? `${split.position.toLocaleUpperCase()}s` : 'players'} 
            {' '}
            {split.timeRange == 'PAST_YEAR' ? 'from past year' : null} 
            {' '}
            {!!split.scoutName && `based on ${split.scoutName}'s views`}
            {' '}
            {!!split.predictedGrades && `using predicted grades`}

        </div> : <em>No data found</em>}  
        <div>
            {loading ? <BDDLoader/>
            : error ? <BDDApiError error={error}/>
            : !!data ? <Table
                columns={columns}
                data={tableData}
                defaultSort={ratings.length ? [{id: `${ratings[0]}_pct`, desc: true}] : []}
                defaultPageSize={25}
            />
            : null}
        </div>
    </>
}