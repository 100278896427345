import BDDSortableTable from 'components/bdd/bddsortabletable';
import { toastError, toastInfo } from 'components/bdd/bddtoasts';
import { Column, Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import { theme } from 'constants';
import { formatDate } from 'helpers/helpers';
import styled from 'styled-components';
import useBFTradeModal from '../hooks/useBFTradeModal';
import useTrades from '../hooks/useTrades';
import { BFTrade } from './BFTrade';
import { permissions } from 'constants';
import { useUser } from 'helpers/user';
import { IconButton } from 'components/bdd/Button';
import { Trash } from 'react-bootstrap-icons';
import { useBFTradeMutations } from 'components/ProScouting/BFTrades/useBFTradeMutations';
import { useConfirmModal } from 'components/bdd/Confirm/useConfirmModal';

const Styles = styled.div({
  table: {
    td: {
      ...theme.typography.body2,
    },
    tbody: {
      tr: {
        cursor: 'pointer',
        '&:hover': {
          boxShadow: '1px 1px 15px #ddd',
        },
        borderBottom: '1px solid #ddd',
      },
    },
  },
});

const SORTABLE_COLUMN_MAPPER = {
  // maps table col id to sortable column
  date: 'date',
  updatedAt: 'updatedAt',
};

export default function TradesTable({
  filterCallback,
  renderTradePick,
  hidePaginationControls,
  useSearchParams,
  preventModal,
  defaultPageSize = 50,
  showDelete=false
}) {
  const { userHasPermission } = useUser();
  const { trades, placeholder, pageControls, sort, setSort } = useTrades({
    defaultPageSize,
  });
  const { handleRenderModal } = useBFTradeModal({ useSearchParams });

  // if (!!placeholder) return placeholder;

  const { deleteBFTrade } = useBFTradeMutations({
    onDelete: () => toastInfo('Trade deleted')
  })

  const { modal, setStateAndShow } = useConfirmModal({
    prompt: 'Are you sure you want to delete this trade?',
    onConfirm: (tradeId) => {
      deleteBFTrade({
        variables: {
          id: tradeId
        }
      })
    }
  });

  let columns = [
    {
      id: "date",
      Header: <Typography variant="body1">Date</Typography>,
      accessor: (t) => formatDate(t.date),
    },
    {
      id: "details",
      Header: <Typography variant="body1">Details</Typography>,
      accessor: (t) => <BFTrade trade={t} renderTradePick={renderTradePick} />,
    },
  ];
  if (showDelete && userHasPermission(permissions.manageSalaryCap)) {
    columns.push({
      id: "delete",
      Header: "",
      accessor: (t) => (
        <IconButton
          icon={<Trash />}
          onClick={(ev) => {
            ev.stopPropagation();
            setStateAndShow(t.id);
          }}
          tooltip="Delete BF Trade (permanently)"
        />
      ),
    });
  }

  const handleSortChange = (newSort) => {
    const unsortable = newSort.filter(
      (s) => !SORTABLE_COLUMN_MAPPER.hasOwnProperty(s.id)
    );
    if (unsortable.length > 0) {
      toastError(
        `Can't sort on column(s) ${unsortable.map((s) => s.id).join(", ")}`
      );
      setSort(sort);
    } else {
      setSort(newSort);
    }
  };

  const filteredTrades = filterCallback ? filterCallback(trades) : trades;

  if (!!placeholder) return placeholder;
  return (
    <Container padding={2}>
      {modal}
      {!hidePaginationControls && pageControls}
      <Styles>
        <BDDSortableTable
          data={filteredTrades}
          columns={columns}
          columnJustify="center"
          manualSortBy={true}
          onSortChange={handleSortChange}
          defaultSort={sort}
          onRowClick={(row) => {
            !preventModal && handleRenderModal({ bfTrade: row.original });
          }}
        />
      </Styles>
    </Container>
  );
}
