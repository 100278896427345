import React, { useState } from 'react';
import Toggle from './Toggle';
import { Flex } from '../Layout';
import { Text } from '../Text';
import { Switch } from './Switch';
import { Label } from '../Label/ControlLabel';

export default function useToggle({
  id,
  label,
  labelToggled,
  onToggled,
  initialToggled,
  disabled = false,
  tooltip,
}) {
  const [toggled, setToggled] = useState(initialToggled || false);
  const handleToggled = (toggled) => {
    setToggled(toggled);
    onToggled && onToggled(toggled);
  };

  return {
    toggled,
    toggleComponent: (
      <Flex gap={1}>
        <Switch
          id={id}
          checked={toggled}
          disabled={disabled}
          onCheckedChange={handleToggled}
        />
        <Label htmlFor={id}>{toggled && labelToggled ? labelToggled : label}</Label>
      </Flex>
    ),
    // toggleComponent: (
    //   <Toggle
    //     key={id}
    //     disabled={disabled}
    //     id={id}
    //     label={label}
    //     labelToggled={labelToggled}
    //     toggled={toggled}
    //     setToggled={handleToggled}
    //     tooltip={tooltip}
    //   />
    // ),
    forceSetToggle: (val) => setToggled(val),
  };
}
