'use client';

import * as React from 'react';
import * as LabelPrimitive from '@radix-ui/react-label';
import { cva } from 'class-variance-authority';
import { cn } from '@udecode/cn';
import { Text } from '../Text';

const labelVariants = cva(
  'mb-0.5 leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'
);

const Label = React.forwardRef(({ className, disabled, ...props }, ref) => (
  <Text variant="label" color={!disabled ? 'primary' : 'muted'} asChild>
    <LabelPrimitive.Root
      ref={ref}
      className={cn(labelVariants(), className)}
      disabled={disabled}
      {...props}
    />
  </Text>
));
Label.displayName = LabelPrimitive.Root.displayName;

export { Label };
