import { useMutation, useQuery } from "@apollo/client"
import { GET_OR_CREATE_TEAM_SCENARIO_BY_KEY, UPDATE_PRO_OBJECTIVE_SCENARIO } from "apollo/queries/proobjective.queries"
import { toastBddApiError, toastInfo } from "components/bdd/bddtoasts";
import Icon from "components/bdd/Icon";
import { Container, Row } from "components/bdd/Layout";
import { Typography } from "components/bdd/Typography";
import usePlaceholder from "components/Placeholder/usePlaceholder"
import { useScenario } from "components/ProObjectives/Scenarios/hooks/useScenario";
import { ProScenario } from "components/ProObjectives/Scenarios/ProScenario";
import { Scenario } from "components/ProObjectives/Scenarios/Scenario";
import { ScenarioCapBreakdown } from "components/ProObjectives/Scenarios/ScenarioCapBreakdown";
import { buildProScenarioLink } from "components/ProObjectives/Scenarios/ScenarioCard";
import { ScenarioContextProvider } from "components/ProObjectives/Scenarios/ScenarioContextProvider";
import { BoxArrowInRight } from "react-bootstrap-icons";
import { Link } from "react-router-dom/cjs/react-router-dom.min";


export const TeamCapScenario = ({ teamSlug, scenarioKey }) => {
  const { data, placeholder } = usePlaceholder(useQuery(GET_OR_CREATE_TEAM_SCENARIO_BY_KEY, {
    variables: {
      teamSlug,
      uniqueKey: scenarioKey
    }
  }));

  const scenario = data?.getOrCreateTeamScenarioByKey;
  if (!!placeholder) return placeholder;

  return (
    <TeamCapScenarioOutlook id={scenario?.id} />
  )
}


const TeamCapScenarioOutlook = ({ id, allTargets=[] }) => {
    // when we "refetch" based on DC changes, loading becomes true--but its not changing the base scenario data, just the DepthChecks and TeamStrength
    const { scenario, scenarioPlaceholder, loading, refetch } = useScenario({
      id,
      allTargets,
      cacheOnly: true,
    });
  
    const [updateScenario, { loading: updateScenarioLoading }] = useMutation(UPDATE_PRO_OBJECTIVE_SCENARIO, {
      onError: toastBddApiError,
      onCompleted: () => toastInfo('Scenario updated'),
    });
  
    const scenarioContext = {
      scenario,
      refetchScenario: refetch,
      scenarioLoading: loading,
      proObjective: null,
      proObjectiveSlug: null,
      updateScenario,
      updateScenarioLoading,
    };
  if (!scenario) return scenarioPlaceholder;
  return (
    <ScenarioContextProvider context={scenarioContext}>
      <Container padding={2}>
        <Container>
          <Row justifyContent='end'>
            <Link to={buildProScenarioLink(scenario.id)}>
              <Row gap={2}>
                <Icon icon={<BoxArrowInRight/>}/>
                <Typography variant='stat'>Open Scenario</Typography>
              </Row>
            </Link>
          </Row>
        </Container>
        <ScenarioCapBreakdown scenario={scenario} />
      </Container>
    </ScenarioContextProvider>
  )
}