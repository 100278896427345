import React from 'react';
import styled from 'styled-components';
import { ButtonGroup, Dropdown } from 'react-bootstrap';

import { theme } from 'constants';
import { Container, Row } from '../Layout';
import Icon from '../Icon';
import { CaretDownFill } from 'react-bootstrap-icons';

const Styles = styled.div(({ noCaret }) => ({
  '.dropdown-toggle': {
    '&::after': {
      display: 'none',
    },
  },
}));

const DropdownContainer = styled.div({
  ...theme.font.base,
  ...theme.typography.body3,
  cursor: 'pointer',
});

const DropdownMenuContent = styled.div({
  maxHeight: theme.spacing[48],
  overflowY: 'auto',
});

const DropdownButton = styled.div({ ...theme.typography.body3 });

export default function BDDDropdown({
  renderToggle,
  noCaret = false,
  options,
  initialSelected,
  onSelected,
  splitButton,
  size,
  disabled,
  optionDividerIndexes,
}) {
  if (!options || options.length == 0) {
    return null;
  }

  const selected = initialSelected ?? options[0];

  const handleSelection = (option) => {
    onSelected(option);
  };

  return (
    <Styles noCaret={noCaret}>
      <Dropdown
        as={splitButton ? ButtonGroup : DropdownContainer}
        size={size || 'md'}
      >
        {splitButton ? (
          <>
            {splitButton}
            <Dropdown.Toggle split variant="outline-dark" disabled={disabled}>
              <Icon icon={<CaretDownFill />} />
            </Dropdown.Toggle>
          </>
        ) : (
          <Dropdown.Toggle
            noCaret={true}
            as={DropdownButton}
            disabled={disabled}
            className="dropdown-toggle"
          >
            <Row gap={4}>
              <Container>
                {!!renderToggle ? renderToggle(selected) : selected.label}
              </Container>
              {!noCaret && <Icon icon={<CaretDownFill />} />}
            </Row>
          </Dropdown.Toggle>
        )}
        <Dropdown.Menu>
          <DropdownMenuContent>
            {options.map((option, index) => (
              <div key={`dropdown-${index}`}>
                <Dropdown.Item onClick={() => handleSelection(option)}>
                  <DropdownButton>{option.label}</DropdownButton>
                </Dropdown.Item>
                {optionDividerIndexes?.includes(index) && <Dropdown.Divider />}
              </div>
            ))}
          </DropdownMenuContent>
        </Dropdown.Menu>
      </Dropdown>
    </Styles>
  );
};
