import { roundToX, strContains } from './data';

export function numberToOrdinal(arg) {
  var i = arg;
  var j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return i + 'st';
  }
  if (j == 2 && k != 12) {
    return i + 'nd';
  }
  if (j == 3 && k != 13) {
    return i + 'rd';
  }
  return i + 'th';
}

const defaultDateOptions = {
  format: '%m/%d/%Y',
};
export function formatDate(d, options = {}) {
  /*
    Example: const date = formatDate(t.date, {format: '%m/%d %H:%M %ampm'})
    if time (H:M:S) specified in format, default to parsing timezone
    Key:
        %Y -- year (2020)
        %m -- month (08)
        %b -- month short (Aug)
        %B -- month long (August)
        %d -- day (09)
        %n -- day (9)
        %W -- weekday long (Thursday)
        %w -- weekday short (Thur)
        %H -- hours (10)
        %M -- minutes
        %S -- seconds
        %ampm -- day period (am)
    */
  var dateReg = /^\d{2,4}([./-])\d{2}\1\d{2,4}$/;

  if ('useTimeZone' in options) {
    var tZ = { useTimeZone: options.useTimeZone };
  } else if (typeof d === 'string' && !!d.match(dateReg)) {
    // If we've passed in a string that's a date (e.g. "2020-01-01" or "01-01-2020")
    // then don't useTimeZone (cause it'll change the day)
    var tZ = { useTimeZone: false };
  } else {
    var tZ = { useTimeZone: true };
  }
  options = { ...defaultDateOptions, ...tZ, ...options };
  const format = options.format;

  if (!d) {
    return null;
  }
  var date = d;
  if (!(date instanceof Date)) {
    // Check if we want to parse as UTC
    if (
      options.useTimeZone && // if we want to apply timezone
      typeof date === 'string' &&
      !date.endsWith('Z') && // and we don't already have a "Z"
      date.match(/^\d{4}[\/\-](0?[1-9]|[12][0-9]|3[01])[\/\-](\d{2})T.*$/)
    ) {
      var tdate = date + 'Z';
      tdate = new Date(tdate);
      if (!isNaN(tdate)) date = tdate;
    } else {
      date = new Date(date);
    }
  }

  const yearFmt = strContains(format, '%y', false) ? '2-digit' : 'numeric';
  const monthFmt = strContains(format, '%m', false)
    ? '2-digit'
    : strContains(format, '%c', false)
    ? 'numeric'
    : strContains(format, '%b', false)
    ? 'short'
    : strContains(format, '%B', false)
    ? 'long'
    : null;
  const dayFmt = strContains(format, '%n', false) ? 'numeric' : '2-digit';
  const weekdayFmt = strContains(format, '%W', false) ? 'long' : 'short';
  var dateTimeConfig = {
    year: yearFmt,
    month: monthFmt || 'numeric',
    day: dayFmt,
    weekday: weekdayFmt,
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true,
  };
  if (!options.useTimeZone) {
    dateTimeConfig.timeZone = 'UTC';
  }
  const dateTimeFormat = new Intl.DateTimeFormat('en', dateTimeConfig);
  const [
    { value: weekday },
    ,
    { value: month },
    ,
    { value: day },
    ,
    { value: year },
    ,
    { value: hour },
    ,
    { value: minute },
    ,
    { value: second },
    ,
    { value: dayPeriod },
  ] = dateTimeFormat.formatToParts(date);
  return format
    .replace('%m', month)
    .replace('%c', month)
    .replace('%b', month)
    .replace('%B', month)
    .replace('%d', day)
    .replace('%n', day)
    .replace('%W', weekday)
    .replace('%w', weekday)
    .replace('%Y', year)
    .replace('%y', year)
    .replace('%H', hour)
    .replace('%M', minute)
    .replace('%S', second)
    .replace('%ampm', dayPeriod);
}

export function formatDateGQL(date) {
  return formatDate(date, { format: '%Y-%m-%d' });
}

export function feetToMiles(feet) {
  return feet / 5280;
}

export function formatSeconds(seconds) {
  var s = (Math.round(seconds) % 60).toString();
  s = s.length === 1 ? '0' + s : s;
  var m = Math.floor(seconds / 60);
  return `${m}:${s}`;
}
export function formatMinutes(minutes, showMs = false) {
  const isNegative = minutes < 0;
  minutes = Math.abs(minutes);
  let seconds = minutes * 60;
  if (showMs) {
    var s = roundToX(seconds % 60, 2).toString();
  } else {
    s = (Math.floor(seconds) % 60).toString();
  }
  s = s.length === 1 ? '0' + s : s;
  var m = Math.floor(seconds / 60);
  return `${isNegative ? '-' : ''}${m}:${s}`;
}

export function formatGameTime(seconds, showMs = false) {
  var absSeconds = Math.max(seconds, 0);
  var periodNum = Math.floor(absSeconds / 60 / 20) + 1;
  var period = periodNum <= 3 ? `P${periodNum}` : 'OT';
  if (period === 'OT' && periodNum > 4) period += periodNum - 3;
  var minutesInPeriod = absSeconds / 60 - (periodNum - 1) * 20;
  var time = formatMinutes(minutesInPeriod, showMs);
  return `${period} ${time}`;
}

export function birthdateToAge(d) {
  if (!d) {
    return null;
  }
  var date = d;
  if (!(date instanceof Date)) {
    date = new Date(date);
  }
  var ageDifMs = Date.now() - date.getTime();
  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export function numberWithCommas(x) {
  if (!x) return null;
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function arrayEquals(a, b, orderIndependent = false) {
  if (orderIndependent) {
    a = a.sort();
    b = b.sort();
  }
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  );
}

export const millisecondsToDays = (m) => parseInt(m / 24 / 60 / 60 / 1000);

export const removeTimeFromDate = (d) => new Date(d.toDateString());

export const getDateDaysBack = (daysBack, fromDate = null, removeTime = true) => {
  if (!fromDate) {
    fromDate = new Date();
  } else {
    fromDate = new Date(fromDate);
  }
  var ret = new Date(fromDate);
  ret.setDate(ret.getDate() - daysBack);
  if (removeTime) {
    ret = removeTimeFromDate(ret);
  }
  return ret;
};
export const getDateDaysForward = (daysForward, fromDate = null) => {
  if (!fromDate) {
    fromDate = new Date();
  } else {
    fromDate = new Date(fromDate);
  }
  const ret = new Date(fromDate);
  ret.setDate(ret.getDate() + daysForward);
  return ret;
};

// if removeTime=true will remove time, (set to 00:00)
export const getYesterday = (removeTime = true) => getDateDaysBack(1, null, removeTime);

export const getMinutesBetweenDates = (date1, date2) => {
  // Take the difference between the dates and divide by milliseconds per day.
  // Round to nearest whole number to deal with DST.
  return Math.abs(Math.round((new Date(date1) - new Date(date2)) / (1000 * 60)));
};

export const getDaysBetweenDates = (date1, date2) => {
  // Take the difference between the dates and divide by milliseconds per day.
  return Math.abs((new Date(date1) - new Date(date2)) / (1000 * 60 * 60 * 24));
};

export const parseDateNoTimezone = (date) => {
  // takes  date without time and parses, removing the auto offset
  // input: '2020-2-10' or other format
  date = new Date(date);
  return new Date(date.getTime() + Math.abs(date.getTimezoneOffset() * 60000));
};

export const parseUTCDate = (date) => {
  date = new Date(date);
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
};

export const dateToTimeAgo = (date) => {
  const now = parseDateNoTimezone(new Date());
  const parsedDate = new Date(date);
  const minutes = getMinutesBetweenDates(now, parsedDate);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(minutes / 60 / 24);
  const years = Math.floor(minutes / 60 / 24 / 365);
  if (minutes < 1) {
    return 'Just now';
  } else if (minutes < 59.5) {
    // minutes ago
    return `${Math.floor(minutes)} min ago`;
  } else if (hours < 24) {
    // hours ago
    return `${hours} hr${hours > 1 ? 's' : ''} ago`;
  } else if (days < 31) {
    // days ago
    return `${days} day${days > 1 ? 's' : ''} ago`;
  } else {
    return formatDate(date);
  }
};

export const addMinutes = (date, minutes) => {
  return new Date(date.getTime() + minutes * 60000);
};

export const addDays = (date, days) => {
  const newDate = new Date(date.valueOf());
  newDate.setDate(newDate.getDate() + days);

  return newDate;
};

export const addMonths = (date, months) => {
  const newDate = new Date(date.valueOf());
  newDate.setMonth(newDate.getMonth() + months);

  return newDate;
};

export const getStartDateOfMonth = (date) =>
  new Date(date.getFullYear(), date.getMonth(), 1);

export const filterUnique = (list, properties) =>
  list.filter(
    (obj, index, self) =>
      index === self.findIndex((t) => properties.every((key) => obj[key] === t[key]))
  );

// Firstname Lastname -> F. Lastname
export const initializeName = (name) => {
  if (!name) return null;
  const [firstName, lastName] = name.split(' ');
  return `${firstName[0]}. ${lastName}`;
};
