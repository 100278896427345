import { Box } from '@udecode/cn';
import { Text } from '../Text';

export const BDDInput = ({ flatRight, flatLeft, isInvalid, ...props }) => {
  const borderRadius = {
    borderTopLeftRadius: flatLeft ? '0' : '5px',
    borderTopRightRadius: flatRight ? '0' : '5px',
    borderBottomRightRadius: flatRight ? '0' : '5px',
    borderBottomLeftRadius: flatLeft ? '0' : '5px',
  };
  const invalidClasses = isInvalid ? 'shadow-red-500 border-red-500' : 'border-gray-400';

  return (
    <Text variant="body" asChild>
      <Box className={`w-full border p-1 ${invalidClasses}`} asChild>
        <input style={{ ...borderRadius }} {...props} />
      </Box>
    </Text>
  );
};
