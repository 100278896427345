import React, { useState } from 'react'
import { useMutation } from '@apollo/client';

import { SectionHeader, SubSectionHeader } from 'components/reports'
import { useQueryWithPlaceholder } from 'apollo/hooks';
import PlayerListController from '../PlayerLists/playerlistcontroller'
import { GET_PLAYER_LISTS } from 'apollo/queries/playerlist.queries'
import styled from 'styled-components';
import { PLAYER_LIST_TYPES } from 'constants';
import BDDSelect from 'components/bdd/bddselect';
import { UPDATE_BDD_DEPTH_CHART } from 'apollo/queries/depthchart.queries';
import { IconButton } from 'components/bdd/Button';
import { PersonPlusFill } from 'react-bootstrap-icons';
import { HoverInteractive } from 'components/bdd';

const DepthChartListContainer = styled.div`
  font-size: 0.7em;
`

// Uncomment if we work on adding players directly to the DC
// const DCSpotContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// `
// const DCSpot = styled.div`
//   width: 40px;
//   height: 20px;
//   border-radius: 5px;
//   background-color: #FFF;
//   border: 1px solid #CCC;
//   cursor: pointer;
//   &:hover {
//     background-color: #CCC;
//   }
// `

export default function DepthChartLists({ depthChartId }) {
  const [selected, setSelected] = useState(null);

  // const [updateBddDepthChart, { loading: mutationLoading }] = useMutation(UPDATE_BDD_DEPTH_CHART)
  // const handleAddPlayerToDC = (depthLeague, position, lineNum, playerSlug) => {
  //   updateBddDepthChart({ 
  //     variables: {
  //         input: { id: depthChartId },
  //         lineupSpotsToSet: [{ depthLeague, position, lineNum, playerSlug, adjustMode? }]
  //     }
  //   })
  // }

  const { data, placeholder } = useQueryWithPlaceholder(
    GET_PLAYER_LISTS,
    {
      listTypes: [PLAYER_LIST_TYPES.PRO_LIST],
      includeArchived: false,
      pinsOnly: false
    }
  );


  if (!data) return placeholder

  const options = data.playerLists.map(list => ({
    value: list.id,
    label: <>{list.name} <em>{list.user.username}</em></>
  }))

  const selectedList = data.playerLists.find(list => list.id === selected)

  return <DepthChartListContainer>
  <center><SectionHeader>Pro Lists</SectionHeader></center>
  <BDDSelect
    name='selected'
    value={selected}
    options={options}
    onChange={(n,v) => setSelected(v)}
  />
  {!!selected && <div>
    <SubSectionHeader>{selectedList.name}</SubSectionHeader>
    <PlayerListController 
        listId={selected}
        playerSize='sm'
        renderPlayer={(player, list) => {
            return <DCListPlayer player={player}/>
        }}
        showTitle={false}
        showDescription={false}
        showEditButton={false}
        showMenu={false}
        showAddPlayers={true}
        showCreatedBy={false}
        showChangeStatus={false}
        defaultEditMode={true}
        disableDragLayer={true} // must disable drag layer because viewing multiple lists at once
    />
  </div>}
  </DepthChartListContainer>
}

const DCListPlayer = ({ player }) => {
  return <div>
    {player.firstname} {player.lastname} 
    {/* If we maybe wanted to build an interface to add players directly to the DC
    {' '}
    <HoverInteractive content={<>
      <DCSpotContainer>
        {['NHL', 'AHL', 'Prospect'].map(depthLeague => 
          [1, 2, 3, 4, 5].map...
        )}<DCSpot/>
      </DCSpotContainer>
    </>}>
      <PersonPlusFill/>
    </HoverInteractive> */}
  </div>
}