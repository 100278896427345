import { Column, Container, Row } from 'components/bdd/Layout';
import { DraftPlayerChecks } from 'components/Lists/Builder/components/DraftPlayerChecks';
import { getCurrentSeason } from 'helpers/hockeyutils';
import { AnalyticModelScores } from '../AnalyticModelScores';
import { useModal } from 'components/bdd/Modal';
import { LinkButton } from 'components/bdd/Button';
import { Typography } from 'components/bdd/Typography';
import { useUser } from 'helpers/user';
import { PlayerScoutingGradesRows } from './PlayerScoutingGrades';
import Icon from 'components/bdd/Icon';
import { People } from 'react-bootstrap-icons';
import { DraftSimilarPlayers } from 'components/DraftApp/DraftSimilarPlayers';

export const SimilarScoutingPlayersLinkButton = ({
  name,
  rinknetId,
  draftYear,
  text = 'Show Similar Players',
}) => {
  const {
    renderModal,
    showModal: showSimilarPlayers,
    setShowModal: setShowSimilarPlayers,
  } = useModal({
    title: <Typography variant="h4">Similar Players for {name}</Typography>,
    size: 'xxl',
  });
  return (
    <Container>
      <LinkButton
        onClick={(ev) => {
          ev.stopPropagation();
          setShowSimilarPlayers(true);
        }}
      >
        {text}
      </LinkButton>
      {showSimilarPlayers &&
        renderModal(
          <DraftSimilarPlayers rinknetId={rinknetId} draftYear={draftYear} />
        )}
    </Container>
  );
};

export const SkillsAndModels = ({ bddPlayer, limitScoutViews = true }) => {
  const rp = bddPlayer.rinknetPlayer;
  const isDraftEligible = !!rp?.isdrafteligible;
  const draftYear = !!rp?.draftyear
    ? rp.draftyear
    : parseInt(getCurrentSeason().toString().slice(4));

  return (
    <Column gap={1}>
      {isDraftEligible ? (
        <PlayerScoutingGradesRows
          rinknetId={bddPlayer.rinknetId}
          limitScoutViews={limitScoutViews}
        />
      ) : (
        <PlayerScoutingGradesRows
          rinknetId={bddPlayer.rinknetId}
          filterSplits={(s) => !s.isDraftEligible}
          limitScoutViews={limitScoutViews}
        />
      )}
      <Container>
        {!isDraftEligible && (
          <Typography variant="caption">
            From {draftYear} draft year:
          </Typography>
        )}
        <Column gap={1}>
          <Container paddingLeft={4}>
            {!isDraftEligible && (
              <PlayerScoutingGradesRows
                rinknetId={bddPlayer.rinknetId}
                filterSplits={(s) => !!s.isDraftEligible}
                limitScoutViews={limitScoutViews}
              />
            )}
            <Row gap={1} flexWrap height="auto">
              <AnalyticModelScores
                bddPlayer={bddPlayer}
                rinknetId={bddPlayer.rinknetId}
              />
              <SimilarScoutingPlayersLinkButton
                name={`${bddPlayer.firstname} ${bddPlayer.lastname}`}
                rinknetId={bddPlayer.rinknetId}
                draftYear={draftYear}
                text={
                  <Row gap={2}>
                    <Icon icon={<People />} />
                    <Typography>Similar Players</Typography>
                  </Row>
                }
              />
            </Row>
            <Container paddingLeft={8}>
              <DraftPlayerChecks
                rinknetId={bddPlayer.rinknetId}
                rinknetIds={[bddPlayer.rinknetId]}
                draftYear={draftYear}
                pos={bddPlayer.posFDG}
              />
            </Container>
          </Container>
        </Column>
      </Container>
    </Column>
  );
};
