import React, { useState, useEffect } from 'react';
import Dropdown from './BDDDropdown';

const useDropdown = ({
  renderToggle,
  noCaret=false,
  options,
  initialSelectedValue,
  handleSelected,
  firstOptionLabel,
  splitButton=false,
  disabled=false,
  optionDividerIndexes
}) => {
  const [selectedValue, setSelectedValue] = useState(initialSelectedValue);

  useEffect(() => {
    setSelectedValue(initialSelectedValue);
  }, [initialSelectedValue]);

  if (!options || options.length == 0) {
    return {
      selected: null,
      dropdown: null,
    };
  }

  const selectedOption = selectedValue
    ? options.find((option) => option.value == selectedValue)
    : options[0];

  return {
    selected: selectedOption,
    dropdown: (
      <Dropdown
        noCaret={noCaret}
        renderToggle={renderToggle}
        initialSelected={selectedOption}
        options={firstOptionLabel ? options.slice(1) : options}
        onSelected={(option) => {
          handleSelected && handleSelected(option);
          setSelectedValue(firstOptionLabel ? options[0].value : option.value);
        }}
        splitButton={splitButton}
        disabled={disabled}
        optionDividerIndexes={optionDividerIndexes}
      />
    ),
  };
};

export default useDropdown;
