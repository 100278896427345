import { theme } from 'constants';
import BDDErrorBoundary from '../bdderrorboundary';
import { usePageStore } from './Page';
import { cn } from '@udecode/cn';

export const PageBody = ({
  children,
  backgroundColor = theme.colors.light.secondary,
  padding = 2,
  className,
}) => {
  const shortKeyExists = usePageStore((state) => state.shortKeyExists);

  return (
    <BDDErrorBoundary>
      <div
        className={cn('h-full tw-overflow-auto', className)}
        style={{
          backgroundColor,
          padding: padding ? theme.spacing[padding] : undefined,
        }}
      >
        {!shortKeyExists && children}
      </div>
    </BDDErrorBoundary>
  );
};
