import * as React from 'react';

import { Slot } from '@radix-ui/react-slot';
import { cn, withRef } from '@udecode/cn';
import { cva } from 'class-variance-authority';

export const textVariants = cva('', {
  defaultVariants: {
    variant: 'body',
    weight: 'default',
  },
  variants: {
    align: {
      start: 'text-start',
      end: 'text-end',
      center: 'text-center',
    },
    weight: {
      thin: 'font-thin',
      default: 'font-normal',
      bold: 'font-medium',
      extraBold: 'font-bold',
    },
    color: {
      DEFAULT: 'text-foreground',
      secondary: 'text-secondary-foreground',
      dark: 'text-dark-foreground',
      destructive: '!text-destructive',
      muted: 'text-muted-foreground',
      accent: 'text-accent-foreground',
    },
    variant: {
      header: 'font-display text-xl',
      title: 'font-display text-lg',
      card: 'font-display text-md',
      subtitle: 'font-display text-sm',
      label: 'font-sans text-xs font-medium',
      body: 'font-sans text-sm',
      caption: 'font-sans text-xs',
    },
  },
});

export const Text = withRef(
  (
    { asChild = false, className, variant, weight, align, color, truncate, ...props },
    ref
  ) => {
    const Comp = asChild ? Slot : 'div';

    return (
      <Comp
        ref={ref}
        className={cn(
          textVariants({ variant, align, weight, color, truncate }),
          truncate && 'truncate',
          className
        )}
        {...props}
      />
    );
  }
);
