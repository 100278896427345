import React, { useContext } from 'react';
import styled from 'styled-components';
import { theme } from 'constants/theme';
import { WindowContext } from 'components/bdd/context';
import { Typography } from '../Typography';
import { Column, Container, Row } from '../Layout';
import BDDErrorBoundary from '../bdderrorboundary';
import { ModalContext } from '../Modal';
import { IconButton } from '../Button';
import { ArrowsFullscreen, Fullscreen } from 'react-bootstrap-icons';

const CardContainer = styled.div(({ isMobile, variant, overflow, padding }) => ({
  ...(isMobile ? theme.cards.mobile : theme.cards.medium),
  // some cards need overflow='hidden' so nested scrollables work
  overflow: overflow || 'visible',
  padding: padding || isMobile ? theme.spacing[2] : theme.spacing[3],
  backgroundColor: theme.colors.light.background,
  ...(isMobile && { width: '100%' }),
  height: '100%',
  ...(variant == 'button'
    ? {
        cursor: 'pointer',
        userSelect: 'none',
      }
    : {}),
  '&:hover': {
    ...(variant == 'button'
      ? {
          transform: 'scale(1.01)',
          transition: 'all .1s ease-in-out',
        }
      : {}),
  },
}));

export const Card = ({
  topStripe, // above the header
  header,
  caption,
  children,
  errorBoundary = true,
  errorContent,
  actionContent,
  overflow,
  includeExpand,
  renderAsMobile,
  ...styles
}) => {
  const { width } = useContext(WindowContext);
  const { renderModal } = useContext(ModalContext);
  const isMobile = renderAsMobile || width <= theme.breakpoints.xs;

  const renderChildren =
    typeof children === 'function' ? children({ expanded: false }) : children;

  // Note we have two error boundaries
  // If theres an error in the content we'd still like to render the header
  const cardContent = (
    <>
      {!!topStripe && <Container>{topStripe}</Container>}
      <Typography variant="subtitle1">
        <Row
          justifyContent="space-between"
          alignItems="start"
          columnGap={1}
          rowGap={1}
          flexWrap
        >
          {header}
          <Row style={{ maxWidth: '100%' }}>
            {includeExpand && (
              <IconButton
                icon={<ArrowsFullscreen />}
                hoverColor={theme.colors.teams.bos.primary}
                onClick={() =>
                  renderModal({
                    size: 'xl',
                    title: <Typography variant="h6">{header}</Typography>,
                    body: (
                      <Container padding={3}>
                        <Column>
                          {typeof children === 'function'
                            ? children({ expanded: true })
                            : children}
                        </Column>
                      </Container>
                    ),
                  })
                }
              />
            )}
            {actionContent}
          </Row>
        </Row>
      </Typography>
      <Typography variant="body2">{caption}</Typography>
      {errorBoundary ? (
        <BDDErrorBoundary errorContent={errorContent}>{renderChildren}</BDDErrorBoundary>
      ) : (
        renderChildren
      )}
    </>
  );

  return (
    <CardContainer {...styles} overflow={overflow} isMobile={isMobile}>
      {errorBoundary ? (
        <BDDErrorBoundary errorContent={errorContent}>{cardContent}</BDDErrorBoundary>
      ) : (
        cardContent
      )}
    </CardContainer>
  );
};
