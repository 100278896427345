import { useQuery } from '@apollo/client';
import { CombineNotes } from 'components/Lists/components/CombineNotes';
import CareerStats from 'components/PlayersV2/CareerStats';
import ColinQuestionnaire from 'components/Questionnaire/ColinQuestionnaire';
import SkatingEval from 'components/Questionnaire/SkatingEval';
import { theme } from 'constants';
import React, { useEffect } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { BDDTabLink, BDDTabLinkContainer } from '../bdd';
import BDDApiError from '../bdd/bddapierror';
import { BDDLoader } from '../bdd/bddloader';
import { SectionHeader } from '../reports';
import { GET_RINKNET_PLAYER } from '../Scouting/playerreportform';
import PlayerReportViewer from '../Scouting/PlayerReports/playerreportviewer';
import DraftSLPlayerData from './draftslplayerdata';

const parseHash = (hash) => {
  const hashbase = hash.split('&')[0];
  const rinknetId = hashbase.split('#player=')[1];
  try {
    var focus = hash.split('focus=')[1].split('&')[0];
  } catch {
    var focus = undefined;
  }
  return {
    hashbase,
    rinknetId,
    focus,
  };
};

const Styles = styled.div`
  .bddmodal-header {
    padding: 10px 20px;
    text-align: left;
    border-bottom: 1px solid #aaa;
  }

  .bio-info {
    font-size: 0.8em;
  }
`;

const CareerStatsContainer = styled.div({
  margin: theme.spacing[1],
});

export default function DraftPlayerModal({
  show,
  handleClose,
  playerToEP,
  playerToCombineNotes,
}) {
  const history = useHistory();
  const { pathname, hash, search } = useLocation();
  const { rinknetId, hashbase, focus } = parseHash(hash);
  const { data, loading, error } = useQuery(GET_RINKNET_PLAYER, {
    variables: { rinknetId },
  });

  useEffect(() => {
    if (!focus) {
      // history.push(`${pathname}${hashbase}&focus=bddreports`)
      history.push(`${pathname}${hashbase}&focus=scoutingreports`);
    }
  }, [focus]);

  const rp = data ? data.rinknetPlayer : {};

  return (
    <Modal size="lg" show={show} onHide={handleClose}>
      <Styles>
        {loading ? (
          <div style={{ padding: '40px' }}>
            <BDDLoader />
          </div>
        ) : error ? (
          <BDDApiError error={error} />
        ) : (
          <>
            <div className="bddmodal-header">
              <SectionHeader style={{ textAlign: 'left' }}>
                {rp.firstname} {rp.lastname}
              </SectionHeader>
              <div className="bio-info">
                <Row>
                  <Col>
                    {rp.currentteam} | {rp.currentleagueabbr}
                  </Col>
                  <Col>
                    {rp.heightStr} | {rp.weight}
                  </Col>
                  <Col>{rp.birthdate}</Col>
                </Row>
              </div>
            </div>

            <Modal.Body>
              <BDDTabLinkContainer leftAlign>
                <BDDTabLink
                  useHash
                  matchSearchStart
                  to={`${search}${hashbase}&focus=scoutingreports`}
                >
                  Scouting Reports
                </BDDTabLink>
                <BDDTabLink
                  useHash
                  matchSearchStart
                  to={`${search}${hashbase}&focus=eliteprospects`}
                >
                  Eliteprospects
                </BDDTabLink>
                {/* <BDDTabLink
                    useHash
                    matchSearchStart
                    to={`${search}${hashbase}&focus=sportlogiq`}
                >
                    Sportlogiq
                </BDDTabLink> */}
                <BDDTabLink
                  useHash
                  matchSearchStart
                  to={`${search}${hashbase}&focus=questionnaire`}
                >
                  Questionnaire
                </BDDTabLink>
                <BDDTabLink
                  useHash
                  matchSearchStart
                  to={`${search}${hashbase}&focus=combinenotes`}
                >
                  Combine Notes
                </BDDTabLink>
                <BDDTabLink
                  useHash
                  matchSearchStart
                  to={`${search}${hashbase}&focus=skatingeval`}
                >
                  Skating Eval
                </BDDTabLink>
              </BDDTabLinkContainer>

              {/* {focus === 'bddreports' ? <> 
                <PlayerBDDReports rinknetId={rinknetId} />
            </> : focus === 'rinknetreports' ? <>
                <PlayerRinknetReports rinknetId={rinknetId} /> 
            </> */}
              {focus === 'scoutingreports' ? (
                <>
                  <PlayerReportViewer rinknetId={rinknetId} maxHeight="300px" />
                </>
              ) : focus === 'eliteprospects' ? (
                <>
                  <CareerStatsContainer>
                    <CareerStats epId={rp.eliteprospectsid} />
                  </CareerStatsContainer>
                </>
              ) : focus === 'sportlogiq' ? (
                <>
                  <DraftSLPlayerData rinknetId={rinknetId} />
                </>
              ) : focus === 'questionnaire' ? (
                <>
                  <ColinQuestionnaire rinknetId={rp.id} />
                </>
              ) : focus === 'combinenotes' ? (
                <>
                  <CombineNotes
                    rinknetId={rinknetId}
                    playerToCombineNotes={playerToCombineNotes}
                  />
                </>
              ) : focus === 'skatingeval' ? (
                <>
                  <SkatingEval rinknetId={rinknetId} />
                </>
              ) : null}
            </Modal.Body>
          </>
        )}
      </Styles>
    </Modal>
  );
}
