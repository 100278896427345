import React, { useState } from 'react'
import styled from 'styled-components'

const DCPlayerStyles = styled.div({

  fontSize: `min(${ props => props.fontSize}, 3.0vw)`,
  textAlign: 'center',
  transition: 'all 0.2s ease -in -out',
  borderRadius: '2px',
  boxShadow: props => !!props.highlight 
    ? `inset 0 0 3px ${props.highlight}, 0 0 3px ${props.highlight}` 
    : undefined,
  border: props => props.isDashed
    ? `1px dashed ${props.highlight}`
    : '1px solid #ddd',
  '.bdd-dropdown-item': {
    fontSize: '0.6em',
    '&:hover': {
      background: 'lightblue'
    }
  }
})


// This component exists to collapse logic that is the same between small and large DCPlayer
export const DCPlayerWrapper = ({ highlightColor, playerSlug, colSize, children }) => {
  const fontSize = colSize < 12 ? '0.6em' : '0.8em'

  return <>
    <DCPlayerStyles
      fontSize={fontSize}
      highlight={highlightColor}
    >
      {children}
    </DCPlayerStyles>
  </>
}
