import React from 'react';
import styled from 'styled-components';

import { theme } from 'constants';
import { TooltipSpan } from 'components/reports';
import { Switch } from './Switch';
import { Flex } from '../Layout';
import { Label } from '../Label/ControlLabel';

export const Toggle = ({
  id,
  label,
  labelToggled,
  toggled,
  setToggled,
  disabled,
  field,
  tooltip,
}) => {
  // Injected by Formik
  const { value, onChange, name } = field || {};
  const labelComponent = (
    <Label htmlFor={id}>{toggled && labelToggled ? labelToggled : label}</Label>
  );

  return (
    <Flex gap={1}>
      <Switch
        id={id}
        name={name}
        checked={value || toggled || false}
        disabled={disabled}
        onCheckedChange={onChange || (() => setToggled && setToggled(!toggled))}
      />
      {tooltip ? <TooltipSpan content={tooltip}>{labelComponent}</TooltipSpan> : labelComponent}
    </Flex>
    // <ToggleStyles>
    //   <Form.Check
    //     type="switch"
    //     id={id || `toggle-${label}`}
    //     disabled={disabled}
    //   >
    //     <Form.Check.Input
    //       name={name}
    //       type="checkbox"
    //       checked={value || toggled || false}
    //       onChange={onChange || (() => setToggled && setToggled(!toggled))}
    //       disabled={disabled}
    //     />
    //     <Form.Check.Label>
    //       <TooltipSpan content={tooltip}>
    //         <Label>{!toggled ? label : labelToggled ?? label}</Label>
    //       </TooltipSpan>
    //     </Form.Check.Label>
    //   </Form.Check>
    // </ToggleStyles>
  );
};

export default Toggle;
