import { useQuery } from "@apollo/client";
import { GET_BF_TEAM_SEASON_SUMMARY } from "apollo/queries/bruinsfriendly.queries";
import { GET_PRO_OBJECTIVE_SCENARIOS } from "apollo/queries/proobjective.queries";
import usePlaceholder from "components/Placeholder/usePlaceholder";


export const useBFOrScenarioTeamSeasonSummary = ({
  teamSlug,
  scenarioUniqueKey,
  cacheOnly = false,
}) => {
  const { data, placeholder, loading, error } = usePlaceholder(
    useQuery(GET_BF_TEAM_SEASON_SUMMARY, {
      variables: { slTeamSlug: teamSlug },
      skip: !!scenarioUniqueKey,
    })
  );
  const {
    data: scenarioData,
    placeholder: scenarioPlaceholder,
    loading: scenarioLoading,
  } = usePlaceholder(
    useQuery(GET_PRO_OBJECTIVE_SCENARIOS, {
      variables: {
        uniqueKey: scenarioUniqueKey,
        includeCapSheet: true,
        includeChecks: false,
        includeTeamStrength: false,
        cacheOnly,
      },
      skip: !scenarioUniqueKey,
    })
  );

  const teamData = !!scenarioUniqueKey
    ? scenarioData?.proObjectiveScenarios.find((s) => s.teamSlug === teamSlug)
        ?.capSheet?.teamSummary
    : data?.bfTeamSeasonSummary;

  return {
    teamData,
    placeholder: !!scenarioUniqueKey ? scenarioPlaceholder : placeholder,
    loading: !!scenarioUniqueKey ? scenarioLoading : loading,
  };
};