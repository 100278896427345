import { useState } from 'react';
import useDebounce from '../hooks/usedebounce';
import { useFilterContext } from './context';
import { Field } from './Field';
import TextareaAutosize from 'react-textarea-autosize'

export const TextareaFilter = ({ id, label, cond = '=', onChange, meta, className }) => {
  const filter = useFilterContext((state) => state.filters[id]);
  const { setFilters, removeFilter } = useFilterContext((state) => state.actions);

  const [tempValue, setTempValue] = useState(filter?.value);
  useDebounce(tempValue, 400, (value) => {
    if (filter?.value !== value) {
      setFilters(id, { id, type: 'select', cond, value: value, meta });
      onChange && onChange(value, { setFilters, removeFilter });
    }
  });

  return (
    <Field width="100%" label={label} className={className}>
      <TextareaAutosize
        className="font-sans text-sm border rounded-md p-1 min-h-12 w-full"
        // style={{ fieldSizing: 'content' }}
        value={tempValue}
        onChange={(e) => setTempValue(e.target.value)}
      />
    </Field>
  );
};
