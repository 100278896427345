import { IconButton } from 'components/bdd/Button';
import { At, FileEarmarkPlay } from 'react-bootstrap-icons';
import { useSlate } from 'slate-react';
import { ToolbarIconButton } from '../..';
import { insertPlaylist, isPlaylistActive, unwrapPlaylist } from './helpers';

export const PlaylistToolbarButton = ({}) => {
  const editor = useSlate();
  return (
    <ToolbarIconButton
      icon={<FileEarmarkPlay />}
      active={isPlaylistActive(editor)}
      onMouseDown={(event) => {
        event.preventDefault();
        if (isPlaylistActive(editor)) {
          unwrapPlaylist(editor);
        } else {
          insertPlaylist(editor);
        }
      }}
    />
  );
};
