import { StyledBDDSelect } from 'components/bdd/Select/StyledBDDSelect';
import { useFilterContext } from './context';
import { useRegisterFilterTag } from './Group';
import { FilterTag } from '.';
import { Field } from './Field';
import { Typography } from '../Typography';

export const InputFilter = ({
  id,
  label,
  size = 'sm',
  width,
  selectProps,
  onChange,
  ...fieldProps
}) => {
  const filter = useFilterContext((state) => state.filters[id]);
  const { setFilters, removeFilter } = useFilterContext((state) => state.actions);

  useRegisterFilterTag(id, filter && <FilterTag name={label} label={filter?.value} />);

  return (
    <Field id={id} onChange={onChange} label={label} {...fieldProps}>
      <Typography variant="label">
        <input
          className="border-1 rounded-sm p-2"
          style={{ width }}
          value={filter?.value}
          onChange={(e) => {
            setFilters(id, { id, type: 'input', cond: '=', value: e.target.value });
            onChange && onChange(e.target.value);
          }}
        />
      </Typography>
    </Field>
  );
};
