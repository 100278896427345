import { useQuery } from "@apollo/client";
import {
  GET_ALL_REPORTS_FOR_PLAYER,
  GET_SCOUTING_RANKINGS_FOR_PLAYER_BY_SCOUT,
} from 'apollo/queries/scouting.queries';
import { Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import usePlaceholder from 'components/Placeholder/usePlaceholder';
import BDDReport from 'components/Scouting/PlayerReports/bddreport';
import {
  getMetastatsForReport,
  getReportScoutId,
  getScoutId,
  getScoutName,
  reportToDate,
} from 'components/Scouting/PlayerReports/helpers';
import RinknetReport from 'components/Scouting/PlayerReports/rinknetreport';
import { theme } from 'constants';
import { formatDate } from 'helpers/helpers';
import { useState } from 'react';
import { useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { Eye } from 'react-bootstrap-icons';
import styled from 'styled-components';
import { ScoutingGradeBoxes } from './ScoutingGrades';

const ScoutCard = styled.div({
  ...theme.cards.medium,
  ...theme.typography.body2,
  border: '1px solid #ddd',
  padding: '5px',

  width: '30%',
  minWidth: '200px',
  height: '300px',
  overflow: 'hidden',
  '&:focus': {
    overflow: 'auto',
    borderColor: 'green',
  },
});

const CardSubHeader = styled.div({
  ...theme.typography.body2,
  fontSize: '10pt',
  textDecoration: 'underline',
  marginTop: '5px',
});

export default function ReportsByScout({ rinknetId, scoutId: scoutIdArg }) {
  const [showMore, setShowMore] = useState(false);
  const { data, placeholder } = usePlaceholder(
    useQuery(GET_ALL_REPORTS_FOR_PLAYER, {
      variables: { rinknetId, scoutIdArg },
      skip: !rinknetId,
    })
  );

  const { data: rankingsData, loading: rankingsLoading } = useQuery(
    GET_SCOUTING_RANKINGS_FOR_PLAYER_BY_SCOUT,
    {
      variables: {
        rinknetId,
      },
    }
  );

  const metastats = data?.allPlayerReports?.metastatSplits;
  const scoutsAndReports = useMemo(() => {
    // returns [{ scout, reports }] sorted by date
    if (!data) return {};
    return data.allPlayerReports.reports
      .reduce((acc, curr) => {
        // assumes data comes in sorted by date
        const existing = acc.find(
          (a) => getScoutId(a.scout) == getReportScoutId(curr)
        );
        if (!!existing) {
          existing.reports.push(curr);
        } else {
          acc.push({
            scout: curr.rinknetUser || curr.user,
            reports: [curr],
          });
        }
        return acc;
      }, [])
      .sort((a, b) => b.reports.length - a.reports.length);
  }, [data]);

  const rp = rankingsData?.scoutingRankingsForPlayerByScout?.rinknetPlayer;
  const posFDG = rp?.posFDG?.toLowerCase();

  const scoutToRankings = useMemo(() => {
    if (!rankingsData) return {};
    const isDraftEligible = rp.isdrafteligible ? true : null;
    return rankingsData.scoutingRankingsForPlayerByScout.rankings
      .filter(
        (r) =>
          r.split.isDraftEligible == isDraftEligible &&
          r.split.position == posFDG.toLowerCase()
      )
      .reduce((acc, curr) => {
        if (!(curr.split.scoutId in acc)) acc[curr.split.scoutId] = [];
        acc[curr.split.scoutId].push(curr);
        return acc;
      }, {});
  }, [rankingsData]);

  if (!data) return placeholder;

  const itemsToShow = showMore
    ? scoutsAndReports
    : scoutsAndReports.slice(0, 6);
  return (
    <Container maxHeight={'670px'} overflow="auto">
      <Row flexWrap="wrap" rowGap={5} columnGap={2}>
        {itemsToShow.map(({ scout, reports }) => {
          return (
            <ScoutCard key={getScoutId(scout)} tabIndex={-1}>
              <Row justifyContent="space-between" height={20}>
                <div>
                  <Typography variant="body1">{getScoutName(scout)}</Typography>
                </div>
                <div>
                  <Eye /> {reports.length}
                </div>
              </Row>
              {rankingsLoading ? (
                <div>Loading...</div>
              ) : !!rankingsData &&
                scoutToRankings?.[scout.id]?.length &&
                scoutToRankings[scout.id].find(
                  (r) => r.rating.slug === 'skating'
                ) ? (
                <div>
                  <div>
                    <CardSubHeader>Overall:</CardSubHeader>
                  </div>
                  <ScoutingGradeBoxes
                    rankings={scoutToRankings[scout.id]}
                    split={scoutToRankings[scout.id][0].split}
                    position={posFDG}
                    shortSplitDesc={true}
                  />
                </div>
              ) : null}
              <div>
                <CardSubHeader>Last viewing:</CardSubHeader>
              </div>
              <Container>
                {reports[0].__typename === 'RinknetReports' ? (
                  <RinknetReport
                    report={reports[0]}
                    metastats={getMetastatsForReport(reports[0], metastats)}
                    showAuthor={false}
                  />
                ) : (
                  <BDDReport
                    report={reports[0]}
                    expandable={false}
                    noBorder={true}
                    metastats={getMetastatsForReport(reports[0], metastats)}
                    showDeleteButton={false}
                    includeHeader={false}
                  />
                )}
              </Container>
            </ScoutCard>
          );
        })}
      </Row>
      {!showMore && (
        <center style={{ marginTop: '10px' }}>
          <Button variant="dark" size="sm" onClick={() => setShowMore(true)}>
            Show More
          </Button>
        </center>
      )}
    </Container>
  );
}