import React, { useEffect, useRef } from 'react';
import { X } from 'react-bootstrap-icons';
import Select, { components, createFilter } from 'react-select';
// import { FixedSizeList as List } from "react-window"
import styled from 'styled-components';
import { BDDErrorColor } from './bddformcomponents';
import { Typography } from './Typography';

const SelectOption = styled.div({
  display: 'inline-flex',
  background: '#e5e5e5',
  borderRadius: '2px',
  margin: '2px',
  boxSizing: 'border-box',
  cursor: 'pointer',
  '&:hover': {
    background: '#eebcac',
    color: '#ff3813',
  },
});
const Label = styled.div({
  fontSize: '85%',
  padding: '3px 3px 3px 3px',
});

const noOverflowMultiValueContainer = ({
  data,
  selectProps: { value, onChange, ...rest },
}) => {
  const label = data.label;
  return (
    <SelectOption
      onClick={(ev) => {
        ev.stopPropagation();
        onChange(value.filter((v) => v.value !== data.value));
      }}
    >
      <Label>{label}</Label>
    </SelectOption>
  );
};

const height = 35;

const Option = (props) => {
  return (
    <components.Option {...props}>
      <div
        style={{
          maxHeight: height,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {props.data.label}
        <em style={{ marginLeft: '5px', fontSize: '0.7em' }}>{props.data.subLabel}</em>
      </div>
      <div>
        <Typography as="em" variant="stat">
          {props.data.subCaption}
        </Typography>
      </div>
    </components.Option>
  );
};

// class MenuList extends React.Component {
//     render() {
//         const { options, children, maxHeight, getValue } = this.props;
//         const [value] = getValue();
//         const initialOffset = options.indexOf(value) * height;
//         return (
//             <List
//                 height={maxHeight || height}
//                 itemCount={children.length || 0}
//                 itemSize={height}
//                 initialScrollOffset={initialOffset}
//             >
//                 {({ index, style }) => {
//                     return <div style={style}>{children[index]}</div>}
//                 }
//             </List>
//         );
//     }
// }

export default function BDDSelect({
  noOverflow,
  isInvalid,
  styles = {},
  noDropdownIndicator = false,
  autoFocus = false,
  ...props
}) {
  if (autoFocus && !!props.selectRef) {
    console.warn('"autoFocus" and "selectRef" supplied--autoFocus will be ignored');
  }

  const innerRef = useRef();
  useEffect(() => {
    if (autoFocus) {
      innerRef.current?.focus();
    }
  }, [innerRef.current]);

  var customStyle = {
    option: (provided, state) => ({
      ...provided,
      // padding: 2,
      // backgroundColor: state.isSelected ? '#AAF' : null
    }),
    valueContainer: (provided, state) => ({
      ...provided,
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
  };
  if (noOverflow) {
    customStyle.valueContainer = (provided, state) => ({
      ...provided,
      // textOverflow: 'ellipsis',
      maxWidth: '100%',
      maxHeight: height,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      display: 'initial',
    });
  }

  if (isInvalid) {
    customStyle['control'] = (provided) => ({
      ...provided,
      boxShadow: `0px 0px 5px ${BDDErrorColor}`,
    });
  }
  customStyle = { ...customStyle, ...styles };

  // Check for plain list of props
  function compareValueOption(val, opt) {
    if (val instanceof Array) {
      return val.indexOf(opt['value']) >= 0;
    } else {
      return val == opt['value'];
    }
  }

  function unnestOptionsAndMatchValues(val) {
    if (!props.options) {
      return null;
    }
    // If we've got a group of nested options
    var ret = [];
    props.options.forEach((opt) => {
      if ('options' in opt) {
        ret = ret.concat(opt.options.filter((o) => compareValueOption(val, o)));
      } else {
        if (compareValueOption(val, opt)) {
          ret.push(opt);
        }
      }
    });
    return ret;
  }

  // If value is not an object with label, value OR a list of such objects...
  if (
    props.value instanceof Array &&
    props.value.length > 0 &&
    typeof props.value[0] !== 'object'
  ) {
    var value = unnestOptionsAndMatchValues(props.value);
  } else if (!(props.value instanceof Object)) {
    var value = unnestOptionsAndMatchValues(props.value);
  } else {
    var value = props.value;
  }

  // var components = { Option: Option }
  var components = {
    Option: Option,
    ...props.components,
  };
  if (noDropdownIndicator) {
    components = {
      ...components,
      DropdownIndicator: () => null,
      IndicatorSeparator: () => null,
    };
  }
  // if (props.options && props.options.length > 100 && !props.disableMenuList) {
  //     components['MenuList'] = MenuList
  // }
  if (noOverflow) {
    components['MultiValueContainer'] = noOverflowMultiValueContainer;
  }

  return (
    <Select
      ref={!!props.selectRef ? props.selectRef : innerRef}
      isSearchable={false}
      hideSelectedOptions={false}
      closeMenuOnSelect={
        props.closeMenuOnSelect ? props.closeMenuOnSelect : props.isMulti ? false : true
      }
      styles={customStyle}
      filterOption={createFilter({
        ignoreAccents: false,
        stringify: (option) => `${option.label} ${option.value} ${option.data.search}`,
      })}
      {...props}
      value={value}
      onChange={(val) => {
        if (props.defaultOnChange) {
          props.onChange(val);
          return;
        }
        if (!val) {
          props.isMulti
            ? props.onChange(props.name, [])
            : props.onChange(props.name, null);
        } else {
          val instanceof Array
            ? props.onChange(
                props.name,
                val.map((v) => v.value)
              )
            : props.onChange(props.name, val.value);
        }
      }}
      components={components}
      menuPortalTarget={document.body}
    />
  );
}
