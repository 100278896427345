import React, { useState, memo, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import {
  faEdit,
  faGripLines,
  faStickyNote,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import styled from 'styled-components';

import { UPDATE_PLAYER_LIST } from '../../apollo/queries/playerlist.queries';
import { itemTypes } from '../../constants';
import { bruinsBlack, bruinsGold } from '../../helpers/plotting';
import BDDEditInline from '../bdd/bddeditinline';
import { SectionHeader } from '../reports';

import Draggable from '../bdd/draggable';
import { AddTierNoteButton, TierNotes } from './ListTierNotes';
import { CompactNotes } from 'components/Notes';
import { Container } from 'components/bdd/Layout';

const Styles = styled.div`
  // background: ${(props) => props.background};
  padding: auto;
  margin-bottom: 7px;
  margin-top: 7px;
  box-shadow: 0 0 10px ${(props) => props.shadow};
  border-radius: 10px;

  .tier-header {
    font-size: ${(props) => (props.editing ? '12pt' : '16pt')};
    font-family: Oswald;
    padding-left: 10px;
  }
  .tier-footer {
    font-family: Oswald;
    padding-left: 10px;
  }

  .tier-button {
    font-size: 12px;
    padding-right: 10px;
    cursor: pointer;
  }

  .draggable-tier-edge {
    font-size: 12px;
    color: #777;
    &:hover {
      color: black;
    }
  }
`;
function ListTier({ listId, tier, children, editMode, finishedMovingTier, hideNotes=false }) {
  const [
    updatePlayerlist,
    { loading: updateLoading, error: updateError, called: updateCalled },
  ] = useMutation(UPDATE_PLAYER_LIST);
  const [showEditModal, setShowEditModal] = useState(false);
  if (!tier) {
    return children;
  }

  const handleChangeTitle = (name, value) => {
    if (!value || value.length === 0) return;
    if (!tier || !tier.id) throw Error('Attempting to update list tier without tier id');
    updatePlayerlist({
      variables: {
        input: { id: listId },
        tiersToUpdate: [{ id: tier.id, title: value }],
      },
    });
  };

  const handleRemoveTier = () => {
    if (!tier || !tier.id) throw Error('Attempting to remove list tier without tier id');
    if (
      confirm(
        'Are you sure you want to delete this tier? (This will not remove the players from list)'
      )
    ) {
      updatePlayerlist({
        variables: {
          input: { id: listId },
          tiersToRemove: [tier.id],
        },
      });
    }
  };

  const title = !!tier.title ? tier.title : editMode ? 'Untitled Tier' : null;
  return (
    <Styles editing={editMode} background={bruinsGold + '77'} shadow={bruinsGold}>
      <Row className="tier-header">
        {!!title ? (
          <Col
            xs={editMode ? '5' : '12'}
            style={!editMode ? { textAlign: 'center' } : null}
          >
            <Row>
              <Col xs="auto">
                <BDDEditInline
                  name="title"
                  defaultValue={title}
                  onChange={handleChangeTitle}
                  allowEdit={editMode}
                />{' '}
                {editMode && title === 'Untitled Tier' && <small>(click to edit)</small>}
              </Col>
            </Row>
          </Col>
        ) : (
          <div style={{ height: '5px' }}></div>
        )}
        {editMode && (
          <>
            <Col xs="2" style={{ textAlign: 'center' }}>
              <Draggable
                className="draggable-tier-edge"
                activeClassName="active"
                itemType={itemTypes.PLAYER_LIST_TIER}
                data={{ listId: listId, tierId: tier.id, startEnd: 'start' }}
                noDragPreview={true}
                onEnd={(item, monitor) => {
                  finishedMovingTier();
                }}
              >
                <div style={{ textAlign: 'center' }}>
                  <FontAwesomeIcon icon={faGripLines} />
                </div>
              </Draggable>
            </Col>
            <Col xs="5" style={{ textAlign: 'right' }}>
              <AddTierNoteButton listId={listId} tier={tier} />
              <span
                className="tier-button"
                onClick={() => setShowEditModal(true)}
                title="Edit tier start/end"
              >
                <FontAwesomeIcon icon={faEdit} />
              </span>
              <span
                className="tier-button"
                onClick={handleRemoveTier}
                title="Remove tier"
              >
                <FontAwesomeIcon icon={faTrash} />
              </span>
            </Col>
          </>
        )}
      </Row>
      {hideNotes 
        ? (
          <Container paddingLeft={2} width={40}>
            <CompactNotes
              useHoverIconButton
              entity={{
                entityId: listId,
                entityType: 'BDD_PLAYER_LIST',
                subEntityId: tier.id,
                subEntityType: 'BDD_PLAYER_LIST_TIER',
              }}
            />
          </Container>
        ): <TierNotes tier={tier} listId={listId} />
      }
      {children}
      {editMode ? (
        <Row className="tier-footer justify-content-md-center">
          <Col md="auto" style={{ textAlign: 'center' }}>
            <Draggable
              className="draggable-tier-edge"
              activeClassName="active"
              itemType={itemTypes.PLAYER_LIST_TIER}
              data={{ listId, tierId: tier.id, startEnd: 'end' }}
              noDragPreview={true}
              onEnd={(item, monitor) => {
                finishedMovingTier();
              }}
            >
              <span>
                <FontAwesomeIcon icon={faGripLines} />
              </span>
            </Draggable>
          </Col>
        </Row>
      ) : (
        <div style={{ height: '5px' }}></div>
      )}
      <EditTierModal
        show={showEditModal}
        handleClose={() => setShowEditModal(false)}
        start={tier.startsWithListNumber}
        end={tier.endsWithListNumber}
        listId={listId}
        tierId={tier.id}
      />
    </Styles>
  );
}
export default memo(ListTier);

function EditTierModal({
  show,
  handleClose,
  start: defaultStart,
  end: defaultEnd,
  listId,
  tierId,
}) {
  const [
    updatePlayerlist,
    { loading: updateLoading, error: updateError, called: updateCalled },
  ] = useMutation(UPDATE_PLAYER_LIST, {
    onCompleted: () => handleClose(),
  });
  const [start, setStart] = useState(defaultStart);
  const [end, setEnd] = useState(defaultEnd);

  useEffect(() => {
    setStart(defaultStart);
    setEnd(defaultEnd);
  }, [defaultStart, defaultEnd]);

  const handleSubmit = () => {
    updatePlayerlist({
      variables: {
        input: { id: listId },
        tiersToUpdate: [
          { id: tierId, startsWithListNumber: start, endsWithListNumber: end },
        ],
      },
    });
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <SectionHeader>Select Tier Range</SectionHeader>
      </Modal.Header>
      <Modal.Body>
        <div>Select starting and ending list number for tier</div>
        <Row style={{ textAlign: 'center' }}>
          <Col>
            <label>Start</label>
            <input
              type="number"
              value={start}
              onChange={(ev) => {
                setStart(ev.target.value);
                if (end < ev.target.value) setEnd(ev.target.value);
              }}
              min={1}
            />
          </Col>
          <Col md="auto">to</Col>
          <Col>
            <label>End</label>
            <input
              type="number"
              value={end}
              onChange={(ev) => setEnd(ev.target.value)}
              min={start}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

// const TierDropStyles = styled.div`
//     .player-list-tier-target {
//         width: 100%;
//         height: 0px;
//         boder-radius: 10px;
//         transition: all 0.2s;
//     }
//     .potential-target {
//         height: 15px;
//         background: #abfcab;
//         border-radius: 10px;
//         margin-top: 10px;
//         margin-bottom: 10px;
//     }
//     .active-player-list-tier-target {
//         background: black;
//     }
// `
// export const TierDropTarget = memo(function TierDropTarget({
//     listId,
//     listNumber,
//     activeTarget
// }) {
//     // console.log('rendered tdt')
//     const [updatePlayerlist, { loading: updateLoading, error: updateError, called: updateCalled }] = useMutation(UPDATE_PLAYER_LIST)

//     const onDrop = ({ data, type }) => {
//         if (type !== itemTypes.PLAYER_LIST_TIER) return

//         const update = { id: data.tierId }
//         if (data.startEnd === 'start') {
//             update.startsWithListNumber = listNumber
//         } else if (data.startEnd === 'end') {
//             update.endsWithListNumber = listNumber
//         }
//         updatePlayerlist({ variables: {
//             input: { id: listId },
//             tiersToUpdate: [update] }
//         })
//     }

//     return <TierDropStyles>
//     <Droppable
//         // className='player-list-tier-target'
//         className={`player-list-tier-target ${activeTarget ? 'potential-target' : ''}`}
//         activeClassName='active-player-list-tier-target'
//         onDrop={onDrop}
//         canDrop={() => activeTarget}
//         acceptItemTypes={[itemTypes.PLAYER_LIST_TIER]}
//         customStyles={() => null}
//     >
//     </Droppable>
//     </TierDropStyles>
// })