import { useEffect, useRef } from 'react';
import { Row } from '../Layout';
import { Typography } from '../Typography';

export const Checkbox = ({
  id,
  label,
  checked,
  onChange,
  styleProps,
  ignoreIndeterminate,
  typographyVariant = 'body2',
  gap = 2,
}) => {
  const ref = useRef();

  useEffect(() => {
    if (ignoreIndeterminate) return;
    if (!ref?.current) return;
    if (checked != undefined) return;

    ref.current.indeterminate = true;
  }, [ref, checked]);

  return (
    <label style={{ margin: 0, ...styleProps }}>
      <Row columnGap={gap} alignItems="center">
        <input
          id={id}
          ref={ref}
          type="checkbox"
          checked={checked || false}
          onChange={onChange}
        />
        <Typography variant={typographyVariant} noWrap noUserSelect>
          {label}
        </Typography>
      </Row>
    </label>
  );
};
