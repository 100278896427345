import React, { useCallback, useEffect, useState } from 'react'
import { useQueryWithPlaceholder } from 'apollo/hooks'
import { CREATE_OR_UPDATE_QUESTIONNAIRE_LINK, GET_POTENTIAL_QUESTIONNAIRE_LINKS } from 'apollo/queries/questionnaire.queries'
import styled from 'styled-components'
import { theme } from 'constants'
import { Col, Row } from 'react-bootstrap'
import { getCurrentSeason } from 'helpers/hockeyutils'
import { useMutation } from '@apollo/client'
import { Typography } from 'components/bdd/Typography'

const Header = styled.div(() => ({
  ...theme.typography.subtitle1
}))
const SubHeader = styled.div(() => ({
  ...theme.typography.subtitle2,
  fontStyle: 'normal'
}))

const ShowMore = styled.div(() => ({
  ...theme.typography.subtitle2,
  cursor: 'pointer',
  "&:hover": {
    textDecoration: 'underline'
  }
}))

const Match = styled.div(() => ({
  ...theme.typography.body1,
  padding: theme.spacing[2],
  margin: theme.spacing[1],
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  border: '1px solid #ddd',
  borderRadius: '5px',
  '&:hover': {
    boxShadow: '0px 0px 5px #333'
  },
  transition: 'all 0.1s ease-in-out' 
}))

const MatchField = styled.div(() => ({
  flex: 1
})) 


const QUESTIONNAIRE_LINK_COLUMN = 'Email2'

export default function LinkQuestionnaire({ rinknetId, year }) {
  const [showMore, setShowMore] = useState(false)
  if (!year) year = getCurrentSeason().toString().slice(4)
  const { data, placeholder } = useQueryWithPlaceholder(GET_POTENTIAL_QUESTIONNAIRE_LINKS, { rinknetId, year })

  const [createOrUpdateQuestionnaireLink] = useMutation(CREATE_OR_UPDATE_QUESTIONNAIRE_LINK, {
    refetchQueries: ['GetQuestionnaire']
  })

  const handleLink = useCallback(parsed => {
    const id = parsed[QUESTIONNAIRE_LINK_COLUMN]
    if (!id) {
      alert(`Failed to find ${QUESTIONNAIRE_LINK_COLUMN} in questionnaire`)
      return
    }
    const input = {
      year,
      rinknetId,
      questionnaireId: id,
      questionnaireIdColumn: QUESTIONNAIRE_LINK_COLUMN
    }
    createOrUpdateQuestionnaireLink({ variables: { ...input }})
  }, [createOrUpdateQuestionnaireLink, rinknetId, year])

  // if we get a direct name match, let's autolink here
  useEffect(() => {
    if (!!data && data.potentialQuestionnaireLinks.length > 0) {
      const topResult = data.potentialQuestionnaireLinks[0]
      const parsed = JSON.parse(topResult.questionnaire.data)
      if (parsed['Name2'] === topResult.rinknetName) {
        //exact match
        if (window.confirm(`Found an questionnaire with an identical name: "${topResult.rinknetName}".  Would you like to link this?`)) {
          handleLink(parsed)
        }
      }
    }
  }, [data, handleLink]);

  if (!data) return placeholder

  if (data.potentialQuestionnaireLinks.length === 0) return <Typography variant="body1"><em>No questionnaire found</em></Typography>

  const topResult = data.potentialQuestionnaireLinks[0]
  const hasCloseMatch = true // for now (because search similarity is by term) we can't cut out matches effectively

  return <>
    {!!hasCloseMatch ? <>
      <Header>
        TOP RESULT:
      </Header>
      <QuestionnaireSearchResult result={topResult} handleLink={handleLink} />

      <SubHeader>
        If that doesn't look right, we might not have a questionnaire for this player.
      </SubHeader>
    </> : <>
      <Header>No questionnaire found for player</Header>
    </>}
    <hr></hr>
    <ShowMore onClick={() => setShowMore(!showMore)}>
      <em>{showMore ? 'Hide all results' : 'Show all results'}</em>
    </ShowMore>
    {showMore && <Row>
    {data.potentialQuestionnaireLinks.slice(1).map((q, i) => {
      return <QuestionnaireSearchResult key={i} result={q} handleLink={handleLink} />
    })}
    </Row>}
  </>
}

const QuestionnaireSearchResult = ({ result, handleLink }) => {
  const parsed = JSON.parse(result.questionnaire.data)
  return <Col key={parsed[QUESTIONNAIRE_LINK_COLUMN]} md={6} sm={12}>
    <Match onClick={() => handleLink(parsed)}>
      <MatchField>{parsed.Name2}</MatchField>
      <MatchField>{parsed.Position}</MatchField>
      <MatchField>{parsed.Email2}</MatchField>
    </Match>
  </Col>
}