import React, { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import styled from 'styled-components';
import { useRouteMatch, useHistory, useLocation, Link } from 'react-router-dom';
import { Button, Col } from 'react-bootstrap';

import { theme, LIST_ID_TYPES } from 'constants';
import {
  GET_PLAYER_LIST,
  UPDATE_PLAYER_LIST_WATCH,
} from 'apollo/queries/playerlist.queries';

import {
  getNormChoices,
  getPositionChoices,
  getSeasonChoices,
  getStrengthChoices,
  NULL_FILTER_VALUE,
} from 'helpers/filters';
import { getCurrentSeason } from 'helpers/hockeyutils';

import { useQueryWithPlaceholder } from 'apollo/hooks';
import PlayerListWrapper from 'components/PlayerLists/playerlistwrapper';
import BDDSelect from 'components/bdd/bddselect';
import StatsSelect from 'components/StatManager/v2/statsselect';
import ListPlayer from './components/ListPlayer';
import AddListPlayers from 'components/PlayerLists/addlistplayers';
import { ComparePlayersModal } from './components/ComparePlayersModal';
import { usePlayerListFilter } from './hooks/usePlayerListFilter';
import { useDropdown } from 'components/bdd/Dropdown';
import { PlayerModes } from './components/PlayerModes';
import DraftPlayerModal from 'components/DraftLists/draftplayermodal';
import { useAnalyticUploadData } from './hooks/useAnalyticUploadData';
import { useCombineNotesUploadData } from './hooks/useCombineNotesUploadData';
import { Helmet } from 'react-helmet';
import useCustomlistPlayerMode from './hooks/useCustomListPlayerMode';
import { COMPARE_PLAYERS_ROUTE } from 'constants';
import { GET_BDD_PLAYER_SLUG_FROM_RINKNET_ID } from 'apollo/queries/players.queries';
import { TooltipSpan } from 'components/reports';
import { ListBuilderRecencyWrapper } from './components/ListBuilderRecencyWrapper';
import { ListFilterForm } from './components/ListFilterForm';
import { Container } from 'components/bdd/Layout';
import { Page } from 'components/bdd/Page';
import useToggle from 'components/bdd/Toggle/useToggle';
import { useUser } from 'helpers/user';

const ControlContainer = styled.div({
  ...theme.borders.light,
  ...theme.borders.thin,
  ...theme.borders.bottom,
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing[3],
  flexWrap: 'wrap',
});

const ControlButtonContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'end',
  gap: theme.spacing[3],
  height: theme.spacing[14],
  marginLeft: 'auto',
});

const ListContainer = styled.div({
  display: 'flex',
  alignItems: 'start',
  '.selected-player': {
    boxShadow: 'inset 5px 0 5px 5px #aaaaff;',
  },
  // overflowY: 'auto',
  // maxHeight: '100vh',
});

const InputFilterTitle = styled.div({
  ...theme.typography.h6,
  display: 'flex',
  gap: theme.spacing[4],
  alignItems: 'baseline',
});

const InputFilterDisclaimer = styled.div({
  ...theme.typography.body2,
  color: theme.colors.states.danger,
});

const InputFilterContainer = styled.div({
  display: 'flex',
  gap: theme.spacing[10],
  margin: theme.spacing[3],
  flexWrap: 'wrap',
});

const Divider = styled.div({
  ...theme.borders.light,
  ...theme.borders.thin,
  ...theme.borders.bottom,
  width: '100%',
  overflow: 'auto',
  marginBottom: theme.spacing[3],
});

const DisappearingCol = styled(Col)`
  ${(props) =>
    props.isvisible
      ? `
        overflow: scroll;
    `
      : `
        overflow: hidden;
        max-width: 0 !important;
        padding: 0 !important;
    `}
`;

export const LIST_BUILDER_PLAYER_FIELDS = {
  rinknet_id: `
    rinknetPlayer {
      id
      realId
      firstname
      lastname
      birthdate age
      currentteamid
      currentleagueid
      currentteam currentleagueabbr 
      position heightStr weight
      height heightFt
      posFDG
      eliteprospectsid
      handedness
      agentname
      collegecommitmentteam
      bddPlayerSlug {
        slug
        rinknetId
        slUid
        slPlayerMaster {
          slUid
          defaultLeagueSlug
        }
        bddManagedTags {
          id
          flags
          isProjection
          tag {
            id
            tag
            tagType
            description
            color
            textColor
            dateCreated
            isBddTag
            requiresPosition
            priority
          }
        } 
      }
      agent {
          id
          title firstname lastname
          companyname
          email phone cellphone
      }
    }`,

  bdd_player_slug: `
    bddPlayer {
        slug
        rinknetId
        slUid
        cfSlug
        bfSlug
        firstname
        lastname
        birthdate
        bfPlayer {
          slug
          hasPastContract
          hasActiveContract
          hasFutureContract
          currentRemainingTerm
          capHit
          aav
          futureCapHit
          futureAav
          futureRemainingTerm
          retainedPct
          retainedDetails
          retainedCapHit
        }
        rinknetPlayer { 
            id
            realId
            firstname
            lastname
            birthdate age
            currentteamid
            currentleagueid
            currentteamnhlid
            currentteam currentleagueabbr 
            position heightStr weight
            height heightFt
            posFDG
            eliteprospectsid
            handedness
            agentname
            collegecommitmentteam
        }
        slPlayerMaster {
          slUid
          defaultLeagueSlug
        }
    }`,
};

const ListBuilder = ({
  listId: listIdArg,
  playerMode: playerModeArg,
  includePlayerModes = true,
  renderPlayerCallback, // if supplied, will be called with "listPlayerComponent", allows to wrap or add content
}) => {
  const {
    path,
    params: { listId: listIdParam, listType, playerMode: playerModeParam },
  } = useRouteMatch();

  const listId = listIdParam || listIdArg;
  const playerMode = playerModeParam || playerModeArg;

  useEffect(() => {
    localStorage.setItem('listBuilderPlayerMode', playerMode);
  }, [playerMode]);

  const getLink = (mode) =>
    path
      .replace(':listType', listType)
      .replace(':listId', listId)
      .replace(':playerMode?', mode);

  const history = useHistory();
  const { hash, pathname } = useLocation();
  const bottomOfListRef = useRef(null);
  const [showAddPlayers, setShowAddPlayers] = useState(false);
  const [selectedPlayers, setSelectedPlayers] = useState([]);

  const [filters, setFilters] = useState({
    season: [getCurrentSeason()],
    strength: 'ES55',
    norm: '20',
    compareTo: NULL_FILTER_VALUE,
  });

  const [slStats, setSlStats] = useState([
    { slug: 'points', label: 'P' },
    { slug: 'iXP', label: 'iXP' },
    { slug: 'percentageSCGP', label: '%SCGP' },
    { slug: 'PDPs', label: 'PDPs' },
    { slug: 'XPGF', label: 'XPGF' },
    { slug: 'XPGA', label: 'XPGA' },
  ]);

  const [updatePlayerlistWatch] = useMutation(UPDATE_PLAYER_LIST_WATCH);
  const { data, placeholder } = useQueryWithPlaceholder(GET_PLAYER_LIST, {
    id: listId,
  });

  const { playerToCombineNotes } = useCombineNotesUploadData({
    skip: !data || data.playerList.idType == LIST_ID_TYPES.bdd_player_slug,
  });

  const { selected, dropdown } = useDropdown({
    options: [
      {
        value: 'column',
        label: 'Column View',
      },
      {
        value: 'list',
        label: 'List View',
      },
      {
        value: 'filter',
        label: 'Filter View',
      },
      {
        value: 'recency',
        label: 'Recency View',
      },
    ],
    initialSelectedValue: 'list',
  });

  const tieredColumns = selected.value == 'column';
  const [showFilters, setShowFilters] = useState(true);

  const { sortAndFilterPlayers, inputFilters, filtersChanged, resetFilters } =
    usePlayerListFilter();

  const { fields, settings, customGearIconButton, customFieldsModal } =
    useCustomlistPlayerMode({
      list: data?.playerList,
      useLocalStorage: true,
      saveOnChange: true,
    });

  const handleScrollToBottom = () =>
    bottomOfListRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });

  const getRinknetPlayer = (player, list) =>
    list.idType == LIST_ID_TYPES.bdd_player_slug
      ? player.bddPlayer.rinknetPlayer
      : player.rinknetPlayer;

  const getBDDPlayer = (player, list) =>
    list.idType == LIST_ID_TYPES.bdd_player_slug
      ? player.bddPlayer
      : player.rinknetPlayer.bddPlayerSlug;

  const getSLPlayerMaster = (player, list) =>
    list.idType == LIST_ID_TYPES.bdd_player_slug
      ? player.bddPlayer.slPlayerMaster
      : player.rinknetPlayer.bddPlayerSlug?.slPlayerMaster;

  const { toggleComponent: myGradesToggle, toggled: useMyGrades } = useToggle({
    label: 'Show MY scouting grades',
    initialToggled: false,
  });
  const { user } = useUser();
  const hasRinknetUser = !!user.rinknetUser;

  const renderPlayer = (player, playerList) => {
    const rinknetPlayer = getRinknetPlayer(player, playerList);
    const bddPlayer = getBDDPlayer(player, playerList);
    const slPlayerMaster = getSLPlayerMaster(player, playerList);
    const listPlayer = (
      <ListPlayer
        rinknetPlayer={rinknetPlayer}
        bddPlayer={bddPlayer}
        slPlayerMaster={slPlayerMaster}
        mode={playerMode}
        useMyGrades={useMyGrades}
        list={playerList}
        filters={filters}
        slStats={slStats}
        justName={tieredColumns}
        notes={player.notes}
        customFields={fields}
        customSettings={settings}
      />
    );
    if (!!renderPlayerCallback) {
      return renderPlayerCallback({
        player,
        playerList,
        listPlayerComponent: listPlayer,
      });
    }
    return listPlayer;
  };

  const handlePlayerClick = (playerId) => {
    if (selectedPlayers.indexOf(playerId) >= 0) {
      setSelectedPlayers(selectedPlayers.filter((pid) => pid !== playerId));
    } else {
      setSelectedPlayers(selectedPlayers.concat(playerId));
    }
  };

  const hideModals = () => {
    history.push(pathname);
  };

  useEffect(() => {
    if (data && data.playerList.isWatched) {
      updatePlayerlistWatch({
        variables: { id: listId },
      });
    }
  }, [data]);

  if (!data) {
    return placeholder;
  }

  return (
    <>
      {customFieldsModal}

      <Helmet>
        <title>{!!data ? data.playerList.name : 'List'}</title>
      </Helmet>
      <Page.Breadcrumbs.Listener breadcrumb={{ label: data.playerList.name }} />
      <ControlContainer>
        {includePlayerModes && (
          <div>
            <PlayerModes
              listIdType={data.playerList.idType}
              getLink={getLink}
              list={data.playerList}
              showCustom={true}
              customGearIconButton={customGearIconButton}
            />
          </div>
        )}
        <ControlButtonContainer>
          {!!hasRinknetUser &&
            ['scouting', 'all', 'custom'].includes(playerMode) &&
            myGradesToggle}
          {selected.value == 'filter' && (
            <Button
              variant="dark"
              size="sm"
              onClick={() => setShowFilters((show) => !show)}
            >
              {showFilters ? 'Hide Filters' : 'Show Filters'}
            </Button>
          )}
          {filtersChanged && (
            <Button variant="outline-danger" size="sm" onClick={resetFilters}>
              Reset Filters
            </Button>
          )}
          {dropdown}
          <Button
            variant="outline-dark"
            size="sm"
            onClick={() => setShowAddPlayers(!showAddPlayers)}
          >
            Add Players
          </Button>
        </ControlButtonContainer>
      </ControlContainer>
      {showFilters && selected.value == 'filter' && (
        <>
          <InputFilterTitle>
            List Filters
            <InputFilterDisclaimer>
              (List cannot be edited while in filter mode)
            </InputFilterDisclaimer>
          </InputFilterTitle>
          <Container padding={2}>{inputFilters}</Container>
          <Divider />
        </>
      )}
      {selected.value === 'recency' ? (
        <ListBuilderRecencyWrapper playerList={data.playerList} />
      ) : (
        <ListContainer>
          <Col sm={showAddPlayers ? 7 : 12}>
            <PlayerListWrapper
              listId={listId}
              tieredColumns={tieredColumns}
              playerSize={tieredColumns ? 'sm' : 'lg'}
              defaultEditMode={true}
              showEditButton={false}
              showDeleteButton={false}
              showDetailsButton={false}
              showAddPlayers={false}
              renderPlayer={renderPlayer}
              useMyGrades={useMyGrades}
              sortAndFilterPlayers={
                selected.value == 'filter' && sortAndFilterPlayers
              }
              playerFields={LIST_BUILDER_PLAYER_FIELDS[data.playerList.idType]}
              selectedPlayers={selectedPlayers}
              handlePlayerClick={handlePlayerClick}
              clearSelectedPlayers={() => setSelectedPlayers([])}
              pollInterval={10000}
            />
          </Col>
          <DisappearingCol
            sm={showAddPlayers ? 5 : 0}
            isvisible={showAddPlayers ? 1 : 0}
            style={{
              position: 'sticky',
              top: 0,
            }}
          >
            <AddListPlayers
              listId={listId}
              defaultExpand={true}
              numCols={2}
              onPlayersAdded={() => setTimeout(handleScrollToBottom, 500)}
            />
          </DisappearingCol>
        </ListContainer>
      )}
      <div ref={bottomOfListRef} />
      {hash.startsWith('#compare') && data.playerList && (
        <ComparePlayersModal list={data.playerList} handleClose={hideModals} />
      )}
      {hash.startsWith('#player') && (
        <DraftPlayerModal
          show={hash.startsWith('#player')}
          handleClose={hideModals}
          playerToCombineNotes={playerToCombineNotes}
        />
      )}
    </>
  );
};

export default ListBuilder;
