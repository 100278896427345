import { Share } from 'react-bootstrap-icons';
import { IconButton } from '../Button';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import {
  CREATE_SHORT_LINK,
  GET_SEARCH_FROM_SHORT_LINK,
} from 'apollo/queries/url.queries';
import { useMutation, useQuery } from '@apollo/client';
import { toastInfo } from '../bddtoasts';
import { useSearchParamsState } from '../hooks';
import { useEffect } from 'react';
import { SearchParams } from 'helpers/searchParams';
import { usePageStore } from './Page';
import { use } from 'react';

export const SHORT_KEY_PARAM = 'short_key';

export const ShareButton = () => {
  const { search, pathname, href } = useLocation();
  const [createShortLink] = useMutation(CREATE_SHORT_LINK);

  return (
    <>
      <ShareButtonListener />
      <IconButton
        icon={<Share />}
        size={14}
        tooltip="Share link"
        onClick={() => {
          createShortLink({
            variables: { search, path: pathname },
            onCompleted: ({ createShortLink: { key } }) => {
              const url = `${window.location.origin}${window.location.pathname}/?${SHORT_KEY_PARAM}=${key}`;
              navigator.clipboard.writeText(url);
              toastInfo('Copied to clipboard!');
            },
          });
          // if (!!originPath) {
          //   history.push(originPath);
          // } else {
          //   history.goBack();
          // }
        }}
      />
    </>
  );
};

export const ShareButtonListener = () => {
  const history = useHistory();
  const { pathname } = useLocation();

  let setShortKeyExists;
  try {
    const store = usePageStore((state) => state.actions);
    setShortKeyExists = store?.setShortKeyExists;
  } catch (error) {
    setShortKeyExists = null; // No-op function as a fallback
  }

  const searchParams = new SearchParams();
  const shortKey = searchParams.getValue(SHORT_KEY_PARAM);

  const { data: { searchFromShortLink } = {} } = useQuery(GET_SEARCH_FROM_SHORT_LINK, {
    skip: !shortKey,
    variables: { shortKey, path: pathname },
  });

  useEffect(() => {
    setShortKeyExists?.(!!shortKey);
  }, [shortKey]);

  useEffect(() => {
    if (!!shortKey && !!searchFromShortLink) {
      history.replace(`${pathname}${searchFromShortLink}`);
    }
  }, [shortKey, searchFromShortLink]);
};
